import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/affiliateLandings.api'

export class AffiliateLandingsStore extends SharedStore {
  storeName = 'AffiliateLandingsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions
  @observable templates = []

  @action fetchTemplatesList = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchTemplatesList(data)
    this.templates = resp?.data?.list
    this.toggleLoading(false)
    return resp
  }

  duplicateByToken = (token, data) => api.duplicateByToken(token, data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new AffiliateLandingsStore()
