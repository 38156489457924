import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'

import { COURSE_CUSTOM_BTN_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import customButtonStyles from './customButtonStyles'

const CustomButton = (props) => {
  const {
    classes,
    onClick,
    hidden,
    disabled,
    children,
    outline = false,
    rightAligned = false,
    sideBtnLeft = false,
    sideBtnRight = false,
    className,
  } = props
  const btnClasses = classNames(
    classes.button,
    COURSE_CUSTOM_BTN_CLASS_NAMES.containerClassName,
    outline && classes.outlineButton,
    rightAligned && classes.rightAlignedButton,
    hidden && classes.buttonHidden,
    disabled && classes.buttonDisabled,
    className
  )
  const sideBtnClasses = classNames(
    classes.sideButton,
    COURSE_CUSTOM_BTN_CLASS_NAMES.sideBtnContainerClassName,
    sideBtnRight && classes.sideButtonRight
  )

  return (
    <button
      type='button'
      className={sideBtnLeft || sideBtnRight ? sideBtnClasses : btnClasses}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default withStyles(customButtonStyles)(CustomButton)
