import { getFontStyles } from '@elo-kit/utils/fonts.utils'

import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlue, eloWhite } = COLORS

// TODO: update with using createUseStyles
const customButtonStyles = (theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    minWidth: '104px',
    minHeight: '48px',
    borderRadius: '6px',
    backgroundColor: theme.buttonColor || eloBlue,
    fontSize: `${theme.buttonSize || 14}px`,
    fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
    ...getFontStyles(theme.buttonStyle),
    color: theme.buttonFontColor || eloWhite,
    border: 'none',
    padding: '0 20px',
    cursor: 'pointer',
    '& .fa-undo-alt': {
      marginRight: '6px',
      padding: 0,
    },
    '& .fa-chevron-left': {
      paddingRight: '10px',
    },
    '& .fa-chevron-right': {
      paddingLeft: '10px',
    },
    '& span': {
      marginTop: '2px',
      lineHeight: 1,
    },
    '&:hover': {
      opacity: 0.8,
    },
    '&:active': {
      opacity: 0.6,
    },
  },
  outlineButton: {
    border: `solid 2px ${theme.buttonBorderColor || eloBlue}`,
    background: 'none',
    color: theme.buttonBorderColor || eloBlue,
  },
  rightAlignedButton: {
    marginLeft: 'auto',
  },
  buttonHidden: {
    visibility: 'hidden',
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  buttonDisabled: {
    backgroundColor: '#cdcdcd',
    border: 'none',
    color: eloWhite,
    '&:hover': {
      opacity: 1,
    },
    '&:active': {
      opacity: 1,
    },
  },
  sideButton: {
    display: 'none',
    position: 'absolute',
    opacity: '0.8',
    height: 80,
    borderRadius: '0px 20px 20px 0',
    fontSize: 20,
    backgroundColor: theme.buttonColor || eloBlue,
    color: eloWhite,
    zIndex: 3,
    cursor: 'pointer',
    border: 'none',
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.35)',
    '&:hover': {
      opacity: 0.8,
    },
    '&:active': {
      opacity: 0.6,
    },
    '@media (max-width: 991px)': {
      display: 'block',
    },
  },
  sideButtonRight: {
    borderRadius: '20px 0 0 20px ',
    right: 0,
  },
})

export default customButtonStyles
