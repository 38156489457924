import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.picture.image,
    label: I18n.t('react.common.image'),
    fields: [
      {
        field: 'uploadBackgroundImage',
        cropAllowed: true,
      },
      {
        field: 'percentage',
        label: I18n.t('react.shared.width'),
        key: 'width',
      },
      {
        field: 'percentage',
        label: I18n.t('react.cabinet.max_width'),
        key: 'maxWidth',
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.background'),
    fields: [BLOCKS_WITH_COLOR_PICKER.backgroundColor],
  },
  {
    label: I18n.t('react.shared.alignment'),
    fields: [
      [
        {
          field: 'horizontalAlignment',
          key: 'horizontalAlignment',
        },
        {
          field: 'verticalAlignment',
          key: 'verticalAlignment',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
