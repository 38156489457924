import React from 'react'
import { observer } from 'mobx-react'

import { useSharedStores } from 'shared/hooks/use-shared-stores'
import { audioPlayer } from 'utils/audio.utils'

import { AudioPlayer } from '@elo-kit/components/page-builder/blocks/audio-player/AudioPlayer'

export const AudioPlayerPreview = observer((props) => {
  const { audioPlayersStore } = useSharedStores()
  const { setFixedPlayerId, fixedPlayerId } = audioPlayersStore

  return (
    <AudioPlayer
      {...props}
      audioPlayer={audioPlayer}
      fixedPlayerId={fixedPlayerId}
      setFixedPlayerId={setFixedPlayerId}
      audioPlayersStore={audioPlayersStore}
    />
  )
})
