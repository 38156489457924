import { createUseStyles } from 'react-jss'
import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { COLORS, PADDING_UNITS, YES, POSITIONS } from '@elo-kit/constants/general.constants'
import { getAlignment } from 'utils/themes.utils'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { BLOCK_VIEW } from 'constants/contentPageShared.constants'

import { BAR_STYLE_TYPES, IMAGE_STYLE_TYPES } from 'shared/components/page-builder/constants/block.constants'
import { FONTS } from 'shared/components/page-builder/constants/pageBuilder.constants'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloGrey70, eloGrey60, eloBlack, eloWhite, eloGrey20, eloGrey50, eloBlue } = COLORS

export const useMarketingToolsStyles = createUseStyles((theme) => {
  const imageStyle = theme.imageStyle ?? IMAGE_STYLE_TYPES.smallImage
  const isBackgroundImageStyle = IMAGE_STYLE_TYPES.background === imageStyle
  const isSmallImageStyle = IMAGE_STYLE_TYPES.smallImage === imageStyle
  const paddingUnits = theme.paddingUnits || PADDING_UNITS.px
  const isPxUnits = theme.paddingUnits === PADDING_UNITS.px
  const DEFAULT_PADDINGS = isPxUnits ? 30 : 0
  const isModalView = theme.view === BLOCK_VIEW.modal
  const [isLoadingStyle, isHalfCircleStyle] = Object.values(BAR_STYLE_TYPES).map(
    (item) => item === theme.progressBarStyle
  )
  const getDefaultModalPaddings = (previewMode) => (previewMode ? 10 : 0)
  const getModalPaddingValue = (value, isPreview) =>
    `${isModalView ? getDefaultModalPaddings(isPreview) : value ?? DEFAULT_PADDINGS}${paddingUnits}`
  const getPaddings = (isPreview) => ({
    paddingTop: getModalPaddingValue(theme.paddingTop, isPreview),
    paddingBottom: getModalPaddingValue(theme.paddingBottom, isPreview),
    paddingRight: getModalPaddingValue(theme.paddingRight, isPreview),
    paddingLeft: getModalPaddingValue(theme.paddingLeft, isPreview),
  })
  const { circleWidth } = theme
  const halfCircleWidth = Number(circleWidth) / 2
  const endDegForHalfCircle = 180
  const startDegForHalfCircle = 45

  return {
    circlesContainer: {
      height: '100%',
      width: '100%',
      clip:
        theme.progressBarValue > 50
          ? 'rect(auto, auto, auto, auto)'
          : `rect(0, ${circleWidth}px, ${circleWidth}px, ${halfCircleWidth}px)`,
      left: 0,
      position: 'absolute',
      top: 0,
    },

    circle: {
      width: `${circleWidth}px`,
      height: isHalfCircleStyle ? `${halfCircleWidth}px` : `${circleWidth}px`,
      position: 'relative',
      alignSelf: 'center',
      overflow: 'hidden',
    },

    leftCircle: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: `9px solid ${theme.progressBarMainColor}`,
      position: 'absolute',
      fontSize: 20,
      clip: `rect(0, ${halfCircleWidth}px, ${circleWidth}px, 0)`,
      transform: `rotate(${theme.progressBarValue * 3.6}deg)`,
      zIndex: 1,
    },

    pie: {
      background: `linear-gradient(to left, ${theme.progressBarMainColor} 50%, ${theme.progressBarAdditionalColor} 50%)`,
      borderRadius: '50%',

      '&::before': {
        borderRadius: '100% 0% 0% 100%',
        content: '""',
        display: 'block',
        height: '100%',
        marginRight: '50%',
        transformOrigin: 'right',
        background: theme.progressBarValue > 50 ? theme.progressBarMainColor : theme.progressBarAdditionalColor,
        transform:
          theme.progressBarValue > 50
            ? `rotate(${theme.progressBarValue * 3.6}deg)`
            : `rotate(${theme.progressBarValue * 3.6 - 180}deg)`,
      },
    },

    rightCircle: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: `9px solid ${theme.progressBarMainColor}`,
      position: 'absolute',
      clip: `rect(0, ${halfCircleWidth}px, ${circleWidth}px, 0)`,
      display: theme.progressBarValue > 50 ? 'block' : 'none',
      transform: 'rotate(180deg)',
      zIndex: 1,
    },

    circleShadow: {
      height: '100%',
      width: '100%',
      border: `9px solid ${theme.progressBarAdditionalColor}`,
      borderRadius: '50%',
      clip: `rect(0, ${circleWidth}px, ${halfCircleWidth}px, 0)`,
      transform: isHalfCircleStyle ? 'rotate(90deg)' : 'unset',
      position: 'relative',
    },

    halfCircle: {
      top: 0,
      left: 0,
      width: `${circleWidth}px`,
      height: `${circleWidth}px`,
      borderRadius: '50%',
      border: `9px solid ${theme.progressBarAdditionalColor}`,
      borderBottomColor: theme.progressBarMainColor,
      borderRightColor: theme.progressBarMainColor,
      transform: `rotate(${(endDegForHalfCircle * Number(theme.progressBarValue)) / 100 + startDegForHalfCircle}deg)`,
    },

    circleBackground: {
      background: theme.progressBarAdditionalColor,
      borderRadius: '50%',
      top: '8.5px',
      right: '8.5px',
      bottom: '8.5px',
      left: '8.5px',
      color: theme.progressBarMainColor,
      cursor: 'default',
      display: 'block',
      position: 'absolute',
      textAlign: 'center',
    },

    percentageNumber: {
      position: 'absolute',
      fontSize: 20,
      top: isHalfCircleStyle ? `calc(${halfCircleWidth}px - 21.8px)` : 'calc(46% - 9px)',
      right: 'calc(50% - 25px)',
      color: theme.progressBarNumberColor || COLORS.eloBlue,
      fontFamily: 'Montserrat Black',
      fontWeight: 900,
      display: Number(circleWidth) >= 100 ? 'block' : 'none',
    },

    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      backgroundImage: isModalView ? 'unset' : getBackgroundImageCss(theme.backgroundImage),
      maxWidth: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      ...getPaddings(false),
    },

    previewContainer: {
      backgroundColor: isModalView ? COLORS.eloWhite : 'unset',
      ...getPaddings(true),
    },

    containerOverlay: {
      background: isModalView ? theme.modalBackgroundColor : theme.backgroundColor,
      opacity: isModalView ? theme.modalTransparency : theme.transparency,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      borderRadius: isModalView ? `${theme.radius}px` : 0,
    },

    iconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: getAlignment(theme.iconAlignment),
    },

    countdownInfoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },

    icon: {
      width: 45,
      height: 40,
      color: COLORS.eloGrey60,
      fontSize: 40,
      lineHeight: 1.1,
      marginBottom: 13,
    },

    closeIcon: {
      position: 'absolute',
      right: -8,
      top: -5,
      zIndex: 1,
      fontSize: 15,
      padding: '2px 5px',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
      color: eloGrey50,
      backgroundColor: eloWhite,
      width: 21,
      height: 21,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      '@media(max-width: 576px)': {
        width: 46,
        height: 46,
        fontSize: 22,
      },
    },

    countdownContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 178,
      maxWidth: `${theme.maxWidth}px`,
      width: `${theme.width}%`,
      background: eloWhite,
      borderRadius: `${theme.radius === undefined ? 0 : theme.radius}px`,
      padding: '40px 10px',
      boxShadow: `${theme.shadow === YES ? '0 6px 20px 0 rgba(0, 0, 0, 0.15)' : 'none'}`,
      backgroundColor: theme.countdownBackgroundColor,
      opacity: theme.countdownTransparency,
    },

    modal: {
      maxWidth: `${theme.maxWidth}px`,
      width: `${theme.width}%`,

      '& .modal-content': {
        display: 'block',
        borderRadius: `${theme.radius === undefined ? 0 : theme.radius}px`,
        border: 'unset',
      },
    },

    modalBody: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      minHeight: '370px',
      backgroundImage: isBackgroundImageStyle ? getBackgroundImageCss(theme.backgroundImage) : 'none',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingTop: `${theme.modalPaddingTop}${paddingUnits}`,
      paddingBottom: `${theme.modalPaddingBottom}${paddingUnits}`,
      paddingRight: `${theme.modalPaddingRight}${paddingUnits}`,
      paddingLeft: `${theme.modalPaddingLeft}${paddingUnits}`,
      borderRadius: `${theme.radius === undefined ? 0 : theme.radius}px`,
      boxShadow: `${theme.shadow === YES ? '0 6px 20px 0 rgba(0, 0, 0, 0.15)' : 'none'}`,

      '@media(max-width: 576px)': {
        flexWrap: 'wrap',
        flexDirection: getBackgroundImageCss(theme.backgroundImage) && isSmallImageStyle ? 'column-reverse' : 'column',
      },

      '& img': {
        width: 270,
        height: '100%',
        overflow: 'auto',
        objectFit: 'cover',
        position: 'relative',

        '@media(max-width: 576px)': {
          marginBottom: 20,
          width: '100%',
        },
      },
    },

    previewModalBody: {
      maxWidth: `${theme.maxWidth}px`,
      width: `${theme.width}%`,
    },

    noImageIcon: {
      fontSize: 200,
      color: eloGrey20,
      position: 'absolute',
      left: '40%',
    },

    smallImageContainer: {
      position: 'relative',
      width: 270,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: eloGrey20,
      borderRadius: '4px',
      border: 'dashed 1px #B7B7B7',
      overflow: 'hidden',
    },

    smallImageIcon: {
      fontSize: 120,
      color: eloWhite,
    },

    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginRight: isBackgroundImageStyle ? 0 : 50,
      zIndex: 1,
      width: isBackgroundImageStyle ? '100%' : 'unset',
    },

    mainText: {
      fontSize: theme.mainSize ? `${theme.mainSize}px` : '44px',
      fontFamily: cleanupFonts(theme.mainFont) || FONTS.bolder,
      ...getFontStyles(theme.mainStyle),
      color: theme.mainColor || eloBlack,
      width: `${theme.textWidth || 100}%`,
      maxWidth: `${theme.textMaxWidth || 500}px`,
      lineHeight: 1,
      marginBottom: '18px',
      wordBreak: 'keep-all',
      overflowWrap: 'normal',
      display: 'flex',
      justifyContent: getAlignment(theme.textAlignment || POSITIONS.left),
      alignSelf: getAlignment(theme.textAlignment || POSITIONS.left),
      textAlign: theme.textAlignment === 'center' ? 'center' : 'none',
      '@media (max-width: 576px)': {
        fontSize: theme.mainSize > 40 ? 40 : theme.mainSize,
      },
    },

    additionalText: {
      fontSize: theme.additionalSize ? `${theme.additionalSize}px` : '16px',
      fontFamily: cleanupFonts(theme.additionalFont) || FONTS.medium,
      ...getFontStyles(theme.additionalStyle),
      color: theme.additionalColor || eloBlack,
      width: `${theme.textWidth || 100}%`,
      maxWidth: `${theme.textMaxWidth || 500}px`,
      lineHeight: 1.5,
      paddingBottom: '61px',
      wordBreak: 'break-word',
      display: 'flex',
      justifyContent: getAlignment(theme.textAlignment || POSITIONS.left),
      alignSelf: getAlignment(theme.textAlignment || POSITIONS.left),
      textAlign: theme.textAlignment === 'center' ? 'center' : 'none',

      '@media(max-width: 576px)': {
        paddingBottom: 20,
      },
    },

    numbers: {
      fontSize: `${theme.numbersFontSize || 50}px`,
      color: theme.numbersColor || eloGrey70,
      fontFamily: cleanupFonts(theme.numbersFont) || FONTS.bolder,
      lineHeight: 1,
      ...getFontStyles(theme.numbersFontStyle || 'bold'),

      '@media (max-width: 576px)': {
        fontSize: `${Number(theme.numbersFontSize) > 23 ? 23 : theme.numbersFontSize}px`,
      },
    },

    text: {
      fontFamily: cleanupFonts(theme.textFont) || FONTS.bold,
      fontSize: `${theme.textFontSize || 13}px`,
      color: theme.textColor || eloGrey60,
      ...getFontStyles(theme.textFontStyle || 'normal'),
    },

    button: {
      background: theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme.buttonColor || eloBlue,
      color: theme.buttonTextColor || eloWhite,
      border: `2px solid ${theme.buttonColor || eloBlue}`,
      borderRadius: `${theme.buttonCornerRadius === undefined ? 4 : theme.buttonCornerRadius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(theme.buttonFont) || FONTS.medium,
      ...getFontStyles(theme.buttonStyle || 'bold'),
      fontSize: `${theme.buttonSize || 15}px`,
      cursor: 'pointer',
      wordBreak: 'break-word',
      textAlign: 'center',
      textTransform: 'uppercase',
      lineHeight: 1,
      minHeight: '50px',
      width: '240px',
      letterSpacing: 1,
      padding: 5,
      alignSelf: getAlignment(theme.horizontalAlignment || POSITIONS.left),

      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        opacity: 0.7,
      },
    },

    progressBarContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    progressBarDescription: {
      position: 'relative',
      marginBottom: 4,
      fontFamily: FONTS.bold,
      fontSize: 13,
      color: COLORS.eloGrey60,
    },

    progressBar: {
      position: 'relative',
      display: 'flex',
      width: `${theme.progressBarWidth}px`,
      height: `${theme.progressBarHeight}px`,
      backgroundColor: isLoadingStyle ? theme.progressBarAdditionalColor : 'unset',
      borderRadius: `${theme.radius === undefined ? 15 : theme.radius}px`,
      border: isLoadingStyle ? 'unset' : `2px solid ${theme.progressBarMainColor}`,
      padding: isLoadingStyle ? 0 : 2,
    },

    filled: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: isLoadingStyle ? '100%' : theme.progressBarHeight - 8,
      backgroundColor: theme.progressBarMainColor,
      borderRadius: `${theme.radius === undefined ? 15 : theme.radius}px`,
      borderTopRightRadius: isLoadingStyle ? 0 : `${theme.radius}px`,
      borderBottomRightRadius: isLoadingStyle ? 0 : `${theme.radius}px`,
      paddingTop: 2,
    },

    remainingPercentage: {
      color: COLORS.eloWhite,
      fontSize: 14,
      fontFamily: FONTS.bold,
      marginRight: 12,
    },

    ...buttonAnimations({
      ...theme,
      radius: theme.buttonCornerRadius,
    }),
  }
})
