import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { VIDEO_TYPE } from 'constants/contentPageShared.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.cabinet.product.lesson.form.blocks.titles.video.video'),
    fields: [
      {
        show: { key: 'vimeoVideos' },
        field: 'videoType',
      },
      {
        field: 'uploadVideo',
        show: {
          key: 'type',
          value: [VIDEO_TYPE.elopage, undefined],
        },
      },
      {
        field: 'text',
        label: I18n.t('react.cabinet.vimeo_video_id_or_url'),
        key: 'vimeoVideoId',
        show: { key: 'vimeoVideoId' },
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.thumbnail'),
    fields: ['uploadThumbnail'],
    show: {
      key: 'type',
      value: [VIDEO_TYPE.elopage, undefined],
    },
  },
  {
    label: I18n.t('react.shared.integrations'),
    show: { key: 'videoIntegrations' },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.integrationsBackground,
        label: I18n.t('react.shared.integrations_background'),
      },
      'addVideoCodes',
    ],
  },
  {
    label: I18n.t('react.cabinet.video_player'),
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.playerColor,
        show: {
          key: 'type',
          value: [VIDEO_TYPE.elopage, undefined],
        },
      },
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
      {
        field: 'checkbox',
        label: I18n.t('react.cabinet.playbar_disabled'),
        key: 'playbarDisabled',
        tooltipIdCheck: 'playbar_disabled',
        show: {
          key: 'type',
          value: [VIDEO_TYPE.elopage, undefined],
        },
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.background'),
    tooltipId: 'block_background_settings',
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    label: I18n.t('react.shared.alignment'),
    fields: [
      {
        field: 'horizontalAlignment',
        key: 'horizontalAlignment',
      },
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
