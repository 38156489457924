import React, { Component } from 'react'

import { ThemeProvider } from 'react-jss'

import { withStores } from 'utils/helpers.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { formatMoney } from 'utils/money.utils'
import { isWindowEnv } from 'utils/env.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

import { DEFAULT_PAGE, DEFAULT_ITEMS_PER_PAGE } from 'constants/pagination.constants'
import {
  getAllProductsCategories,
  ALL_PRODUCTS_CATEGORY_OPTION,
  getDefaultProductsList,
  DEFAULT_SORTING_DIR,
} from 'constants/contentPageShared.constants'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import SellerProductsPreview from '@elo-kit/components/page-builder/blocks/seller-products/seller-products-preview'
import { changeCategoryTabInUrl } from '../../../../page-builder/utils/block.utils'

const defaultProps = {
  mobileMenuActive: false,
}

class SellerProductsPreviewContainer extends Component {
  handleBuyProduct = (product) => {
    const { historyPush, contentPageStore } = this.props
    const { view, seller: { username } = {} } = contentPageStore
    const link = `/s/${username}/${product?.slug}/payment`

    switch (view) {
      case ELOPAGE_CABINETS.shop: {
        if (product?.slug) {
          const isNextApp = getElopageConfig('isNextApp')
          !isNextApp && window.scrollTo(0, 0)
          historyPush(link)
        }
        break
      }
      case ELOPAGE_CABINETS.payer:
        this.toggleAutoPayModal(product)
        break
    }
  }

  render() {
    const {
      contentPageStore,
      productGroupsStore,
      currenciesStore,
      productsStore,
      block,
      previewMode,
      mobileMenuActive,
      params,
      historyPush,
      LinkComponent,
    } = this.props
    const { fetchFullList, toggleLoading, loading, handleScopeChange } = productGroupsStore
    const { convertToPrice, getUnit, getCurrencySignPosition } = currenciesStore
    const { setGroupId } = productsStore

    const {
      handlePaginationChange,
      view,
      seller: { username: sellerUsername, id: sellerId, consentForm: sellerConsentForm } = {},
      sellerProducts,
      resetQueryParams,
      fetchProducts,
      setPagination,
    } = contentPageStore
    const { content, id } = block || {}
    const {
      query: sellerProductsQuery = '',
      loading: sellerProductsLoading,
      list: sellerProductsList = [],
      included: sellerProductsContent = [],
    } = sellerProducts[id] || {}

    const { sortKey } = content || {}

    const requestParams =
      view === ELOPAGE_CABINETS.shop ? { name: sellerProductsQuery } : { query: sellerProductsQuery }
    const initStore = {
      ...contentPageStore,
      pagination: {
        per: contentPageStore?.pagination?.per || DEFAULT_ITEMS_PER_PAGE,
        page: DEFAULT_PAGE,
        total: 0,
        ...(sellerProducts[id]?.pagination || {}),
      },
      handlePaginationChange: (key, value) =>
        handlePaginationChange(
          key,
          value,
          {
            sortKey,
            sortDir: content.sortDir || DEFAULT_SORTING_DIR[content.sortKey] || 'desc',
            productGroupId: getSearchParams()[`block_${id}_group_id`],
            ...requestParams,
          },
          id
        ),
    }

    const productsBlock = isWindowEnv() && document.getElementById(`seller-products-${id}`)

    const handleScrollChange = () => {
      const blockStart = productsBlock ? productsBlock.getBoundingClientRect().top + window.scrollY : 0
      window.scrollTo(0, blockStart)
    }

    const theme = {
      ...content,
      categorySelectFieldContentBefore: I18n.t('shared.common.category'),
    }

    return (
      <ThemeProvider theme={theme}>
        <SellerProductsPreview
          {...{
            previewMode,
            LinkComponent: LinkComponent,
            handleBuyProduct: this.handleBuyProduct,
            handleScrollChange,
            historyPush,
            initStore,
            sellerUsername,
            sellerId,
            fetchCategories: fetchFullList,
            productGroupsStore,
            setPagination,
            productGroupsLoading: loading,
            changeCategoryTabInUrl: (id) =>
              changeCategoryTabInUrl({ ...getSearchParams(), ...params }, historyPush, id, block.id),
            convertToPriceWithoutCurrency: (value) => formatMoney(value || '', 2, ',', '.'),
            getByKey: () => getSearchParams(),
            defaultProductsList: getDefaultProductsList(),
            allProductsCategory: getAllProductsCategories(),
            allProductsCategoryOption: ALL_PRODUCTS_CATEGORY_OPTION,
            getUnitPosition: getCurrencySignPosition,
            setGroupId,
            sellerProductsQuery,
            sellerProducts: sellerProductsList,
            sellerProductsContent,
            sellerProductsLoading,
            fetchProducts: (data) => fetchProducts(data, id),
            view,
            resetQueryParams,
            toggleLoading,
            handleScopeChange,
            convertToPrice,
            getUnit,
            theme,
            block,
            mobileMenuActive,
            sellerConsentForm,
          }}
          activeTab={contentPageStore.activeTab}
          sortedCategories={contentPageStore.sortedCategories}
        />
      </ThemeProvider>
    )
  }
}

SellerProductsPreviewContainer.defaultProps = defaultProps

const stores = ['contentPageStore', 'productGroupsStore', 'currenciesStore', 'productsStore']

export default withStores(stores, SellerProductsPreviewContainer)
