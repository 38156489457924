import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BUTTON_TYPE } from 'constants/contentPageShared.constants'
import { THEME_TYPE } from 'constants/general.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    tooltipId: 'block_background_settings',
    fields: [
      'uploadBackgroundImage',
      [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency'],
      {
        field: 'pixels',
        label: I18n.t('react.shared.min_height'),
        key: 'minHeight',
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.button.buttonFunctionality,
    label: I18n.t('shared.common.style'),
    tooltipId: 'block_button_style',
    fields: [
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      'buttonViewStyle',
      'cornerRadius',
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      'buttonAnimation',
      {
        field: 'pixels',
        label: I18n.t('react.shared.width'),
        key: 'buttonWidth',
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.button.buttonFunctionality,
    label: I18n.t('react.cabinet.text'),
    tooltipId: 'block_button_text',
    fields: [
      {
        field: 'text',
        key: 'buttonText',
      },
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.button.buttonFunctionality,
    label: I18n.t('react.cabinet.button_functionality'),
    tooltipId: 'button_functionality',
    fields: ['buttonTypes', 'buttonLinkTarget'],
  },
  {
    label: I18n.t('react.cabinet.payment_options'),
    tooltipId: 'payment_options',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.buy,
      theme: THEME_TYPE.membership,
    },
    fields: ['paymentOptions'],
  },
  {
    label: I18n.t('react.shared.custom_link'),
    tooltipId: 'block_custom_link',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.customLink,
    },
    fields: [
      {
        field: 'link',
        key: 'customLink',
      },
    ],
  },
  {
    label: I18n.t('react.shared.lesson_link'),
    tooltipId: 'block_lesson_link',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.lessonLink,
    },
    fields: [
      {
        field: 'selectLesson',
        key: 'selectLesson',
      },
    ],
  },
  {
    label: I18n.t('react.shared.individual_link'),
    tooltipId: 'block_individual_link',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.individualLink,
    },
    fields: [
      {
        field: 'individualLink',
        key: 'individualLink',
      },
    ],
  },
  {
    label: I18n.t('react.shared.download_file'),
    tooltipId: 'block_download_file',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.downloadFile,
    },
    fields: [
      {
        field: 'uploadFile',
        key: BUTTON_TYPE.downloadFile,
      },
    ],
  },
  {
    label: I18n.t('react.shared.product'),
    tooltipId: 'block_add_product',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.buy,
    },
    fields: ['selectProduct'],
  },
  {
    label: I18n.t('react.shared.product'),
    tooltipId: 'block_add_product',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.details,
    },
    fields: ['selectProduct'],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
