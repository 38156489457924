import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import classNames from 'classnames'

import './content-block-select-field.scss'

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <i className='far fa-trash-alt' />
  </components.ClearIndicator>
)

const DropdownIndicator = (props) => {
  const { selectProps } = props
  const { menuIsOpen } = selectProps

  const iconClassName = menuIsOpen
    ? 'fas fa-sort-up content-block__select-field__dropdown-indicator--arrow-up-position'
    : 'fas fa-sort-down'

  return (
    <components.DropdownIndicator {...props}>
      <i className={iconClassName} />
    </components.DropdownIndicator>
  )
}

const ContentBlockSelectField = (props) => {
  const { className, defaultValue, label, name, options, placeholder, valid, value, required, onChange, components } =
    props
  const [showIsRequiredMessage, setShowIsRequiredMessage] = useState(false)

  const onFieldChange = (option) => {
    if (required) {
      setShowIsRequiredMessage(false)
    }
    onChange(option)
  }

  const onBlur = () => {
    if (!value && required) {
      setShowIsRequiredMessage(true)
    }
  }

  const getSelectValue = () => {
    if (['string', 'number'].includes(typeof value)) {
      return options.find((option) => String(option.value) === String(value))
    }
    return value
  }

  const selectValue = getSelectValue()

  const getDefaultValue = () => {
    if (defaultValue && (typeof defaultValue === 'string' || typeof selectValue === 'number')) {
      return options.find((option) => String(option.value) === String(selectValue))
    }

    return defaultValue
  }

  const selectDefaultValue = getDefaultValue()

  const fieldContainerClassNames = classNames('content-block-field content-block__select-container')
  const fieldClassNames = classNames('content-block__select-field elo-select-field searchable', className, {
    error: showIsRequiredMessage || !valid,
  })
  const fieldLabelClassNames = classNames('content-block-field__label')
  const classNamePrefix = classNames('content-block__select-field')

  return (
    <div className={fieldContainerClassNames}>
      {label && (
        <label className={fieldLabelClassNames} htmlFor={name}>
          <span>{label}</span>
        </label>
      )}

      <Select
        className={fieldClassNames}
        defaultValue={selectDefaultValue}
        isSearchable
        onChange={onFieldChange}
        value={selectValue || ''}
        onBlur={onBlur}
        classNamePrefix={classNamePrefix}
        components={components}
        options={options}
        placeholder={placeholder}
      />
    </div>
  )
}

ContentBlockSelectField.displayName = 'ContentBlockSelectField'
ContentBlockSelectField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
}

ContentBlockSelectField.defaultProps = {
  options: [],
  required: false,
  valid: true,
  components: {
    ClearIndicator,
    DropdownIndicator,
  },
}

export default ContentBlockSelectField
