import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { getThemeProps } from '@elo-kit/utils/block.utils'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCK_VIEW } from 'constants/contentPageShared.constants'

import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import { withStores } from 'utils/helpers.utils'

import { useAffiliateStyles } from './affiliateStyles'

import { AFFILIATE_CLASS_NAMES } from '../../../../page-builder/constants/block.constants'

import ButtonAffiliate from './views/ButtonAffiliate'
import TextAffiliate from './views/TextAffiliate'

const defaultProps = {
  mobileMenuActive: false,
  previewMode: false,
}

const AffiliatePreview = (props) => {
  const {
    block: {
      content: { affiliateProgramToken },
      content,
    },
    previewMode,
    contentPageStore: { affiliateProgramsStore },
    block,
  } = props
  const [affiliateProgram, setAffiliateProgram] = useState(affiliateProgramsStore.item)
  const classes = useAffiliateStyles({ theme: getThemeProps(block) })

  useEffect(() => {
    if (affiliateProgramToken) {
      affiliateProgramsStore
        .fetchItem(affiliateProgramToken, {
          expand: ['affiliate_program_commissions'],
        })
        .then((resp) => {
          setAffiliateProgram(resp.data)
        })
    }
  }, [affiliateProgramToken])

  const getAffiliateView = () => {
    const viewProps = {
      classes,
      content,
      previewMode,
      affiliateProgram,
      accept: affiliateProgramsStore.accept,
    }

    if (content.view === BLOCK_VIEW.text) {
      return <TextAffiliate {...viewProps} />
    }

    return <ButtonAffiliate {...viewProps} />
  }

  return (
    <div
      className={classNames(
        classes.container,
        content[CUSTOM_CLASS_NAME_OPTION],
        AFFILIATE_CLASS_NAMES.containerClassName
      )}
    >
      <LazyBackgroundImage block={block} />
      {affiliateProgramToken
        ? getAffiliateView()
        : previewMode && (
            <div
              className={classes.noTextContainer}
              dangerouslySetInnerHTML={{
                __html: I18n.t('react.cabinet.affiliate_program.block_required_settings'),
              }}
            />
          )}
    </div>
  )
}

AffiliatePreview.defaultProps = defaultProps
AffiliatePreview.displayName = 'AffiliatePreview'

export default withStores(['contentPageStore'], AffiliatePreview)
