import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useWindowWidth } from '@react-hook/window-size'
import { components } from 'react-select'
import queryString from 'query-string'

import { EloButton } from '@elo-ui/components/elo-button'
import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloCard } from '@elo-ui/components/elo-card'
import {
  EloTicketIcon,
  EloMapPinIcon,
  EloCaretUpIcon,
  EloCaretDownIcon,
  EloCalendarIcon,
  EloInfoIcon,
  EloCloseIcon,
  EloWarningCircleIcon,
  EloDeleteCircleIcon,
} from '@elo-ui/components/icons/regular'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'
import { EloUserDisplay } from '@elo-ui/components/elo-user-display'
import { EloSelect } from '@elo-ui/components/elo-select'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { useOverflowed } from '@elo-ui/hooks/use-overflowed'
import { getPaymentPeriodLabels } from '@elo-kit/constants/paymentSetting.constants'
import { LoadingMask } from '@elo-kit/components'

import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'

import { omit } from 'utils/lodash.utils'
import { injectStripeScript, isStripeInjected } from 'utils/stripe.utils'
import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import { getFraudSessionIdentifier, insertFraudPaypalSnippet } from 'utils/ordersShared.utils'
import { createStripeClient, createStripeElements } from '@elo-kit/utils/stripe.utils'

import { UpgradeMappings, MappingToUpgrade } from 'types/upgrade-mappings'

import { Summary } from 'shop/components/summary/Summary'
import { PurchaseTerms } from 'shop/components/payment/new/purchaseTerms'
import { useShopStores } from 'shop/hooks/use-store'

import { TicketAvailabilityErrorModal } from './TicketAvailabilityErrorModal'
import { PaymentConfirmationModal } from './PaymentConfirmationModal'

import './upgrade-tickets-modal.scss'

interface SelectItem {
  value: number
  label: string
}

interface TestPeriodPopupData {
  price: string
  paymentPlanType: string
  tooltipMessage: string
}

export interface ErrorPopupData {
  data: TicketDate[]
  errors: {
    stock: string[]
    other: string[]
  }
}

interface TicketDate {
  available: boolean
  inStock: number
  productName: string
  required: number
  ticketDateId: string
  ticketName: string
}

const TooltipWrappedItem = ({ children, tooltipText, isOverflowed }) =>
  isOverflowed ? <EloTooltip content={tooltipText}>{children}</EloTooltip> : children

const getFormattedTicketDateOptions = (list) =>
  list.map((item) => ({
    value: item.id,
    label: item.formattedTicketDate.substring(0, item.formattedTicketDate.lastIndexOf(',')) || item.additionalText,
    inStock: item.inStock,
  }))

const MOBILE_BREAKPOINT = 576

const TicketInfo = (props) => {
  const { id, name, description } = props

  return (
    <div className='ticket-label'>
      <EloTicketIcon size={16} color='var(--elo-primary-dg-400)' />
      <div className='ticket-label__description'>
        {id && <span className='ticket-label__description-id'>{id}</span>}
        <span className='ticket-label__description-name'>{name}</span>
      </div>
      <span className='ticket-label__event-type'>{description}</span>
    </div>
  )
}

const SingleSelectComponent = (props) => (
  <components.SingleValue {...props}>
    <div className='elo-react-select__option--wrapper'>
      <div className='elo-react-select__option--title'>{props.data.label}</div>
      {props.data.inStock !== null && (
        <div className='elo-react-select__option--subtitle'>
          {`(${props.data.inStock < 0 ? '0' : props.data.inStock} ${I18n.t('react.shared.left')})`}
        </div>
      )}
    </div>
  </components.SingleValue>
)

const SelectOptionComponet = (props) => (
  <components.Option {...props}>
    <div className='elo-react-select__option--wrapper'>
      <div className='elo-react-select__option--title'>{props.data.label}</div>
      {props.data.inStock !== null && (
        <div className='elo-react-select__option--subtitle'>
          {`(${props.data.inStock < 0 ? '0' : props.data.inStock} ${I18n.t('react.shared.left')})`}
        </div>
      )}
      {props.isSelected && (
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          color='currentcolor'
        >
          <path
            d='M18 5L7.33333 15L2 10'
            stroke='currentColor'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          ></path>
        </svg>
      )}
    </div>
  </components.Option>
)

const ticketIsSelectable = (upgradeMapping) =>
  upgradeMapping?.ticket?.active &&
  upgradeMapping?.product?.canBeSoldViaShop &&
  !upgradeMapping?.ticketDates.every((item) => item?.soldOut) &&
  !(upgradeMapping?.ticketDates?.length === 1 && upgradeMapping?.ticketDates?.[0]?.inStock === 0) &&
  !!upgradeMapping.pricingPlans.length

const attendeeTicketsSoldOut = (upgradeMappings: UpgradeMappings) =>
  Object.values(upgradeMappings).every((el) => el?.ticket?.soldOut) ||
  Object.values(upgradeMappings).some((el) => !el.product.canBeSoldViaShop && !el.ticket.soldOut)

const attendeeSectionIsAvailable = (upgradeMappings: Record<string, UpgradeMappings>, ticketDateUpgradable) =>
  Object.values(upgradeMappings).some((item) => item.product.canBeSoldViaShop) &&
  ticketDateUpgradable &&
  !Object.values(upgradeMappings).every((item) => !item.pricingPlans.length)

const TicketCard = (props) => {
  const {
    id: paymentTicketId,
    expandedByDefault = false,
    ticketDateUpgradable,
    ticketAttendee,
    ticketName,
    eventName,
    ticketLocationName,
    ticketCode,
    ticketDate,
    selectedMappingToUpgrade,
    setTicketToUpgrade,
    resetTicketToUpgrade,
    shortDescription,
    isMobile,
    setTestPeriodPopupData,
  } = props

  const [isExpanded, setIsExpanded] = useState(expandedByDefault)
  const expandedBlockRef = useRef(null)
  const productNameRef = useRef(null)
  const { isOverflowed } = useOverflowed(productNameRef)
  const { currenciesStore } = useShopStores()

  const timePattern = /\b\d{1,2}:\d{2}(?:\s?[APMapm]{2})?\b/g

  const highlightedTicketTime = ticketDate.formattedTicketDate
    .substring(0, ticketDate.formattedTicketDate.lastIndexOf(','))
    .replaceAll(timePattern, '<span class="ticket-highlighted-text">$&</span>')

  const upgradeAttendeeStatus = !selectedMappingToUpgrade[ticketAttendee.id] ? (
    <span className='upgrade-available-copy'>{I18n.t('react.shared.upgrade_available')}</span>
  ) : (
    <span className='upgrade-selected-copy'>{I18n.t('react.shared.upgrade_selected')}</span>
  )
  const status = attendeeTicketsSoldOut(ticketDate.upgradeMappings) ? (
    <span className='upgrade-sold_out-copy'>{I18n.t('react.shared.upgrade_sold_out')}</span>
  ) : (
    upgradeAttendeeStatus
  )

  const isAttendeeSectionAvailable = attendeeSectionIsAvailable(ticketDate.upgradeMappings, ticketDateUpgradable)

  return (
    <div ref={expandedBlockRef} className='elo-card-ticket-wrapper'>
      <EloCard className='ticket' type={isAttendeeSectionAvailable ? 'informational' : 'disabled'}>
        <div className='ticket__header'>
          <div className='ticket__header-title'>
            <div className='attendee-info'>
              <EloUserDisplay username={ticketAttendee.fullName} />
              {!isExpanded && (
                <div className='attendee-info__title'>
                  {isAttendeeSectionAvailable ? (
                    status
                  ) : (
                    <span className='upgrade-unavailable-copy'>{I18n.t('react.shared.upgrade_unavailable')}</span>
                  )}
                </div>
              )}
            </div>
            {isExpanded ? (
              <button className='expand-button' onClick={() => setIsExpanded((prev) => !prev)}>
                <EloCaretUpIcon />
              </button>
            ) : (
              <button
                className='expand-button'
                onClick={() => {
                  setIsExpanded((prev) => !prev)
                  setTimeout(() => expandedBlockRef?.current?.scrollIntoView({ behavior: 'smooth' }))
                }}
              >
                <EloCaretDownIcon />
              </button>
            )}
          </div>
        </div>

        {isExpanded && (
          <div>
            <div className='ticket__upgrade-block'>
              <div className='ticket__upgrade-block-title'></div>
              <div className='ticket__upgrade-block__tickets-container'>
                <div className='ticket__upgrade-block__ticket'>
                  <div>{I18n.t('react.shared.current_ticket')}</div>
                  <TooltipWrappedItem tooltipText={eventName} isOverflowed={isOverflowed}>
                    <div className='ticket__upgrade-block__ticket-name' ref={productNameRef}>
                      {eventName}
                    </div>
                  </TooltipWrappedItem>
                  <TicketInfo id={ticketCode} name={ticketName} description={shortDescription} />
                  <div className='ticket__upgrade-block__location'>
                    <EloMapPinIcon size={16} color='var(--elo-primary-dg-400)' />
                    <span>{ticketLocationName}</span>
                  </div>
                  <div className='ticket__upgrade-block__date'>
                    <EloCalendarIcon size={16} color='var(--elo-primary-dg-400)' />
                    <div
                      className='ticket__upgrade-block__date-description'
                      dangerouslySetInnerHTML={{
                        __html: highlightedTicketTime || ticketDate?.additionalText,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='ticket__delimiter'>
              {I18n.t('react.shared.upgrade_to')}
              {!!selectedMappingToUpgrade[ticketAttendee.id] && (
                <EloButton
                  size={isMobile ? 'x-large' : 'small'}
                  variant='secondary'
                  onClick={() => resetTicketToUpgrade(ticketAttendee.id)}
                >
                  {I18n.t('react.shared.remove_selection')}
                </EloButton>
              )}
            </div>

            <div className='ticket__upgrade-cards'>
              {Object.values<UpgradeMappings>(ticketDate.upgradeMappings)
                .toSorted((a, b) => Number(b.ticket.active) - Number(a.ticket.active))
                .map((upgradeMapping) => {
                  const {
                    name: pricingPlanName,
                    form,
                    currencyId,
                    currencyKey,
                    prefs = {},
                    useNetPrice,
                  } = upgradeMapping.pricingPlans[0] || {}
                  const { price, oldPrice, firstAmount, oldFirstAmount, testPeriod } = prefs
                  const currency = currencyId || currencyKey
                  const displayPrice = price || firstAmount || 0
                  const displayOldPrice = oldPrice || oldFirstAmount
                  const displayPricingPlanName = pricingPlanName ? ` (${pricingPlanName})` : ''
                  const formattedOldPrice =
                    Number(!!parseFloat(String(displayOldPrice || 0))) > 0 &&
                    currenciesStore.convertToPrice(displayOldPrice, currency)
                  const sortedTicketDatesListList = upgradeMapping.ticketDates.toSorted(
                    (a, b) => Number(b.canBeSold) - Number(a.canBeSold)
                  )
                  const formattedTicketDates = getFormattedTicketDateOptions(sortedTicketDatesListList)
                  const selectedDate = formattedTicketDates.filter(
                    (item) => !!selectedMappingToUpgrade[ticketAttendee.id]?.[item.value]
                  )[0]
                  const netText = useNetPrice && I18n.t('react.shared.tax')

                  const OneTimeTooltip = () => (
                    <EloTooltip content={I18n.t('react.shared.pricing_plan.test_period', { test_period: testPeriod })}>
                      <EloInfoIcon />
                    </EloTooltip>
                  )

                  const handleInsertTestPeriodSection = (price, paymentPlanType, tooltipMessage) => {
                    setTestPeriodPopupData((prev) => ({
                      ...prev,
                      price,
                      paymentPlanType,
                      tooltipMessage,
                    }))
                  }

                  return (
                    <EloCard
                      selected={!!selectedDate}
                      handleSelect={() => {
                        resetTicketToUpgrade(ticketAttendee.id)
                        setTicketToUpgrade({
                          ticketDateId: ticketDate.id,
                          upgradeTicketDateId: selectedDate?.value || formattedTicketDates[0].value,
                          attendeeId: ticketAttendee.id,
                          upgradeMapping,
                          paymentTicketId,
                        })
                      }}
                      type={ticketIsSelectable(upgradeMapping) ? 'selectable' : 'disabled'}
                      className='ticket__upgrade-card'
                      key={ticketDate.id}
                    >
                      <div className='ticket__upgrade-card__title'>{upgradeMapping?.product?.name}</div>
                      <TicketInfo
                        name={upgradeMapping?.ticket?.name}
                        description={upgradeMapping?.ticket?.ticketLocationName}
                      />
                      <div className='ticket__upgrade-card__location'>
                        <EloMapPinIcon size={18} color='var(--elo-primary-dg-400)' />
                        <div className='ticket__upgrade-card__location-text'>
                          {upgradeMapping?.ticket?.ticketLocationName}
                        </div>
                      </div>

                      <div onClick={(e) => e.stopPropagation()} className='ticket__upgrade-card__input-field'>
                        <EloSelect.Select
                          id={`${ticketAttendee.id}-${ticketDate.id}-${upgradeMapping.ticket.name}`}
                          onChange={(item: SelectItem) => {
                            resetTicketToUpgrade(ticketAttendee.id)
                            setTicketToUpgrade({
                              ticketDateId: ticketDate.id,
                              upgradeTicketDateId: item.value,
                              attendeeId: ticketAttendee.id,
                              upgradeMapping,
                              paymentTicketId,
                            })
                          }}
                          options={formattedTicketDates}
                          value={selectedDate || formattedTicketDates[0]}
                          disabled={formattedTicketDates.length === 1}
                          onFocus={() => {
                            resetTicketToUpgrade(ticketAttendee.id)
                            setTicketToUpgrade({
                              ticketDateId: ticketDate.id,
                              upgradeTicketDateId: selectedDate?.value || formattedTicketDates[0].value,
                              attendeeId: ticketAttendee.id,
                              upgradeMapping,
                              paymentTicketId,
                            })
                          }}
                          isOptionDisabled={(ticketDate: SelectItem) =>
                            upgradeMapping.ticketDates.find((item) => item.id === ticketDate.value).soldOut ||
                            upgradeMapping.ticketDates.find((item) => item.id === ticketDate.value).expired
                          }
                          SingleValue={SingleSelectComponent}
                          OptionComponent={SelectOptionComponet}
                        />
                      </div>
                      <div className='ticket__upgrade-card__price-section'>
                        <EloPriceDisplay
                          price={currenciesStore.convertToPrice(displayPrice, currency)}
                          oldPrice={formattedOldPrice}
                          netPrice={netText}
                          supportiveText={
                            form === 'one_time' && `${getPaymentPeriodLabels()[form]}${displayPricingPlanName}`
                          }
                        />
                        {!!testPeriod && (
                          <div
                            onClick={() => {
                              if (isMobile) {
                                handleInsertTestPeriodSection(
                                  currenciesStore.convertToPrice(displayPrice, currency),
                                  `${getPaymentPeriodLabels()[form]}${displayPricingPlanName}`,
                                  I18n.t('react.shared.pricing_plan.test_period', { test_period: testPeriod })
                                )
                              }
                            }}
                          >
                            <OneTimeTooltip />
                          </div>
                        )}
                      </div>
                    </EloCard>
                  )
                })}
            </div>
          </div>
        )}
      </EloCard>
    </div>
  )
}

interface SelectedMappingToUpgrade {
  [index: number]: MappingToUpgrade
}

const useUpgrades = () => {
  const [selectedMappingToUpgrade, setSelectedMappingToUpgrade] = useState<
    SelectedMappingToUpgrade | Record<string, never>
  >({})
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const { upgradeTicketsStore, sellerStore } = useShopStores()

  const setTicketToUpgrade = ({
    ticketDateId,
    upgradeTicketDateId,
    attendeeId,
    paymentTicketId,
    upgradeMapping,
  }: {
    ticketDateId: number
    upgradeTicketDateId: number
    attendeeId: number
    upgradeMapping: UpgradeMappings
    paymentTicketId: number
  }) => {
    setSelectedMappingToUpgrade((prev) => {
      const newData = {
        ...prev,
        [attendeeId]: {
          upgradeItemData: {
            ...upgradeMapping,
            ticketDates: upgradeMapping.ticketDates.filter((item) => item.id === upgradeTicketDateId),
          },
          ticketDateId,
          upgradeTicketDateId,
          paymentTicketId,
          [ticketDateId]: upgradeTicketDateId,
          [upgradeTicketDateId]: (prev[attendeeId]?.[upgradeTicketDateId] || 0) + 1,
        },
      } as SelectedMappingToUpgrade
      upgradeTicketsStore.setSelectedData(newData)
      return newData
    })
  }

  const resetTicketToUpgrade = (attendeeId: number) => {
    setSelectedMappingToUpgrade((prev) => {
      const newData = omit(prev, [attendeeId])
      upgradeTicketsStore.setSelectedData(newData)
      return newData
    })
  }

  useEffect(() => {
    upgradeTicketsStore.toggleLoading(false)
  }, [])

  useEffect(() => {
    if (Object.keys(selectedMappingToUpgrade).length) {
      upgradeTicketsStore.buildOrder(sellerStore.item.username)
    }
  }, [selectedMappingToUpgrade])

  return [
    {
      selectedMappingToUpgrade,
      setSelectedMappingToUpgrade,
      isConfirmationModalOpen,
    },
    {
      setTicketToUpgrade,
      resetTicketToUpgrade,
      setIsConfirmationModalOpen,
    },
  ]
}

const UpgradeTicketsModal = observer(({ upgradeTicketModalIsOpen, setUpgradeTicketModalIsOpen }) => {
  const { ordersStore, upgradeTicketsStore, ticketsAvailabilityStore, sellerStore } = useShopStores()
  const [
    { selectedMappingToUpgrade, setSelectedMappingToUpgrade, isConfirmationModalOpen },
    { setTicketToUpgrade, resetTicketToUpgrade, setIsConfirmationModalOpen },
  ] = useUpgrades()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [testPeriodPopupData, setTestPeriodPopupData] = useState<TestPeriodPopupData>(null)
  const [ticketAvailabilityModalIsOpen, setTicketAvailabilityModalIsOpen] = useState<boolean>(false)
  const [errorsData, setErrorsData] = useState<ErrorPopupData | null>(null)
  const stripeElements = useRef(null)
  const stripeClient = useRef(null)
  const [stripeLoading, setStripeLoading] = useState<boolean>(false)
  const isMobileView = useWindowWidth() <= MOBILE_BREAKPOINT
  const isUpgradeNotAvailable = !!ordersStore.item.ticketUpgradeConnectedOrder

  useEffect(() => {
    const { ticketUpgradeData } = ordersStore

    switch (ticketUpgradeData.paymentForm) {
      case PAYMENT_FORMS.sofort:
      case PAYMENT_FORMS.applePay:
      case PAYMENT_FORMS.googlePay:
      case PAYMENT_FORMS.card: {
        setStripeLoading(true)
        initStripe()
        break
      }
      case PAYMENT_FORMS.paypal: {
        const fraudSessionIdentifier = getFraudSessionIdentifier({
          paypalMerchantId: ordersStore.data.paypalMerchantId,
          paypalProvider: ticketUpgradeData.provider,
          paymentForm: ticketUpgradeData.paymentForm,
        })

        if (fraudSessionIdentifier) {
          insertFraudPaypalSnippet(fraudSessionIdentifier, ordersStore.data.paypalMerchantId)
          upgradeTicketsStore.setFraudSessionIdentifier(fraudSessionIdentifier)
        }
        break
      }
      default:
        break
    }
  }, [])

  const initStripe = () => {
    if (!isStripeInjected()) {
      injectStripeScript(handleStripeOnLoad)
    } else {
      handleStripeOnLoad()
    }
  }

  const handleStripeOnLoad = () => {
    const { provider: orderProvider } = ordersStore.ticketUpgradeData
    const { moneyHolder } = ordersStore.data

    setStripeLoading(false)

    const provider = `${snakeCaseToCamelCase(orderProvider)}PubKey`

    const pubKey = moneyHolder[provider]

    if (pubKey) {
      stripeClient.current = createStripeClient(pubKey)

      if (isSepa) {
        stripeElements.current = createStripeElements(stripeClient.current, ordersStore.getSepaStripeElementPayload())
      } else {
        stripeElements.current = createStripeElements(stripeClient.current)
      }
    }
  }

  useEffect(() => {
    setIsMobile(isMobileView)
  }, [isMobileView])

  const showSummary = !!Object.keys(selectedMappingToUpgrade).length
  const isP24 = PAYMENT_FORMS.p24 === ordersStore.ticketUpgradeData.paymentForm
  const isIdeal = PAYMENT_FORMS.ideal === ordersStore.ticketUpgradeData.paymentForm
  const isKlarna = PAYMENT_FORMS.klarna === ordersStore.ticketUpgradeData.paymentForm
  const isSepa = PAYMENT_FORMS.sepa === ordersStore.ticketUpgradeData.paymentForm

  const handlePayUpgrades = async () => {
    await upgradeTicketsStore.setSelectedData(selectedMappingToUpgrade)

    const dataToCheck = upgradeTicketsStore.paramsForSubmit.sellables.map((sellabe) => ({
      id: sellabe.sellableItemsAttributes[0].ticketDateId,
      requiredNumber: sellabe.sellableItemsAttributes[0].ticketsCount,
    }))

    const dataToSend = {
      ticketDates: dataToCheck,
    }
    const resp: any = await ticketsAvailabilityStore.check(dataToSend)

    if (resp.errors?.other?.length || resp.errors?.stock?.length) {
      const attendeeIdShouldDeselect = []
      Object.keys(selectedMappingToUpgrade).forEach((attendeeId) => {
        resp.data.ticketDates.forEach((errorDate) => {
          if (
            String(errorDate.ticketDateId) === String(selectedMappingToUpgrade[attendeeId].upgradeTicketDateId) &&
            !errorDate.available
          ) {
            attendeeIdShouldDeselect.push(Number(attendeeId))
          }
        })
      })

      const removeElementByKey = () => {
        const newObj = { ...selectedMappingToUpgrade }
        attendeeIdShouldDeselect.forEach((item) => {
          delete newObj[item]
        })
        return newObj
      }

      setSelectedMappingToUpgrade(removeElementByKey())
      setErrorsData(resp)
      setTicketAvailabilityModalIsOpen(true)
    } else {
      const resp = await upgradeTicketsStore.createAndChargeOrder(sellerStore.item.username)
      if (resp.success && (isP24 || isIdeal || isKlarna)) {
        setIsConfirmationModalOpen(true)
      } else {
        await upgradeTicketsStore.payForUpgrades(resp, {
          stripeElement: stripeElements.current,
          stripeClient: stripeClient.current,
        })
      }
    }
  }

  const searchParams = queryString.parse(window.location.search)

  const firstMappingPricingPlanCurrencyId =
    Object.values(selectedMappingToUpgrade)?.[0]?.upgradeItemData?.pricingPlans?.[0]?.currencyId
  const selectedMappingLength = Object.keys(selectedMappingToUpgrade).length

  return (
    <EloModal
      isFullScreen
      isOpen={upgradeTicketModalIsOpen}
      onClose={() => {
        Boolean(searchParams.upgrade_modal)
          ? (window.location.href = ordersStore.item.product.successUrl)
          : setUpgradeTicketModalIsOpen(false)
      }}
      size='large'
      className='upgrade-tickets-modal'
    >
      {(upgradeTicketsStore.loading || stripeLoading) && <LoadingMask />}
      <EloModalHeader>
        {I18n.t('react.shared.upgrade_tickets')}
        {isUpgradeNotAvailable && (
          <EloInfoMessage status='error' icon={<EloDeleteCircleIcon />}>
            {I18n.t('react.shared.upgrade_modal.messages.upgrade_not_possible')}
          </EloInfoMessage>
        )}
      </EloModalHeader>
      <EloModalBody>
        {!ordersStore.ticketUpgradeData?.paymentTickets.some(
          (paymentTicket) =>
            attendeeSectionIsAvailable(paymentTicket.ticketDate.upgradeMappings, paymentTicket.ticketDateUpgradable) &&
            !attendeeTicketsSoldOut(paymentTicket.ticketDate.upgradeMappings as any)
        ) && (
          <EloInfoMessage status='warning' className='upgrade-modal-info-message'>
            <EloWarningCircleIcon />
            {I18n.t('react.shared.upgrade_modal_info_message')}
          </EloInfoMessage>
        )}
        {ordersStore.ticketUpgradeData?.paymentTickets
          .toSorted((a, b) => Number(b.ticketDateUpgradable) - Number(a.ticketDateUpgradable))
          .map((item, index) => (
            <TicketCard
              key={index}
              expandedByDefault={
                !index && attendeeSectionIsAvailable(item.ticketDate.upgradeMappings, item.ticketDateUpgradable)
              }
              selectedMappingToUpgrade={selectedMappingToUpgrade}
              setTicketToUpgrade={setTicketToUpgrade}
              resetTicketToUpgrade={resetTicketToUpgrade}
              isMobile={isMobile}
              setTestPeriodPopupData={setTestPeriodPopupData}
              {...item}
            />
          ))}
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--between'>
        {!testPeriodPopupData && (
          <>
            {showSummary && (
              <PurchaseTerms
                isFree={false}
                link={''}
                htmlTerms={{
                  ...(upgradeTicketsStore.buildedOrderData?.['cancellationTermPrefs'] || {}),
                  id: upgradeTicketsStore.buildedOrderData?.['cancellationTermId'],
                  checkboxVisible: false,
                }}
                onCheckboxChange={() => {}}
                checked={false}
                productType={ordersStore.data.product?.form}
                invoiceToken={null}
                areHiddenCancellationTerms={false}
                sellerUsername={sellerStore.item.username}
                isPowerSeller={sellerStore.item.powerSeller}
                usePowerSellerDocument={sellerStore.item.documentConfiguration?.termsOfBusiness}
                paymentPageColor=''
              />
            )}
            <div className='summary-container'>
              {showSummary && (
                <Summary
                  rates={upgradeTicketsStore.buildedOrderData?.orderRates}
                  pricingPlan={{
                    currencyId:
                      selectedMappingLength === 1 ? firstMappingPricingPlanCurrencyId : ordersStore.data?.currencyId,
                  }}
                  isLoading={upgradeTicketsStore.buildOrderLoading}
                  additionalFee={{ title: '' }}
                  countryCode={ordersStore.data.payer.countryCode}
                  withoutStarNearPrice={true}
                />
              )}
              <div className='action-buttons'>
                <div className='buttons-container'>
                  <EloButton
                    variant='secondary'
                    size='x-large'
                    onClick={() => {
                      Boolean(searchParams.upgrade_modal)
                        ? (window.location.href = ordersStore.item.product.successUrl)
                        : setUpgradeTicketModalIsOpen(false)
                    }}
                  >
                    {I18n.t('shared.common.cancel')}
                  </EloButton>
                  <EloButton
                    onClick={() => handlePayUpgrades()}
                    size='x-large'
                    disabled={!selectedMappingLength || isUpgradeNotAvailable}
                  >
                    {`${I18n.t('react.shared.button.pay_upgrades')} ${
                      !!selectedMappingLength ? `(${selectedMappingLength})` : ''
                    }`}
                  </EloButton>
                </div>
                {!!selectedMappingLength && (
                  <div className='text-container'>{I18n.t('react.shared.upgrade_modal_confirm_payment_text')}</div>
                )}
              </div>
            </div>
          </>
        )}
      </EloModalFooter>

      {testPeriodPopupData && (
        <div className='tooltip-popup-section'>
          <div className='tooltip-popup-section__price-block'>
            <div className='tooltip-popup-section__tooltip-combined-price'>
              <div className='tooltip-popup-section__tooltip-price'>{testPeriodPopupData?.price}</div>
              <div className='tooltip-popup-section__tooltip-plan'>{testPeriodPopupData?.paymentPlanType}</div>
            </div>
            <EloCloseIcon onClick={() => setTestPeriodPopupData(null)} />
          </div>
          <div className='tooltip-popup-section__tooltip-message'>{testPeriodPopupData?.tooltipMessage}</div>
        </div>
      )}

      {isConfirmationModalOpen && (
        <PaymentConfirmationModal
          isOpen={isConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        />
      )}

      <TicketAvailabilityErrorModal
        ticketAvailabilityModalIsOpen={ticketAvailabilityModalIsOpen}
        onClose={() => {
          setTicketAvailabilityModalIsOpen(false)
        }}
        errorsData={errorsData}
      />
    </EloModal>
  )
})

export default UpgradeTicketsModal
