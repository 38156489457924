import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { notify } from 'libs/common/notify'

import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/styleguide'
import { KLARNA_ROOT_ID, PAYMENT_FORMS, STRIPE_ELEMENT_ROOT_ID } from 'constants/paymentSettingShared.constants'

import { injectStripeScript, isStripeInjected } from 'utils/stripe.utils'
import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'

import {
  createStripeClient,
  createStripeElements,
  createStripeIdeal,
  createStripeKlarna,
  createStripeP24,
  getKlarnaFormSettings,
} from '@elo-kit/utils/stripe.utils'

import { useShopStores } from 'shop/hooks/use-store'
import { LoadingMask } from '@elo-kit/components'

export const PaymentConfirmationModal = observer((props) => {
  const { isOpen, setIsConfirmationModalOpen } = props

  const { upgradeTicketsStore, ordersStore } = useShopStores()

  const stripeClient = useRef(null)
  const stripeElement = useRef(null)
  const stripeElements = useRef(null)
  const [stripeLoading, setStripeLoading] = useState(true)
  const [stripeElementError, setStripeElementError] = useState('')

  useEffect(() => {
    initStripe()
  }, [])

  const initStripe = () => {
    if (!isStripeInjected()) {
      injectStripeScript(handleStripeOnLoad)
    } else {
      handleStripeOnLoad()
    }
  }

  const isKlarna = ordersStore.ticketUpgradeData.paymentForm === PAYMENT_FORMS.klarna

  const handleStripeOnLoad = () => {
    setStripeLoading(false)
    const { paymentForm, provider } = ordersStore.ticketUpgradeData

    const pubKeyProvider = `${snakeCaseToCamelCase(provider)}PubKey`

    const pubKey = ordersStore.data.moneyHolder[pubKeyProvider]
    const isKlarna = paymentForm === PAYMENT_FORMS.klarna

    if (pubKey) {
      stripeClient.current = createStripeClient(pubKey)

      if (isKlarna) {
        stripeElements.current = createStripeElements(stripeClient.current, {
          clientSecret: upgradeTicketsStore.createAndChargeResponse?.clientSecret,
        })
      } else {
        stripeElements.current = createStripeElements(stripeClient.current)
      }

      if (
        paymentForm === PAYMENT_FORMS.p24 ||
        paymentForm === PAYMENT_FORMS.ideal ||
        paymentForm === PAYMENT_FORMS.klarna
      ) {
        if (paymentForm === PAYMENT_FORMS.p24) {
          stripeElement.current = createStripeP24(stripeElements.current)
        }

        if (paymentForm === PAYMENT_FORMS.ideal) {
          stripeElement.current = createStripeIdeal(stripeElements.current)
        }

        if (paymentForm === PAYMENT_FORMS.klarna) {
          stripeElement.current = createStripeKlarna(stripeElements.current, getKlarnaFormSettings())
        }

        if (stripeElement.current) {
          setStripeLoading(true)
          stripeElement.current.mount(`#${isKlarna ? KLARNA_ROOT_ID : STRIPE_ELEMENT_ROOT_ID}`)

          stripeElement.current.on('ready', () => {
            setStripeLoading(false)
          })

          stripeElement.current.on('change', ({ error }) => {
            if (error) {
              setStripeElementError(error.message)
            } else {
              setStripeElementError('')
            }
          })
        }
      }
    }
  }

  return (
    <EloModal isOpen={isOpen}>
      <EloModalHeader>Confirmation</EloModalHeader>
      <EloModalBody className={classNames({ 'elo-ui-modal__body--no-overflow': !isKlarna })}>
        {(stripeLoading || upgradeTicketsStore.loading) && <LoadingMask />}
        <div className='next-amount__p24'>
          <div id={isKlarna ? KLARNA_ROOT_ID : STRIPE_ELEMENT_ROOT_ID} />
          {stripeLoading && <div className='lmask' />}
          {stripeElementError && <div>{stripeElementError}</div>}
        </div>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton variant='secondary' size='x-large' onClick={() => setIsConfirmationModalOpen(false)}>
          {I18n.t('shared.common.cancel')}
        </EloButton>
        <EloButton
          onClick={async () => {
            if (isKlarna) {
              const { error } = await stripeElements.current.submit()
              if (error) {
                notify('error', error)
                return
              }
            }
            upgradeTicketsStore.payForUpgrades(null, {
              stripeClient: stripeClient.current,
              stripeElement: isKlarna ? stripeElements.current : stripeElement.current,
            })
          }}
          size='x-large'
        >
          {I18n.t('react.shared.button.pay')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
