import React from 'react'

import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloWarningOctagonIcon } from '@elo-ui/components/icons/regular'

import { ErrorPopupData } from './UpgradeTicketsModal'

import './ticket-availability-error-modal.scss'

interface Props {
  onClose: () => void
  ticketAvailabilityModalIsOpen: boolean
  errorsData: ErrorPopupData | null
}

export const TicketAvailabilityErrorModal: React.FC<Props> = ({
  ticketAvailabilityModalIsOpen,
  onClose,
  errorsData,
}) => (
  <EloModal isOpen={ticketAvailabilityModalIsOpen} onClose={onClose} className='ticket-availability-error-modal'>
    <EloModalHeader>
      <div className='ticket-availability-modal__title'>
        <div className='ticket-availability-modal__icon'>
          <EloWarningOctagonIcon size={32} />
        </div>
        <div className='rticket-availability-modal__title-label'>{I18n.t('react.shared.ticket_not_available')}</div>
      </div>
    </EloModalHeader>
    <EloModalBody className='ticket-availability-error-modal__body'>
      {!!errorsData?.errors?.stock?.length && (
        <>
          <div>{I18n.t('react.shared.ticket_not_available_body_with_count')}</div>
          <ul>{errorsData?.errors?.stock.map((item, index) => <li key={index}>{item}</li>)}</ul>
        </>
      )}
      {!!errorsData?.errors?.other?.length && (
        <>
          <div>{I18n.t('react.shared.ticket_not_available_body_without_count')}</div>
          <ul>{errorsData?.errors?.other.map((item, index) => <li key={index}>{item}</li>)}</ul>
        </>
      )}
    </EloModalBody>
    <EloModalFooter className='elo-ui-modal__footer--end'>
      <EloButton variant='primary' onClick={onClose} size='x-large'>
        {I18n.t('react.shared.button.okay')}
      </EloButton>
    </EloModalFooter>
  </EloModal>
)
