import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.cabinet.background'),
    tooltipId: 'block_background_settings',
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    label: I18n.t('react.shared.min_height'),
    fields: [
      {
        field: 'pixels',
        label: I18n.t('react.shared.min_height'),
        key: 'minHeight',
      },
    ],
  },
]
