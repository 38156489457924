import { useEffect, useState } from 'react'
import moment from 'moment'

import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

const useTimer = (props) => {
  const {
    endTime,
    startTime = moment(),
    tickStep = 1,
    tickUnit = 'seconds',
    isCounter,
    previewMode,
    newEndDate,
  } = props
  const [time, setTime] = useState(startTime.format(DATE_FORMATS.DDMMYYYYHHmmss))
  const [endDate, setEndDate] = useState(endTime)
  const timerEndTime = isCounter ? endDate : endTime
  const isTimerEnded = moment(time, DATE_FORMATS.DDMMYYYYHHmmss).isSameOrAfter(timerEndTime)

  useEffect(() => {
    if (isCounter) {
      setTime(startTime)
      setEndDate(moment(new Date(), DATE_FORMATS.DDMMYYYYHHmmss))
    } else {
      setTime(moment(new Date(), DATE_FORMATS.DDMMYYYYHHmmss))
      setEndDate(endTime)
    }
  }, [isCounter, newEndDate])

  useEffect(() => {
    const updateTime = () => {
      setEndDate(timerEndTime)
      const momentTime = moment(time, DATE_FORMATS.DDMMYYYYHHmmss).add(tickStep, tickUnit)

      isCounter
        ? setEndDate(moment(new Date(), DATE_FORMATS.DDMMYYYYHHmmss))
        : setTime(momentTime.format(DATE_FORMATS.DDMMYYYYHHmmss))
    }

    let timer
    if (!isTimerEnded && !previewMode) {
      timer = setTimeout(updateTime, 1000)
    }

    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (isTimerEnded) {
      setTime(timerEndTime)
    }
  }, [isTimerEnded])

  const momentTime = moment(time, DATE_FORMATS.DDMMYYYYHHmmss)
  const diff = timerEndTime.diff(momentTime)

  return moment.duration(diff, 'milliseconds')
}

export default useTimer
