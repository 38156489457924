import React from 'react'
import { EloIconProps } from '@elo-ui/components/icons/payment-methods/types'
import { EloBaseIconProps } from '@elo-ui/components/icons/regular/_base'

import './story-icon-preview.scss'

interface Props {
  name: string
  icon: React.ComponentType<EloBaseIconProps | EloIconProps>
  iconProps: EloBaseIconProps | EloIconProps
}

export const StoryIconPreview: React.FC<Props> = ({ icon: Icon, name, iconProps }) => (
  <div className='story-icon-preview'>
    <Icon {...iconProps} />
    <span className='name'>{name}</span>
  </div>
)
