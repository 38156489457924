import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { useOverflowed } from '@elo-ui/hooks/use-overflowed'
import { PRODUCT_TYPES, LinkProps } from '@elo-ui/types'
import { ProductType } from 'types/helpers'
import { EloAvatar } from '@elo-ui/components/elo-avatar'
import {
  EloCertificateIcon,
  EloCheckoutIcon,
  EloDownloadIcon,
  EloMembershipIcon,
  EloOnlineCourseIcon,
  EloTicketIcon,
  EloLicenseCodeIcon,
  EloBundleIcon,
} from '@elo-ui/components/icons/product-types'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display'

import { EloTooltip, EloTooltipContent, EloTooltipTitle } from '../elo-tooltip'
import { EloLink } from '../elo-link'

import './elo-product-display.scss'

interface Props {
  type?: ProductType
  name: string
  supportiveText?: string | JSX.Element
  image?: string
  linkOptions?: LinkProps
  tooltipOptions?: {
    nameTitle?: string
    internalNameTitle?: string
    iconTitle?: string
    iconDesc?: string
    placement?: 'top' | 'left' | 'right' | 'bottom'
  }
  size?: 'small' | 'large' | 'extra-large'
  price?: string
  supportivePriceText?: string
  netPrice?: string
  children?: React.ReactNode
  descriptionContent?: React.ReactNode
  hideImage?: boolean
}

const productIcons = {
  [PRODUCT_TYPES.digital]: EloCheckoutIcon,
  [PRODUCT_TYPES.download]: EloDownloadIcon,
  [PRODUCT_TYPES.course]: EloOnlineCourseIcon,
  [PRODUCT_TYPES.eventTickets]: EloTicketIcon,
  [PRODUCT_TYPES.membership]: EloMembershipIcon,
  [PRODUCT_TYPES.bundle]: EloBundleIcon,
  [PRODUCT_TYPES.licenseKey]: EloLicenseCodeIcon,
  [PRODUCT_TYPES.certificate]: EloCertificateIcon,
}

export const EloProductDisplay: React.FC<Props> = (props) => {
  const [isMultipleLine, setIsMultipleLine] = useState(false)
  const {
    type = null,
    name = '',
    supportiveText = '',
    image = '',
    linkOptions = {},
    tooltipOptions = {},
    size = 'small',
    price,
    supportivePriceText,
    netPrice,
    hideImage = false,
  } = props
  const { nameTitle = '', internalNameTitle = '', iconTitle = '', iconDesc = '', placement = 'right' } = tooltipOptions

  const nameRef = useRef(null)
  const internalNameRef = useRef(null)

  const { isOverflowed: isNameOverflowed } = useOverflowed(nameRef)
  const { isOverflowed: isInternalNameOverflowed } = useOverflowed(internalNameRef)

  useEffect(() => {
    if (internalNameRef.current?.clientHeight > 25 && size !== 'extra-large') {
      setIsMultipleLine(true)
    } else {
      setIsMultipleLine(false)
    }
  }, [internalNameRef.current?.clientHeight])

  const isSmall = size === 'small'
  const isLarge = size === 'large'
  const isExtraLarge = size === 'extra-large'

  const Content = ({ children }) => {
    if (linkOptions.link || linkOptions.onClick) {
      return (
        <EloLink className='elo-product-display__link' {...linkOptions} style={{ textDecoration: 'none' }}>
          {children}
        </EloLink>
      )
    }

    return children
  }

  const Icon = ({ width = 32, height = 32 }) => {
    if (!type) return null
    const Icon = productIcons[type]

    const iconClasses = classNames(
      `elo-product-display__type-icon elo-product-display__type-icon--${isMultipleLine ? 'large' : size}`
    )

    return (
      Icon && (
        <div className={iconClasses}>
          {iconTitle ? (
            <EloTooltip title={iconTitle} content={iconDesc || type} placement={placement}>
              <Icon width={width} height={height} />
            </EloTooltip>
          ) : (
            <Icon width={width} height={height} />
          )}
        </div>
      )
    )
  }

  const Description = ({ children }) => {
    if (isNameOverflowed || isInternalNameOverflowed) {
      return (
        <EloTooltip
          wrapperClassName='elo-product-display__description'
          placement={placement}
          body={<DescriptionTooltipContent />}
        >
          {children}
        </EloTooltip>
      )
    }

    return <div className='elo-product-display__description'>{children}</div>
  }

  const DescriptionTooltipContent = () => (
    <>
      {isNameOverflowed && name && (
        <>
          <EloTooltipTitle title={nameTitle} />
          <EloTooltipContent content={name} />
        </>
      )}
      {isInternalNameOverflowed && supportiveText && (
        <>
          <EloTooltipTitle title={internalNameTitle} />
          <EloTooltipContent content={supportiveText} />
        </>
      )}
    </>
  )

  const classes = classNames('elo-product-display', `elo-product-display--size-${isMultipleLine ? 'large' : size}`)

  const avatarInitSize = isSmall && !isMultipleLine ? 'large' : 'extra-large'
  const avatarSize = isExtraLarge ? 'extra-extra-large' : avatarInitSize

  return (
    <div className={classes}>
      <Content>
        <div className='elo-product-display__image'>
          {!hideImage && <EloAvatar shape='square' size={avatarSize} src={image} alt={name} />}
          <Icon
            {...(isSmall &&
              !isMultipleLine && {
                width: 24,
                height: 24,
              })}
          />
        </div>
        <Description>
          {name && (
            <div className='elo-product-display__name' ref={nameRef}>
              {name}
            </div>
          )}
          {(isLarge || isExtraLarge || isMultipleLine) && price && (
            <div className='elo-product-display__prices'>
              {Array.isArray(price) ? (
                <>
                  {price.map((item, index) => {
                    if (index > 2) {
                      return null
                    }

                    return (
                      <EloPriceDisplay
                        key={index}
                        price={item.price}
                        netPrice={item.netPrice}
                        supportiveText={item.supportivePriceText}
                      />
                    )
                  })}
                  {price.length > 3 && <div>...</div>}
                </>
              ) : (
                <EloPriceDisplay price={price} netPrice={netPrice} supportiveText={supportivePriceText} />
              )}
            </div>
          )}
          {supportiveText && (
            <div className='elo-product-display__internal-name' ref={internalNameRef}>
              {supportiveText}
            </div>
          )}
        </Description>
      </Content>
    </div>
  )
}
