import React, { Fragment } from 'react'

import checkedIcon from 'images/payment-sucess-check.png'

import { arrayLast } from 'utils/array.utils'
import { formatDateTime } from '@elo-kit/utils/dateTime.utils'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner'

import {
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  PAYMENT_SYSTEMS,
  PAYMENT_FORMS_LOGOS,
} from 'constants/paymentSettingShared.constants'
import { TRANSFER_PROVIDERS } from 'constants/transfersShared.constants'

import { getSellerLink, getManagePageLink } from 'utils/helpers.utils'

import Sofort from 'images/payment_methods/SofortDark.svg'
import Sepa from 'shared/components/purchase-methods/assets/paymethods/Sepa.svg'
import { Order } from 'shop/types/orders'

import PaymentWaiting from './PaymentWaiting'
import MesageBoxSection from './MesageBoxSection'

interface Props {
  currencyId: number
  order: Order
  showProductsInfo: boolean
  isPaymentCancelled: boolean
  classes: Record<string, string>
  sellerStore
  ordersStore
  contentPageStore
}

export const PaymentInProgress: React.FC<Props> = (props) => {
  const {
    contentPageStore: {
      seller: { username },
      convertToPrice,
    },
    ordersStore: {
      data: { sofortWithImmediateAccess },
      loading: ordersStoreLoading,
    },
    sellerStore,
    ordersStore,
  } = props

  const { currencyId, order, showProductsInfo, isPaymentCancelled, classes } = props

  const { company, invoices, mandateUrl, paymentForm, sellables, reseller, transfers = [], withReseller } = order

  const [isBankWire, isSepa, isSofort, isPayLater] = [
    PAYMENT_FORMS.bankWire,
    PAYMENT_FORMS.sepa,
    PAYMENT_FORMS.sofort,
    PAYMENT_FORMS.payLater,
  ].map((name) => paymentForm === name)

  const firstProduct = sellables?.[0]?.product
  const lastTransfer: any = arrayLast(transfers)
  const lastInvoice = arrayLast(invoices)

  const { provider, providerData, form } = lastTransfer || {}

  const { bankAccount, reference, virtualIban } = providerData || {}

  const isMangopay = provider === PAYMENT_PROVIDERS.mangoPay
  const sepaMangopayPending = isSepa && order.provider === PAYMENT_PROVIDERS.mangoPay && !lastTransfer
  const stripeSofortPending =
    isSofort && (order.provider === PAYMENT_PROVIDERS.stripe || order.provider === PAYMENT_PROVIDERS.elopageConnect)

  const isPaymentAddressHidden = provider === TRANSFER_PROVIDERS.elopageConnect && form === PAYMENT_FORMS.bankWire

  const hidePaymentInProgressPage = lastTransfer
    ? !lastTransfer.inProgress && !isMangopay && !isPaymentAddressHidden
    : !isPayLater

  if (sepaMangopayPending) {
    return (
      <PaymentWaiting
        icon={Sepa}
        type={PAYMENT_PROVIDERS.mangoPay}
        title={I18n.t('react.shop.payment.sepa.mangopay_title')}
        description={I18n.t('react.shop.payment.sepa.mangopay_description')}
        classes={classes}
      />
    )
  }

  if (ordersStoreLoading) {
    if (stripeSofortPending && lastTransfer.inProgress && !sofortWithImmediateAccess) {
      return (
        <PaymentWaiting
          icon={Sofort}
          type={PAYMENT_PROVIDERS.stripe}
          title={I18n.t('react.shop.payment.stripe.sofort_title')}
          description={I18n.t('react.shop.payment.stripe.sofort_description')}
          classes={classes}
        />
      )
    }
  } else {
    ;<LoadingSpinner />
  }

  if (hidePaymentInProgressPage) {
    return null
  }

  const receiverDetailsLocale = isMangopay
    ? I18n.t('react.shop.payment.bank_wire.receiver_details.label.mangopay_receiver')
    : I18n.t('react.shop.payment.bank_wire.receiver_details.label.note_about_comment')
  const receiverDetails =
    provider === PAYMENT_SYSTEMS.directBank
      ? I18n.t('react.shop.payment.bank_wire.receiver_details.label.direct_bank', { address: reseller.address })
      : receiverDetailsLocale

  const showVirtualIbanReceiverDetails = isBankWire && isMangopay && virtualIban
  const virtualOrLocationReceiverDetails = showVirtualIbanReceiverDetails
    ? I18n.t('react.shop.payment.bank_wire.receiver_details.label.mangopay_virtual_iban_receiver')
    : receiverDetails

  const descriptionTexts = {
    bankWire: I18n.t('react.shop.payment.bank_wire.description', {
      href: mandateUrl,
      company,
      deadline: (lastInvoice || {}).deadline,
      payment_link: (lastInvoice || {}).paymentLink,
    }),
    sepa: I18n.t('react.shop.payment.sepa.description', {
      href: mandateUrl,
      company,
      deadline: (lastInvoice || {}).deadline,
      payment_link: (lastInvoice || {}).paymentLink,
    }),
    sofort: I18n.t('react.shop.payment.sofort.description', {
      href: mandateUrl,
      company,
      deadline: (lastInvoice || {}).deadline,
      payment_link: (lastInvoice || {}).paymentLink,
    }),
    payLater: I18n.t('react.shop.payment.pay_later.description_new', {
      href: mandateUrl,
      company,
      deadline: (lastInvoice || {}).deadline,
      payment_link: (lastInvoice || {}).paymentLink,
    }),
  }
  const descriptionText = descriptionTexts[paymentForm]

  const dealPaymentLink = getSellerLink(
    sellerStore.item,
    `deals/${ordersStore.data?.deal?.idForSeller}/payment/${ordersStore.data?.token}`
  )
  const managePageLink = getManagePageLink(sellerStore.item, ordersStore.data?.token)

  const isBindingOffer = !invoices.length && !!ordersStore?.data?.deal

  return (
    <div className={classes.paymentInProgressContainer}>
      <div className={classes.paymentInProgressCentered}>
        <img
          className={classes.paymentInProgressLogo}
          src={PAYMENT_FORMS_LOGOS[paymentForm]}
          alt='paymentLogo'
          style={{ maxHeight: '55px' }}
        />
        {!isPaymentCancelled && (
          <Fragment>
            {(isBankWire || isPayLater) && (
              <div>
                <div className={classes.paymentInProgressTitle}>{I18n.t('react.shared.amount_to_be_paid')}</div>
                <div translate='no' className={classes.paymentInProgressPrice}>
                  {convertToPrice((lastTransfer || lastInvoice || {}).total, currencyId)}
                </div>
                <div>{formatDateTime((lastTransfer || lastInvoice || {})?.createdAt)}</div>
              </div>
            )}
            {isBindingOffer ? (
              <div
                className={classes.paymentInProgressDescription}
                dangerouslySetInnerHTML={{
                  __html: I18n.t('react.shop.payment.pay_later.description_deal', {
                    manage_page_link: managePageLink,
                    payment_link: dealPaymentLink,
                  }),
                }}
              />
            ) : (
              <div
                className={classes.paymentInProgressDescription}
                dangerouslySetInnerHTML={{
                  __html: descriptionText,
                }}
              />
            )}
            {isBankWire && (
              <div className={classes.messageBox}>
                <div className={classes.messageBoxTitle}>
                  {I18n.t('react.shop.payment.bank_wire.receiver_details.label.title')}
                </div>

                <MesageBoxSection
                  classes={classes}
                  name={I18n.t('react.shop.payment.bank_wire.receiver_details.label.bank_name')}
                  value={bankAccount?.owner}
                />
                <MesageBoxSection
                  classes={classes}
                  name={I18n.t('react.shop.payment.bank_wire.receiver_details.label.iban')}
                  value={bankAccount?.iban || I18n.t('react.shop.payment.bank_wire.receiver_details.iban')}
                />
                <MesageBoxSection
                  classes={classes}
                  name={I18n.t('react.shop.payment.bank_wire.receiver_details.label.bic')}
                  value={bankAccount?.bic}
                />
                <MesageBoxSection
                  classes={classes}
                  name={I18n.t('react.shop.payment.bank_wire.receiver_details.label.reference_id')}
                  value={reference || `ord-${order.id}`}
                />
                {!isPaymentAddressHidden && (
                  <MesageBoxSection classes={classes} value={virtualOrLocationReceiverDetails} />
                )}
              </div>
            )}

            {isSepa && withReseller && (
              <div className={classes.messageBox}>
                <div className={classes.messageBoxTitle}>{I18n.t('react.shop.payment.transfer_details.title')}</div>

                {firstProduct && (
                  <MesageBoxSection
                    classes={classes}
                    name={I18n.t('react.shop.payment.transfer_details.product_name')}
                    value={firstProduct.name}
                  />
                )}
                <MesageBoxSection
                  classes={classes}
                  name={I18n.t('react.shop.payment.transfer_details.product_price')}
                  value={convertToPrice(lastTransfer.total, currencyId)}
                />
                <MesageBoxSection
                  classes={classes}
                  name={I18n.t('react.shop.payment.transfer_details.transfer_id')}
                  value={String(lastTransfer.idForSeller)}
                />

                <hr className={classes.lightSeparator} />

                <div className={classes.messageBoxFooter}>
                  <div>
                    <img src={String(checkedIcon)} alt='icon' />
                    <div className={classes.messageBoxFooterText}>
                      {I18n.t('react.shop.reseller_of')}
                      <b>{username}</b>
                    </div>
                  </div>

                  <img src={reseller.logo as string} alt='logo' />
                </div>
              </div>
            )}

            <div className={classes.paymentInProgressTitle}>{I18n.t('react.shop.payment.you_receive_email')}</div>

            {showProductsInfo &&
              sellables?.map(
                (sellablesItem) =>
                  sellablesItem.product.additionalInfo && (
                    <div key={sellablesItem.product.id} className='additional-info padding-10px fr-view'>
                      <div dangerouslySetInnerHTML={{ __html: sellablesItem.product.additionalInfo }} />
                    </div>
                  )
              )}
          </Fragment>
        )}
      </div>
    </div>
  )
}
