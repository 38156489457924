import React from 'react'
import { PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'
import NotificationSection from './NotificationSection'

const PaymentWaiting = ({ icon, type = '', title = '', description = '', classes }) => (
  <NotificationSection
    imgUrl={icon}
    alt={PAYMENT_PROVIDERS[type]}
    title={title}
    description={description}
    onClick={() => window.location.reload()}
    btnText={I18n.t('react.shared.button.refresh_page')}
    btnIcon='fas fa-sync-alt'
    classes={classes}
  />
)

export default PaymentWaiting
