import React from 'react'

import { PAYMENT_PROVIDERS, PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { getShopProductLink, withStores } from 'utils/helpers.utils'
import { arrayLast } from 'utils/array.utils'

import NotificationSection from './NotificationSection'

const PaymentError = (props) => {
  const {
    contentPageStore: { ordersStore, seller },
    error = '',
    classes,
  } = props
  const { chargePS, list, item } = ordersStore
  const { slug, name } = item
  const productLink = getShopProductLink(seller, slug)
  const { transfers = [] } = list[0] || {}
  const lastTransfer = arrayLast(transfers) || {}

  const showRetry = lastTransfer.provider === PAYMENT_PROVIDERS.lemonWay && lastTransfer.form === PAYMENT_FORMS.card

  const handleRetry = showRetry ? () => chargePS() : null

  return (
    <NotificationSection
      icon='fas fa-exclamation-triangle'
      title={I18n.t('react.shared.error.title')}
      description={I18n.t('react.shop.payment.error')}
      onClick={handleRetry}
      btnText={I18n.t('react.shop.order.button.retry')}
      classes={classes}
      message={error}
      link={productLink}
      linkName={name}
    />
  )
}

export default withStores(['contentPageStore'], PaymentError)
