import { COLORS } from '@elo-kit/constants/general.constants'

const filePdfStyles = () => {
  const { eloWhite, eloGrey20 } = COLORS

  return {
    noPdfContainer: {
      position: 'relative',
      minHeight: '300px',
      width: '100%',
      background: eloGrey20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      '& i': {
        fontSize: '100px',
        color: eloWhite,
      },
    },
  }
}

export default filePdfStyles
