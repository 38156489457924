import React from 'react'
import { EloButton } from '@elo-ui/components/elo-button'

const UpgradeTicketsOption = ({ classes, setUpgradeTicketModalIsOpen }) => (
  <div className={classes.upgradeTicketsSection}>
    <div className={classes.upgradeTicketsText}>
      <div className={classes.upgradeTicketsLabel}>{I18n.t('react.shared.upgrade_your_tickets_now')}</div>
      <div className={classes.upgradeTicketsDescription}>{I18n.t('react.shared.upgrading_description')}</div>
    </div>
    <EloButton variant='highlight' size='x-large' onClick={() => setUpgradeTicketModalIsOpen(true)}>
      {I18n.t('react.shared.upgrade')}
    </EloButton>
  </div>
)

export default UpgradeTicketsOption
