import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    fields: [BLOCKS_WITH_COLOR_PICKER.backgroundColor],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.footer.menuItems,
    label: I18n.t('react.cabinet.menu_items'),
    fields: ['addFooterMenu'],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.footer.menuItems,
    label: I18n.t('react.cabinet.menu_style'),
    tooltipId: 'menu_style',
    fields: [
      [
        {
          field: 'font',
          key: 'menuFont',
        },
        {
          field: 'fontStyle',
          key: 'menuStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.menuColor,
        },
        {
          field: 'fontSize',
          key: 'menuSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.footer.submenuStyle,
    label: I18n.t('react.cabinet.submenu_style'),
    tooltipId: 'submenu_style',
    fields: [
      [
        {
          field: 'font',
          key: 'submenuFont',
        },
        {
          field: 'fontStyle',
          key: 'submenuStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.submenuColor,
        },
        {
          field: 'fontSize',
          key: 'submenuSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.footer.websiteAndSocials,
    label: I18n.t('react.shared.website_and_socials'),
    toggle: 'socialsIsShown',
    fields: [
      {
        field: 'text',
        key: 'socialsTitle',
        label: I18n.t('react.cabinet.column_title'),
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.socialsColor,
      },
      {
        field: 'link',
        key: 'website',
        label: I18n.t('react.cabinet.website'),
      },
      {
        field: 'link',
        key: 'facebook',
        label: I18n.t('react.cabinet.facebook'),
      },
      {
        field: 'link',
        key: 'instagram',
        label: I18n.t('react.cabinet.instagram'),
      },
      {
        field: 'link',
        key: 'twitter',
        label: I18n.t('react.cabinet.twitter'),
      },
      {
        field: 'link',
        key: 'youtube',
        label: I18n.t('react.cabinet.youtube'),
      },
      {
        field: 'link',
        key: 'tumblr',
        label: I18n.t('react.cabinet.tumblr'),
      },
      {
        field: 'link',
        key: 'pinterest',
        label: I18n.t('react.cabinet.pinterest'),
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
