import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@elo-kit/components/page-builder/blocks/text/Text'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { getThemeProps } from '@elo-kit/utils/block.utils'

import { useTextStyles } from './Text.styles'

const TextPreview = ({ block, previewMode }) => {
  const classes = useTextStyles({
    theme: getThemeProps(block),
  })

  return (
    <Text
      classes={classes}
      text={block.content.text || ''}
      previewMode={previewMode}
      customClassName={block.content[CUSTOM_CLASS_NAME_OPTION]}
      block={block}
    />
  )
}

TextPreview.propTypes = {
  previewMode: PropTypes.bool,
  block: PropTypes.shape({
    content: PropTypes.object,
  }),
}

TextPreview.defaultProps = {
  previewMode: false,
  block: {},
}

export default TextPreview
