import React from 'react'
import NotificationSection from './NotificationSection'

const PaymentCanceled = ({ classes, redirectToManagePage }) => (
  <NotificationSection
    icon='fas fa-ban'
    title={I18n.t('react.shop.payment.success.payment_cancel_title')}
    description={I18n.t('react.shop.payment.success.payment_cancel_sub_title')}
    onClick={redirectToManagePage}
    btnText={I18n.t('react.shop.payment.success.back')}
    classes={classes}
  />
)

export default PaymentCanceled
