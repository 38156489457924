import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.background,
      },
    ],
  },
  {
    label: I18n.t('react.shared.slider'),
    fields: [
      {
        label: I18n.t('react.shared.arrows'),
        fields: [
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.arrowsColor,
            },
            {
              field: 'fontSize',
              key: 'arrowFontSize',
            },
          ],
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.productTitle,
    label: I18n.t('react.shared.product_title'),
    fields: [
      [
        {
          field: 'font',
          key: 'textFont',
        },
        {
          field: 'fontStyle',
          key: 'textStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.textColor,
        },
        {
          field: 'fontSize',
          key: 'textSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.backButton,
    label: I18n.t('react.shared.back_button'),
    fields: [
      [
        {
          field: 'font',
          key: 'backButtonFont',
        },
        {
          field: 'fontStyle',
          key: 'backButtonStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.backButtonColor,
        },
        {
          field: 'fontSize',
          key: 'backButtonSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.productPrice,
    label: I18n.t('react.shared.product_price'),
    fields: [
      [
        {
          field: 'font',
          key: 'priceFont',
        },
        {
          field: 'fontStyle',
          key: 'priceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.priceColor,
        },
        {
          field: 'fontSize',
          key: 'priceSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.currency,
    label: I18n.t('react.common.currency'),
    fields: [
      [
        {
          field: 'font',
          key: 'currencyFont',
        },
        {
          field: 'fontStyle',
          key: 'currencyStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.currencyColor,
        },
        {
          field: 'fontSize',
          key: 'currencySize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.oldPrice,
    label: I18n.t('react.cabinet.pricing_plan.form.lb.old_price'),
    tooltipId: 'block_old_price',
    fields: [
      [
        {
          field: 'font',
          key: 'oldPriceFont',
        },
        {
          field: 'fontStyle',
          key: 'oldPriceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.oldPriceColor,
        },
        {
          field: 'fontSize',
          key: 'oldPriceSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.paymentInformation,
    label: I18n.t('react.shared.payment_information'),
    fields: [
      [
        {
          field: 'font',
          key: 'payInfoFont',
        },
        {
          field: 'fontStyle',
          key: 'payInfoStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.payInfoColor,
        },
        {
          field: 'fontSize',
          key: 'payInfoSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.paymentButton,
    label: I18n.t('react.shared.payment_button'),
    fields: [
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      ['cornerRadius'],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.coursePlan,
    label: I18n.t('react.shared.course_plan'),
    toggle: 'coursePreview',
    fields: [
      {
        label: I18n.t('react.shared.section_title'),
        fields: [
          [
            {
              field: 'font',
              key: 'planTitleFont',
            },
            {
              field: 'fontStyle',
              key: 'planTitleStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.planTitleColor,
            },
            {
              field: 'fontSize',
              key: 'planTitleSize',
            },
          ],
        ],
      },
      {
        label: I18n.t('react.cabinet.common.course_schedule'),
        fields: [
          [
            {
              field: 'font',
              key: 'planFont',
            },
            {
              field: 'fontStyle',
              key: 'planStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.planColor,
            },
            {
              field: 'fontSize',
              key: 'planSize',
            },
          ],
        ],
      },
      {
        label: I18n.t('react.shared.preview_button'),
        fields: [
          {
            field: 'color',
            key: BLOCKS_WITH_COLOR_PICKER.previewButtonColor,
            label: I18n.t('react.cabinet.button_color'),
          },
          [
            {
              field: 'font',
              key: 'previewButtonFont',
            },
            {
              field: 'fontStyle',
              key: 'previewButtonStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.previewButtonTextColor,
            },
            {
              field: 'fontSize',
              key: 'previewButtonSize',
            },
          ],
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.availableTickets,
    label: I18n.t('react.shared.available_tickets'),
    toggle: 'ticketsPreview',
    fields: [
      {
        label: I18n.t('react.shared.section_title'),
        fields: [
          [
            {
              field: 'font',
              key: 'ticketsTitleFont',
            },
            {
              field: 'fontStyle',
              key: 'ticketsTitleStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.ticketsTitleColor,
            },
            {
              field: 'fontSize',
              key: 'ticketsTitleSize',
            },
          ],
        ],
      },
      {
        label: I18n.t('react.shared.tickets_details'),
        fields: [
          [
            {
              field: 'font',
              key: 'ticketsDetailsFont',
            },
            {
              field: 'fontStyle',
              key: 'ticketsDetailsStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.ticketsDetailsColor,
            },
            {
              field: 'fontSize',
              key: 'ticketsDetailsSize',
            },
          ],
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.product.overviewAboutFiles,
    label: I18n.t('react.shared.nav.overview'),
    tooltipId: 'download_files_list',
    toggle: 'downloadPreview',
    fields: [
      {
        label: I18n.t('react.shared.section_title'),
        fields: [
          [
            {
              field: 'font',
              key: 'downloadTitleFont',
            },
            {
              field: 'fontStyle',
              key: 'downloadTitleStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.downloadTitleColor,
            },
            {
              field: 'fontSize',
              key: 'downloadTitleSize',
            },
          ],
        ],
      },
      {
        label: I18n.t('react.shared.download_details'),
        fields: [
          [
            {
              field: 'font',
              key: 'downloadDetailsFont',
            },
            {
              field: 'fontStyle',
              key: 'downloadDetailsStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.downloadDetailsColor,
            },
            {
              field: 'fontSize',
              key: 'downloadDetailsSize',
            },
          ],
        ],
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
