const isVisibleOnScreen = (isHidden) => (isHidden ? 'none' : 'block')

// TODO: update with using createUseStyles
const contentBlockStyles = (theme) => ({
  contentBlock: {
    '@media (min-width: 992px)': {
      display: isVisibleOnScreen(theme['desktopHidden']),
    },

    '@media (max-width: 991px)': {
      display: isVisibleOnScreen(theme['tabletHidden']),
    },

    '@media (max-width: 576px)': {
      display: isVisibleOnScreen(theme['mobileHidden']),
    },
  },
})

export default contentBlockStyles
