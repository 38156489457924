import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import { BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { getThemeProps } from '@elo-kit/utils/block.utils'
import { useMarketingToolsStyles } from './conversionToolsStyles'

import Countdown from './views/Countdown'
import ModalWindow from './views/ModalWindow'
import ProgressBar from './views/Progressbar'

export const ConversionToolsPreview = (props) => {
  const { block, block: { content: { view } = {} } = {}, previewMode } = props
  const isModalView = view === BLOCK_VIEW.modal
  const classes = useMarketingToolsStyles({
    theme: getThemeProps(block),
  })

  const renderConversionToolsView = () => {
    switch (view) {
      case BLOCK_VIEW.countdown: {
        return <Countdown {...props} classes={classes} />
      }
      case BLOCK_VIEW.modal: {
        return <ModalWindow {...props} classes={classes} />
      }
      case BLOCK_VIEW.progressBar: {
        return <ProgressBar {...props} classes={classes} />
      }
      default: {
        return null
      }
    }
  }

  return (
    <div
      className={classNames(classes.container, {
        [classes.previewContainer]: previewMode,
      })}
    >
      {!isModalView && <div className={classes.containerOverlay} />}
      {renderConversionToolsView()}
    </div>
  )
}

export default observer(ConversionToolsPreview)
