import React from 'react'

import { withStores } from 'utils/helpers.utils'
import { Funnel } from '@elo-kit/components/page-builder/blocks/funnel'

const FunnelPreview = (props) => {
  const {
    block,
    previewMode,
    contentPageStore: {
      handleFunnelReject = () => {},
      handleFunnelAccept = () => {},
      funnelHandled,
      setFunnelHandled,
      ordersStore,
      seller,
    },
  } = props

  return (
    <Funnel
      block={block}
      previewMode={previewMode}
      handleFunnelReject={handleFunnelReject}
      handleFunnelAccept={handleFunnelAccept}
      funnelHandled={funnelHandled}
      setFunnelHandled={setFunnelHandled}
      defaultButtonText={I18n.t('react.shared.funnels.no_thanks_uppercased')}
      noTextMessage={I18n.t('react.cabinet.funnels.block_required_settings')}
      ordersStore={ordersStore}
      seller={seller}
    />
  )
}

FunnelPreview.displayName = 'FunnelPreview'
FunnelPreview.defaultProps = {
  previewMode: false,
}

const stores = ['contentPageStore']
export default withStores(stores, FunnelPreview)
