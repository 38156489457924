import { getAlignment } from 'utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { POSITIONS } from 'constants/general.constants'
import { COLORS, ZERO_DATA } from '@elo-kit/constants/general.constants'
import { TEXT, DEFAULT_TEXT_PADDING, BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { cleanupFonts } from 'utils/fonts.utils'
import { createUseStyles } from 'react-jss'
import { PADDING_UNITS } from '../../../../page-builder/constants/block.constants'

const { eloWhite, eloBlue, eloBlack } = COLORS
const { center } = POSITIONS

export const useAffiliateStyles = createUseStyles((theme) => {
  const defaultPadding = theme.paddingUnits === PADDING_UNITS.px ? DEFAULT_TEXT_PADDING : ZERO_DATA
  const getPaddingValue = (value, defaultValue, unit = '%') => {
    if (value || value === 0) {
      return `${value}${theme.paddingUnits || unit}`
    }

    return `${defaultValue}${theme.paddingUnits || unit}`
  }

  return {
    container: {
      padding: theme.view === BLOCK_VIEW.button ? '10px 0' : 0,
      display: 'flex',
      justifyContent: getAlignment(theme.horizontalAlignment),
      alignItems: 'center',
      height: '100%',
      maxWidth: '100%',
      minHeight: theme.view === BLOCK_VIEW.button ? `${theme.minHeight || ZERO_DATA}px` : 'unset',
    },
    button: {
      position: 'relative',
      background: theme.buttonViewStyle === BUTTON_STYLE.filled ? theme.buttonColor || eloBlue : 'none',
      color: theme.buttonTextColor || eloWhite,
      border: `2px solid ${theme.buttonColor || eloBlack}`,
      padding: '13px 16px',
      maxWidth: `${theme.buttonWidth || 170}px`,
      width: `${theme.buttonWidth || 170}px`,
      borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
      ...getFontStyles(theme.buttonStyle || 'bold'),
      fontSize: `${theme.buttonSize || 15}px`,
      cursor: 'pointer',
      wordBreak: 'break-word',
      textAlign: 'center',
      lineHeight: 1,
      zIndex: 0,
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        opacity: 0.7,
      },
    },

    ...buttonAnimations(theme),

    textContainer: {
      position: 'relative',
      paddingTop: getPaddingValue(theme[TEXT.paddingTop], defaultPadding, theme.paddingUnits),
      paddingBottom: getPaddingValue(theme[TEXT.paddingBottom], defaultPadding, theme.paddingUnits),
      paddingRight: getPaddingValue(theme[TEXT.paddingRight], defaultPadding, theme.paddingUnits),
      paddingLeft: getPaddingValue(theme[TEXT.paddingLeft], defaultPadding, theme.paddingUnits),
      height: '100%',
      minHeight: `${theme.minHeight || ZERO_DATA}px`,
      width: `${theme.width || 100}%`,
      maxWidth: theme.maxWidth ? `${theme.maxWidth}px` : 'none',
      margin: '0 auto',
      wordBreak: 'break-word',

      '& span': {
        wordBreak: 'break-word',
      },

      '& .affiliate-program__title, h2': {
        color: eloBlack,
        fontSize: '24px',
        fontFamily: 'Montserrat Medium',
        marginBottom: '15px',
      },

      '& p': {
        color: eloBlack,
        fontSize: '13px',
        fontFamily: 'Montserrat Medium',
      },
    },
    noTextContainer: {
      minHeight: '300px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: center,
      alignItems: center,
      fontSize: 13,
      color: '#787878',
      textAlign: 'center',
      wordBreak: 'break-word',
      '& i': {
        fontSize: '100px',
        color: eloWhite,
      },
    },

    noBackgroundImage: {
      backgroundImage: 'none',
      background: 'unset',
    },
  }
})
