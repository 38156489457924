import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.file.filesList,
    label: I18n.t('react.cabinet.files_list'),
    tooltipId: 'block_files_list',
    fields: ['uploadFiles'],
  },
  {
    label: I18n.t('react.cabinet.background'),
    tooltipId: 'block_background_settings',
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.file.buttonStyle,
    label: I18n.t('react.shared.button.title'),
    tooltipId: 'block_button_style',
    fields: [
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      'buttonViewStyle',
      'cornerRadius',
      'buttonAnimation',
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.file.title,
    label: I18n.t('shared.common.title'),
    fields: [
      [
        {
          field: 'font',
          key: 'textFont',
        },
        {
          field: 'fontStyle',
          key: 'textStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.textColor,
        },
        {
          field: 'fontSize',
          key: 'textSize',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.width'),
    fields: [
      {
        field: 'percentage',
        label: I18n.t('react.shared.width'),
        key: 'width',
      },
      {
        field: 'pixels',
        label: I18n.t('react.cabinet.max_width'),
        key: 'maxWidth',
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
