import { GET, POST, PUT, PATCH } from 'utils/requests.utils'

const BASE_URL = '/cabinet/products'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const duplicateItem = (id) => POST(`${BASE_URL}/${id}/duplicate`)

export const addProductUpsell = (id, data) => POST(`${BASE_URL}/${id}/add_product_upsell`, data)

export const removeProductUpsell = (id, data) => POST(`${BASE_URL}/${id}/remove_product_upsell`, data)

export const updateProductFunnel = (id, data) => PUT(`${BASE_URL}/${id}/update_product_funnels`, data)

export const sendProductsToReview = (data) => POST(`${BASE_URL}/sent_to_review`, data)

export const duplicateByToken = (token, data) => POST(`${BASE_URL}/${token}/duplicate_by_token`, data)

export const sendTestAttendeeEmail = (id, data) =>
  POST(`${BASE_URL}/${id}/ticket_attendee_confirmation_email/send`, data)

export const sendTestEmailNew = (id, data) => POST(`${BASE_URL}/${id}/payer_confirmation_email/send`, data)

export const fetchBubble = (id) => GET(`${BASE_URL}/${id}/social_proof_bubble`)

export const createBubble = ({ id, ...restData }) => POST(`${BASE_URL}/${id}/social_proof_bubble`, restData)

export const updateBubble = (id, data) => PATCH(`${BASE_URL}/${id}/social_proof_bubble`, data)
