import { createUseStyles } from 'react-jss'
import { COLORS } from '@elo-kit/constants/general.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlack, eloBlue, eloWhite, eloGrey60, eloGrey70 } = COLORS

export const useThankYouPageStyles = createUseStyles((theme) => ({
  rootContainer: {
    width: '100%',
    backgroundColor: theme.backgroundColor || eloWhite,
  },
  container: {
    width: `${theme.width || 100}%`,
    maxWidth: `${theme.maxWidth || 1200}px`,
    padding: '45px 45px 30px',
    position: 'relative',
    margin: '0 auto',
    '@media (max-width: 768px)': {
      padding: '25px 20px 20px',
    },
  },
  successHeader: {
    fontSize: 30,
    color: theme.headerColor || eloBlack,
    fontFamily: cleanupFonts(theme.headerFont) || 'Montserrat Medium',
    ...getFontStyles(theme.headerStyle),
    marginBottom: 30,
    lineHeight: 1,
    '@media (max-width: 768px)': {
      marginBottom: 25,
    },
  },
  lightSeparator: {
    height: 2,
    color: '#00000014',
    width: '100%',
    margin: '27px 0',
  },
  bigSeparator: {
    height: 2,
    color: '#00000014',
    width: '100%',
    margin: '47px 0 50px',
  },
  section: {
    color: theme.additionalTextColor || eloGrey60,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  sectionItem: {
    width: '47%',
    fontSize: 14,
    color: theme.additionalTextColor || eloGrey60,
    ...getFontStyles(theme.additionalTextStyle),
    display: 'flex',
    alignItems: 'center',
    minHeight: 30,
    marginBottom: 5,
    flexWrap: 'wrap',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
  lastSection: {
    marginBottom: 50,
  },
  mobileShow: {
    display: 'none',
    '@media (max-width: 576px)': {
      display: 'block',
    },
  },
  mobileHide: {
    display: 'none',
    '@media (min-width: 577px)': {
      display: 'block',
    },
  },
  link: {
    color: theme.secondaryButtonColor || eloBlue,
    fontFamily: cleanupFonts(theme.secondaryButtonFont) || 'Montserrat Reg',
    ...getFontStyles(theme.secondaryButtonStyle),
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    outline: 'none',
    '&:hover': {
      opacity: 0.8,
      color: theme.secondaryButtonColor || eloBlue,
    },
    '&:active': {
      opacity: 0.6,
      color: theme.secondaryButtonColor || eloBlue,
    },
  },
  additionalButton: {
    minWidth: 155,
    height: 30,
    padding: '0 21px',
    borderRadius: `${theme.secondaryCornerRadius || 4}px`,
    border: 'solid 1px',
    color: theme.secondaryButtonColor || eloBlue,
    fontFamily: cleanupFonts(theme.secondaryButtonFont) || 'Montserrat Reg',
    textDecorationLine: 'underline',
    textDecorationColor: theme.backgroundColor || eloWhite,
    ...getFontStyles(theme.secondaryButtonStyle),
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: 'transparent',
    borderColor: theme.secondaryButtonColor || eloBlue,
    outline: 'none',
    marginRight: 30,
    marginBottom: 20,
    whiteSpace: 'nowrap',
    '&:hover': {
      opacity: 0.8,
      color: theme.secondaryButtonColor || eloBlue,
      borderColor: theme.secondaryButtonColor || eloBlue,
    },
    '&:active': {
      opacity: 0.6,
      color: theme.secondaryButtonColor || eloBlue,
      borderColor: theme.secondaryButtonColor || eloBlue,
    },
    '&:focus': {
      outline: 'none',
      borderColor: theme.secondaryButtonColor || eloBlue,
    },
    '& span': {
      alignSelf: 'center',
    },
    '& i': {
      alignSelf: 'center',
      marginRight: 5,
    },
    '@media (max-width: 576px)': {
      width: '100%',
      marginRight: 0,
    },
  },
  invoiceButton: {
    marginBottom: 5,
  },
  sectionItemRight: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    '@media (max-width: 576px)': {
      textAlign: 'left',
      justifyContent: 'flex-start',
      order: -1,
    },
  },
  sectionTitle: {
    fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
    textDecorationLine: 'underline',
    textDecorationColor: theme.backgroundColor || eloWhite,
    ...getFontStyles(theme.labelsStyle),
    color: theme.labelsColor || eloGrey60,
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: 17,
    width: '100%',
  },
  boldText: {
    fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
    textDecorationLine: 'underline',
    textDecorationColor: theme.backgroundColor || eloWhite,
    ...getFontStyles(theme.labelsStyle),
    color: theme.labelsColor || eloGrey60,
    marginRight: 5,
  },
  productAccessEmail: {
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    ...getFontStyles(theme.additionalTextStyle),
    color: theme.additionalTextColor || eloGrey60,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    '& i': {
      fontSize: 16,
      marginRight: 5,
    },
  },
  price: {
    color: theme.priceColor || '#545454',
    fontFamily: cleanupFonts(theme.priceFont) || 'Montserrat Medium',
    textDecorationLine: 'underline',
    textDecorationColor: theme.backgroundColor || eloWhite,
    ...getFontStyles(theme.priceStyle),
  },
  mainPrice: {
    color: theme.priceColor || '#545454',
    fontFamily: cleanupFonts(theme.priceFont) || 'Montserrat Medium',
    textDecorationLine: 'underline',
    textDecorationColor: theme.backgroundColor || eloWhite,
    ...getFontStyles(theme.priceStyle),
    fontSize: 30,
    lineHeight: 1,
  },
  productContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 14,
    fontFamily: 'Montserrat Reg',
    color: eloGrey70,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 20,
      '& > div:not(:last-child)': {
        marginBottom: 20,
      },
    },
  },
  productWrapper: {
    marginBottom: 30,
    '@media (max-width: 768px)': {
      marginBottom: 20,
    },
  },
  productCover: {
    width: 90,
    height: 90,
    minWidth: 90,
    minHeight: 90,
    marginRight: 30,
    borderRadius: 6,
    '@media (max-width: 768px)': {
      marginRight: 20,
    },
  },
  noProductCover: {
    border: '1px dashed #00000026',
    backgroundColor: '#efedef',
    color: '#e0e0e0',
    fontSize: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productCoverSmall: {
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    marginRight: 30,
    borderRadius: 6,
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    '@media (max-width: 768px)': {
      marginRight: 20,
    },
  },
  noProductCoverSmall: {
    border: '1px dashed #00000026',
    backgroundColor: '#efedef',
    color: '#e0e0e0',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productName: {
    color: theme.productNameColor || eloBlack,
    fontFamily: cleanupFonts(theme.productNameFont) || 'Montserrat Medium',
    ...getFontStyles(theme.productNameStyle),
    fontSize: 16,
    marginRight: 20,
    wordBreak: 'break-word',
    lineHeight: 1,
    maxWidth: 400,
  },
  innerProductsContainer: {
    border: 'solid 1px #00000014',
    padding: 30,
    borderRadius: 4,
    marginTop: 30,
    marginBottom: 30,
    '& $lightSeparator': {
      margin: '20px 0',
    },
    '@media (max-width: 768px)': {
      padding: 20,
    },
  },
  productDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%',
    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  },
  productDetails: {
    display: 'flex',
    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  productInfo: {
    lineHeight: 1.14,
    marginRight: 20,
    marginTop: 4,
    wordBreak: 'break-word',
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    ...getFontStyles(theme.additionalTextStyle),
    color: theme.additionalTextColor || eloGrey60,
  },
  fileDetails: {
    whiteSpace: 'nowrap',
    marginTop: 4,
    marginRight: 20,
    color: theme.additionalTextColor || eloGrey60,
  },
  productDownloadButton: {
    color: theme.buttonTextColor || eloWhite,
    fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Reg',
    ...getFontStyles(theme.buttonStyle),
    fontSize: 14,
    borderRadius: `${theme.radius || 4}px`,
    background: theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme.buttonColor || eloBlue,
    border: `2px solid ${theme.buttonColor || eloBlue}`,
    padding: '2px 24px 0',
    minHeight: 40,
    minWidth: 155,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      fontSize: 16,
      marginRight: 4,
      marginTop: -2,
    },
    '&:hover': {
      textDecoration: 'none',
      color: theme.buttonTextColor || eloWhite,
    },
    '&:active': {
      color: theme.buttonTextColor || eloWhite,
    },
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
  productDownloadButtonDisabled: {
    borderColor: '#f0f1f6',
    backgroundColor: '#f0f1f6',
    color: '#8f9295',

    '&:hover': {
      color: '#8f9295',
    },
  },
  productDownloadLink: {
    color: theme.secondaryButtonColor || eloBlue,
    fontFamily: cleanupFonts(theme.secondaryButtonFont) || 'Montserrat Reg',
    ...getFontStyles(theme.secondaryButtonStyle),
    fontSize: 14,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '& i': {
      fontSize: 16,
      marginRight: 7,
    },
    '&:hover': {
      opacity: 0.8,
      textDecoration: 'none',
      color: theme.secondaryButtonColor || eloBlue,
    },
    '&:active': {
      opacity: 0.6,
      color: theme.secondaryButtonColor || eloBlue,
    },
    '@media (max-width: 768px)': {
      marginLeft: 80,
    },
  },
  giftProduct: {
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    ...getFontStyles(theme.additionalTextStyle),
    color: theme.additionalTextColor || eloGrey60,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    '& i': {
      fontSize: 16,
      marginRight: 5,
      marginTop: -3,
    },
  },
  orderInfo: {
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    ...getFontStyles(theme.additionalTextStyle),
    color: theme.additionalTextColor || eloGrey60,
    fontSize: 14,
    lineHeight: 1.14,
    marginBottom: 13,
  },
  productNoAccess: {
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    ...getFontStyles(theme.additionalTextStyle),
    color: theme.additionalTextColor || eloGrey60,
    fontSize: 14,
    lineHeight: 1.14,
    marginBottom: 13,
    textAlign: 'right',
    maxWidth: 350,
    '& h1': {
      fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
      textDecorationLine: 'underline',
      textDecorationColor: theme.backgroundColor || eloWhite,
      ...getFontStyles(theme.labelsStyle),
      color: theme.labelsColor || '#4a4a4a',
      fontSize: 16,
      lineHeight: 0.88,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& i': {
        fontSize: 22,
        marginRight: 9,
        marginTop: -3,
      },
    },
    '& p': {
      marginBottom: 0,
    },
    '@media (max-width: 768px)': {
      width: '100%',
      textAlign: 'left',
      maxWidth: 'none',
      marginBottom: 0,
      '& h1': {
        justifyContent: 'flex-start',
      },
    },
  },
  desktopEmailNotific: {
    width: '100%',
    marginTop: 20,

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  mobileEmailNotific: {
    display: 'none',

    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  notificationContainer: {
    textAlign: 'center',
    maxWidth: 700,
    margin: '30px auto 0',
    '& img': {
      height: 30,
      marginBottom: 17,
    },
    '& h1': {
      fontFamily: cleanupFonts(theme.headerFont) || 'Montserrat Medium',
      ...getFontStyles(theme.headerStyle),
      color: theme.headerColor || '#4a4a4a',
      fontSize: 20,
      lineHeight: 1,
    },
    '& > i': {
      fontSize: 36,
      color: theme.additionalTextColor || '#cdcdcd',
      marginBottom: 17,
    },
    '& p': {
      fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
      ...getFontStyles(theme.labelsStyle),
      color: theme.labelsColor || eloGrey60,
      fontSize: 14,
      lineHeight: 1,
      marginBottom: 30,
    },
    '& > a': {
      fontFamily: 'Montserrat Reg',
      color: theme.secondaryButtonColor || '#2b90df',
      fontSize: 14,
      lineHeight: 1,
      marginBottom: 30,
    },
    '& button': {
      color: theme.buttonTextColor || '#FFFFFF',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
      ...getFontStyles(theme.buttonStyle),
      fontSize: 14,
      borderRadius: `${theme.radius || 4}px`,
      background: theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme.buttonColor || eloBlue,
      border: `2px solid ${theme.buttonColor || eloBlue}`,
      padding: '2px 24px 0',
      minHeight: 40,
      minWidth: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '30px auto 30px',
      '& i': {
        fontSize: 16,
        marginRight: 5,
      },
      '&:hover': {
        opacity: 0.8,
        textDecoration: 'none',
        color: theme.buttonTextColor || '#FFFFFF',
        border: 'none',
      },
      '&:active': {
        opacity: 0.6,
        color: theme.buttonTextColor || '#FFFFFF',
        border: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
      '@media (max-width: 576px)': {
        width: '100%',
      },
    },
  },
  messageBox: {
    borderRadius: 4,
    border: '1px solid #00000014',
    backgroundColor: '#00000008',
    padding: 25,
    fontFamily: 'Montserrat Reg',
    color: eloGrey70,
    fontSize: 14,
    lineHeight: 1.14,
    textAlign: 'center',
    maxWidth: 700,
    margin: '0 auto 30px',
    '@media (max-width: 576px)': {
      backgroundColor: eloWhite,
      border: 'none',
      borderTop: '1px solid #00000014',
      padding: '25px 0 0',
    },
  },
  messageBoxTitle: {
    fontSize: 14,
    color: theme.productNameColor || eloBlack,
    fontFamily: cleanupFonts(theme.productNameFont) || 'Montserrat Medium',
    ...getFontStyles(theme.productNameStyle),
    marginBottom: 20,
    textTransform: 'uppercase',
    textAlign: 'left',
    '@media (max-width: 576px)': {
      marginBottom: 10,
    },
  },
  messageBoxSection: {
    fontSize: 14,
    color: eloGrey60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 30,
    marginBottom: 5,
    flexWrap: 'wrap',
    fontFamily: 'Montserrat Reg',
    '& div': {
      width: '47%',
      '&:first-child:not(:only-child)': {
        marginRight: 5,
        textAlign: 'left',
        fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
        ...getFontStyles(theme.labelsStyle),
        color: theme.labelsColor || '#4a4a4a',
      },
      '&:last-child': {
        textAlign: 'right',
        justifyContent: 'flex-end',
        color: theme.productNameColor || eloBlack,
        fontFamily: cleanupFonts(theme.productNameFont) || 'Montserrat Medium',
        ...getFontStyles(theme.productNameStyle),
      },
      '&:only-child': {
        width: '100%',
        color: theme.productNameColor || eloBlack,
        fontFamily: cleanupFonts(theme.productNameFont) || 'Montserrat Medium',
        ...getFontStyles(theme.productNameStyle),
      },
    },
    '@media (max-width: 576px)': {
      justifyContent: 'flex-start',
      minHeight: 25,
      '& div': {
        width: 'unset',
        textAlign: 'left !important',
      },
    },
  },
  messageBoxFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: eloGrey60,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
    '& img': {
      maxHeight: 25,
      maxWidth: 100,
      marginLeft: 20,
      '&:first-child': {
        marginRight: 8,
        marginLeft: 0,
      },
    },
  },
  paymentInProgressContainer: {
    width: '100%',
    marginBottom: 30,
    border: '1px solid #00000014',
    borderRadius: 4,
    '@media (max-width: 576px)': {
      border: 'none',
      borderBottom: '1px solid #00000014',
    },
  },
  paymentInProgressCentered: {
    textAlign: 'center',
    maxWidth: 700,
    margin: '0 auto',
    padding: 30,
    lineHeight: 1.14,
    color: eloGrey70,
    fontSize: 14,
    fontFamily: 'Montserrat Reg',
    '@media (max-width: 576px)': {
      padding: '0 0 30px',
      textAlign: 'left',
    },
  },
  paymentInProgressDescription: {
    marginBottom: 30,
    color: theme.productNameColor || eloBlack,
    fontFamily: cleanupFonts(theme.productNameFont) || 'Montserrat Medium',
    ...getFontStyles(theme.productNameStyle),
    '& a': {
      color: theme.secondaryButtonColor || eloBlue,
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      outline: 'none',
      '&:hover': {
        opacity: 0.8,
        color: theme.secondaryButtonColor || eloBlue,
      },
      '&:active': {
        opacity: 0.6,
        color: theme.secondaryButtonColor || eloBlue,
      },
    },
    '@media (max-width: 576px)': {
      marginBottom: 20,
    },
  },
  paymentInProgressLogo: {
    maxHeight: 30,
    maxWidth: 100,
    width: '100%',
    height: '100%',
    marginBottom: 30,
  },
  paymentInProgressTitle: {
    color: theme.labelsColor || eloGrey60,
    fontSize: 14,
    fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
    ...getFontStyles(theme.labelsStyle),
  },
  paymentInProgressPrice: {
    color: theme.priceColor || '#545454',
    fontFamily: cleanupFonts(theme.priceFont) || 'Montserrat Medium',
    ...getFontStyles(theme.priceStyle),
    fontSize: 30,
    marginBottom: 30,
    '@media (max-width: 768px)': {
      marginBottom: 20,
    },
  },
  footer: {
    color: theme.additionalTextColor || eloGrey60,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontFamily: cleanupFonts(theme.additionalTextFont) || 'Montserrat Reg',
    ...getFontStyles(theme.additionalTextStyle),
    alignItems: 'center',
    '& $link': {
      marginLeft: 5,
    },
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  footerButtons: {
    display: 'flex',
    fontFamily: 'Montserrat Reg',
    '@media (max-width: 576px)': {
      width: '100%',
      '& button': {
        marginRight: 0,
      },
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  footerInfo: {
    textAlign: 'right',
    lineHeight: 1,
    marginBottom: 20,
    '@media (max-width: 576px)': {
      textAlign: 'center',
    },
  },
  delayedAccess: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 50px',
    color: eloGrey60,
    fontSize: 14,
    fontStyle: 'normal',
    fontFamily: 'Montserrat Medium',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  upgradeTicketsSection: {
    display: 'flex',
    color: eloBlack,
    justifyContent: 'space-between',
  },
  upgradeTicketsText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  upgradeTicketsLabel: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '147%',
  },
  upgradeTicketsDescription: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '144%',
  },
  ...buttonAnimations(theme),
}))
