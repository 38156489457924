import React, { Component } from 'react'
import { Picture } from '@elo-kit/components/page-builder/blocks/picture/Picture'

class PicturePreview extends Component {
  shouldComponentUpdate() {
    const { isDragging } = this.props

    if (isDragging) {
      return false
    }

    return true
  }

  render() {
    return <Picture {...this.props} />
  }
}

PicturePreview.defaultProps = {
  previewMode: false,
}

export default PicturePreview
