const gapStyles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxWidth: '100%',
    position: 'relative',
    minHeight: `${theme.minHeight}px`,
  },
})

export default gapStyles
