import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/addon_themes'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const duplicateItem = (id) => POST(`${BASE_URL}/${id}/duplicate`)

export const duplicateByToken = (token, data) => POST(`${BASE_URL}/${token}/duplicate_by_token`, data)

export const fetchItemForPreview = (token, username) => GET(`${BASE_URL}/${token}`, { username })
