import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    tooltipId: 'block_background_settings',
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    dataMenuHighlight: true,
    label: I18n.t('react.shared.text'),
    fields: [
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'textAlignment',
      },
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
      {
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.banner.mainText,
        label: I18n.t('react.cabinet.main_text'),
        tooltipId: 'main_text',
        tooltipTitle: true,
        fields: [
          [
            {
              field: 'font',
              key: 'mainFont',
            },
            {
              field: 'fontStyle',
              key: 'mainStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.mainColor,
            },
            {
              field: 'fontSize',
              key: 'mainSize',
            },
          ],
          {
            field: 'addText',
            key: 'titleText',
          },
        ],
      },
      {
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.banner.additionalText,
        label: I18n.t('react.cabinet.additional_text'),
        tooltipId: 'additional_text',
        tooltipTitle: true,
        fields: [
          [
            {
              field: 'font',
              key: 'additionalFont',
            },
            {
              field: 'fontStyle',
              key: 'additionalStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.additionalColor,
            },
            {
              field: 'fontSize',
              key: 'additionalSize',
            },
          ],
          {
            field: 'addText',
            key: 'additionalText',
          },
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.banner.customCta,
    label: I18n.t('react.cabinet.CTA_button'),
    toggle: 'showButton',
    tooltipId: 'block_CTA_button',
    fields: [
      {
        field: 'link',
        key: 'buttonLink',
      },
      {
        field: 'text',
        key: 'buttonText',
      },
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      'buttonViewStyle',
      'cornerRadius',
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      'buttonAnimation',
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
