import React from 'react'
import classNames from 'classnames'

import { YES } from '@elo-kit/constants/general.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { MARKETING_TOOLS_PROGRESS_BAR_SECTION_WIDTH } from 'constants/contentPageShared.constants'
import { createRange } from 'utils/helpers.utils'

import { EMPTY_TEXT_EDITOR } from 'constants/courses.constants'
import { BAR_STYLE_TYPES } from 'shared/components/page-builder/constants/block.constants'
import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'

import CircleProgress from './CircleProgress'

const ProgressBar = (props) => {
  const { block: { content: { progressBarDescriptionText, progressBarStyle } = {} } = {}, classes, previewMode } = props

  const renderProgressBarView = () => {
    switch (progressBarStyle) {
      case BAR_STYLE_TYPES.bordered:
      case BAR_STYLE_TYPES.loading: {
        return <ProgressBar.Default {...props} />
      }
      case BAR_STYLE_TYPES.sections: {
        return <ProgressBar.Sections {...props} />
      }

      case BAR_STYLE_TYPES.circle:
      case BAR_STYLE_TYPES.filledCircle:
      case BAR_STYLE_TYPES.halfCircle:
      case BAR_STYLE_TYPES.pieCircle: {
        return <CircleProgress {...props} />
      }

      default: {
        return null
      }
    }
  }

  return (
    <div className={classes.progressBarContainer}>
      {progressBarDescriptionText && progressBarDescriptionText !== EMPTY_TEXT_EDITOR && (
        <EditTextArea
          value={progressBarDescriptionText}
          dataKey='progressBarDescriptionText'
          previewMode={previewMode}
          highlightDataAttributes={{
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.progressBarDescription,
            'data-highlighter-selector': '',
          }}
        >
          <div
            className={classNames(classes.progressBarDescription)}
            dangerouslySetInnerHTML={{
              __html: progressBarDescriptionText,
            }}
          />
        </EditTextArea>
      )}
      {renderProgressBarView()}
    </div>
  )
}

ProgressBar.Default = (props) => {
  const { block: { content: { radius, showNumbers, progressBarValue } = {} } = {}, classes, previewMode } = props
  const loaded = progressBarValue === 100 || progressBarValue === 0
  const showNumbersSection = showNumbers === YES && progressBarValue > 15

  return (
    <div
      className={classNames(classes.progressBar)}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.progressBarStyles,
        'data-highlighter-selector': '',
      })}
    >
      <div
        className={classes.filled}
        style={{
          width: `${progressBarValue}%`,
          borderTopRightRadius: loaded ? Number(radius) : 0,
          borderBottomRightRadius: loaded ? Number(radius) : 0,
        }}
      >
        {showNumbersSection && <span className={classes.remainingPercentage}>{`${progressBarValue}%`}</span>}
      </div>
    </div>
  )
}

ProgressBar.Sections = (props) => {
  const { block: { content: { radius, progressBarWidth, progressBarValue } = {} } = {}, classes, previewMode } = props
  const loaded = progressBarValue === 100 || progressBarValue === 0
  const numberOfSections = progressBarWidth / MARKETING_TOOLS_PROGRESS_BAR_SECTION_WIDTH
  const sections = createRange({
    start: 0,
    end: Math.floor((numberOfSections * progressBarValue) / 100) - 1,
  })

  return (
    <div
      className={classNames(classes.progressBar)}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.progressBarStyles,
        'data-highlighter-selector': '',
      })}
    >
      {sections.map((_item, index) => {
        const firstItemLeftRadius = index === 0 ? Number(radius) : 0
        const lastItemRightRadius = index === sections.length - 1 && loaded ? Number(radius) : 0
        return (
          <div
            key={`section-${_item}`}
            className={classes.filled}
            style={{
              width: 19,
              borderTopLeftRadius: firstItemLeftRadius,
              borderBottomLeftRadius: firstItemLeftRadius,
              borderTopRightRadius: lastItemRightRadius,
              borderBottomRightRadius: lastItemRightRadius,
              marginRight: index === sections.length - 1 ? 0 : 3,
            }}
          />
        )
      })}
    </div>
  )
}

export default ProgressBar
