import React, { Component } from 'react'
import { action, observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Testimonials } from '@elo-kit/components/page-builder/blocks/testimonials/Testimonials'

@observer
class TestimonialsBlock extends Component {
  @observable showMore = false
  @observable showMoreItem = null
  @observable shouldUpdateSliderHeight = null

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidUpdate(prevProps) {
    const {
      block: { content = {} },
    } = this.props
    const {
      block: { content: prevContent = {} },
    } = prevProps
    const { testimonials = [] } = content
    const { testimonials: prevTestimonials = [] } = prevContent
    if (testimonials.length !== prevTestimonials.length) {
      this.setShouldUpdateSliderHeight(true)
    }
  }

  @action setShowMore = (value, item) => {
    if (this.showMore !== value) {
      this.shouldUpdateSliderHeight = true
    }
    this.showMore = value
    this.showMoreItem = !value ? null : item
  }

  @action setShouldUpdateSliderHeight = (value) => (this.shouldUpdateSliderHeight = value)

  render() {
    return (
      <Testimonials
        {...this.props}
        showMore={this.showMore}
        showMoreItem={this.showMoreItem}
        shouldUpdateSliderHeight={this.shouldUpdateSliderHeight}
        setShowMore={this.setShowMore}
        toggleTriggerSizeUpdate={() => this.setShouldUpdateSliderHeight(false)}
      />
    )
  }
}

export default TestimonialsBlock
