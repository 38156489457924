import { BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER, CATEGORIES_VIEW_TYPE } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    fields: [BLOCKS_WITH_COLOR_PICKER.backgroundColor],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productsTitles,
    label: I18n.t('react.cabinet.products_titles'),
    fields: [
      [
        {
          field: 'font',
          key: 'productFont',
        },
        {
          field: 'fontStyle',
          key: 'productStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.productColor,
        },
        {
          field: 'fontSize',
          key: 'productSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productsView,
    label: I18n.t('react.shared.products_view'),
    tooltipId: 'block_products_view',
    fields: ['view', 'itemsPerPage'],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productBackground,
    label: I18n.t('react.shared.product_background'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.grid,
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.productBackground,
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockPrice,
    label: I18n.t('react.cabinet.common.price'),
    tooltipId: 'block_price',
    show: {
      key: 'view',
      value: BLOCK_VIEW.grid,
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.priceBackground,
        label: I18n.t('react.cabinet.background'),
      },
      [
        {
          field: 'font',
          key: 'priceFont',
        },
        {
          field: 'fontStyle',
          key: 'priceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.priceColor,
        },
        {
          field: 'fontSize',
          key: 'priceSize',
        },
      ],
      [
        {
          field: 'cornerRadius',
          key: 'priceRadius',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockPrice,
    label: I18n.t('react.cabinet.common.price'),
    tooltipId: 'block_price',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'priceFont',
        },
        {
          field: 'fontStyle',
          key: 'priceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.priceColor,
        },
        {
          field: 'fontSize',
          key: 'priceSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockCurrency,
    label: I18n.t('react.common.currency'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    tooltipId: 'block_currency',
    fields: [
      [
        {
          field: 'font',
          key: 'currencyFont',
        },
        {
          field: 'fontStyle',
          key: 'currencyStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.currencyColor,
        },
        {
          field: 'fontSize',
          key: 'currencySize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockOldPrice,
    label: I18n.t('react.cabinet.pricing_plan.form.lb.old_price'),
    tooltipId: 'block_old_price',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'oldPriceFont',
        },
        {
          field: 'fontStyle',
          key: 'oldPriceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.oldPriceColor,
        },
        {
          field: 'fontSize',
          key: 'oldPriceSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockProductDescription,
    label: I18n.t('react.cabinet.description'),
    tooltipId: 'block_product_description',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'descriptionFont',
        },
        {
          field: 'fontStyle',
          key: 'descriptionStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.descriptionColor,
        },
        {
          field: 'fontSize',
          key: 'descriptionSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: 'buy_now_button',
    label: I18n.t('react.cabinet.buy_now_button'),
    toggle: 'showButton',
    fields: [
      {
        field: 'text',
        key: 'buttonText',
      },
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      'buttonViewStyle',
      'cornerRadius',
      'buttonAnimation',
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.categories,
    label: I18n.t('react.shared.categories'),
    toggle: 'showCategories',
    tooltipId: 'show_categories',
    fields: [
      {
        label: I18n.t('react.shared.show_all_products'),
        field: 'checkbox',
        key: 'showAllProducts',
      },
      {
        label: I18n.t('react.cabinet.categories_view'),
        show: {
          key: 'showCategories',
          value: true,
        },
        fields: [
          {
            field: 'categoriesView',
            key: BLOCKS_WITH_COLOR_PICKER.categoryActiveColor,
            label: I18n.t('react.cabinet.active_colors'),
          },
        ],
      },
      {
        label: I18n.t('react.cabinet.tabs'),
        show: {
          key: 'categoriesView',
          value: CATEGORIES_VIEW_TYPE.tabs,
        },
        fields: [
          {
            field: 'color',
            key: BLOCKS_WITH_COLOR_PICKER.categoryActiveColor,
            label: I18n.t('react.cabinet.active_colors'),
          },
          [
            {
              field: 'font',
              key: 'categoryFont',
            },
            {
              field: 'fontStyle',
              key: 'categoryStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.categoryColor,
            },
            {
              field: 'fontSize',
              key: 'categorySize',
            },
          ],
        ],
      },
      {
        label: I18n.t('react.cabinet.categories_dropdown_design'),
        show: {
          key: 'categoriesView',
          value: CATEGORIES_VIEW_TYPE.dropdown,
        },
        fields: [
          {
            field: 'color',
            key: BLOCKS_WITH_COLOR_PICKER.categoryBackground,
            label: I18n.t('react.cabinet.background'),
          },
          {
            field: 'color',
            key: BLOCKS_WITH_COLOR_PICKER.categoryActiveColor,
            label: I18n.t('react.cabinet.active_colors'),
          },
          [
            {
              field: 'font',
              key: 'categoryFont',
            },
            {
              field: 'fontStyle',
              key: 'categoryStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.categoryColor,
            },
            {
              field: 'fontSize',
              key: 'categorySize',
            },
          ],
        ],
      },
      {
        label: I18n.t('react.cabinet.description'),
        show: {
          key: 'categoriesView',
          value: CATEGORIES_VIEW_TYPE.tabs,
        },
        fields: [
          [
            {
              field: 'font',
              key: 'categoryDescriptionFont',
            },
            {
              field: 'fontStyle',
              key: 'categoryDescriptionStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.categoryDescriptionColor,
            },
            {
              field: 'fontSize',
              key: 'categoryDescriptionSize',
            },
          ],
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.categories,
    label: I18n.t('react.cabinet.selected_categories'),
    toggle: 'showSelectedCategories',
    show: {
      key: 'showCategories',
      value: true,
    },
    tooltipId: 'block_selected_categories',
    fields: ['selectCategories'],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.search,
    label: I18n.t('shared.common.search'),
    toggle: 'showSearch',
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.searchBackground,
        label: I18n.t('react.cabinet.background'),
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.searchActiveColor,
        label: I18n.t('react.cabinet.active_colors'),
      },
      [
        {
          field: 'font',
          key: 'searchFont',
        },
        {
          field: 'fontStyle',
          key: 'searchStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.searchColor,
        },
        {
          field: 'fontSize',
          key: 'searchSize',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.sorting_order'),
    fields: [
      {
        field: 'sortKey',
        key: 'sortKey',
      },
      {
        field: 'sortDir',
        key: 'sortDir',
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
