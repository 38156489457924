import { BLOCK_VIEW, BUTTON_TYPE } from 'constants/contentPageShared.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { THEME_TYPE } from 'constants/general.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    tooltipId: 'block_background_settings',
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    label: I18n.t('react.shared.products_view'),
    fields: ['sliderView', 'view'],
    tooltipId: 'block_products_view',
  },
  {
    label: I18n.t('react.cabinet.button_functionality'),
    tooltipId: 'button_functionality',
    fields: ['twoButtonTypes'],
  },
  {
    label: I18n.t('react.cabinet.payment_options'),
    tooltipId: 'payment_options',
    show: {
      key: 'buttonType',
      value: BUTTON_TYPE.buy,
      theme: THEME_TYPE.membership,
    },
    fields: ['paymentOptions'],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productButton,
    label: I18n.t('react.common.product_button'),
    tooltipId: 'block_product_button',
    fields: [
      {
        field: 'text',
        key: 'buttonText',
      },
      {
        field: 'text',
        key: 'freeButtonText',
        label: I18n.t('react.cabinet.free_product_text'),
      },
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      'buttonViewStyle',
      'cornerRadius',
      'buttonAnimation',
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productBackground,
    label: I18n.t('react.shared.product_background'),
    show: {
      key: 'view',
      value: [BLOCK_VIEW.slider, BLOCK_VIEW.grid],
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.productBackground,
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.blockSlider,
    label: I18n.t('react.common.slider'),
    tooltipId: 'block_slider',
    show: {
      key: 'view',
      value: BLOCK_VIEW.slider,
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.arrowsColor,
        label: I18n.t('react.cabinet.content_pages.form.arrows_color'),
      },
      'itemsPerSlide',
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productsTitles,
    label: I18n.t('react.common.products_titles'),
    fields: [
      [
        {
          field: 'font',
          key: 'textFont',
        },
        {
          field: 'fontStyle',
          key: 'textStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.textColor,
        },
        {
          field: 'fontSize',
          key: 'textSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.priceBlock,
    label: I18n.t('react.cabinet.common.price'),
    tooltipId: 'block_price',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'priceFont',
        },
        {
          field: 'fontStyle',
          key: 'priceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.priceColor,
        },
        {
          field: 'fontSize',
          key: 'priceSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.priceBlock,
    label: I18n.t('react.cabinet.common.price'),
    tooltipId: 'block_price',
    show: {
      key: 'view',
      value: [BLOCK_VIEW.slider, BLOCK_VIEW.grid],
    },
    toggle: 'showPrice',
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.priceBackground,
        label: I18n.t('react.cabinet.background'),
      },
      [
        {
          field: 'font',
          key: 'priceFont',
        },
        {
          field: 'fontStyle',
          key: 'priceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.priceColor,
        },
        {
          field: 'fontSize',
          key: 'priceSize',
        },
      ],
      [
        {
          field: 'cornerRadius',
          key: 'priceRadius',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.currency,
    label: I18n.t('react.common.currency'),
    tooltipId: 'block_currency',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'currencyFont',
        },
        {
          field: 'fontStyle',
          key: 'currencyStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.currencyColor,
        },
        {
          field: 'fontSize',
          key: 'currencySize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.oldPrice,
    label: I18n.t('react.cabinet.pricing_plan.form.lb.old_price'),
    tooltipId: 'block_old_price',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'oldPriceFont',
        },
        {
          field: 'fontStyle',
          key: 'oldPriceStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.oldPriceColor,
        },
        {
          field: 'fontSize',
          key: 'oldPriceSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.description,
    label: I18n.t('react.cabinet.common.description'),
    tooltipId: 'block_product_description',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'descriptionFont',
        },
        {
          field: 'fontStyle',
          key: 'descriptionStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.descriptionColor,
        },
        {
          field: 'fontSize',
          key: 'descriptionSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.search,
    label: I18n.t('shared.common.search'),
    toggle: 'showSearch',
    show: {
      key: 'view',
      value: BLOCK_VIEW.list,
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.searchBackground,
        label: I18n.t('react.cabinet.background'),
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.searchActiveColor,
        label: I18n.t('react.cabinet.active_colors'),
      },
      [
        {
          field: 'font',
          key: 'searchFont',
        },
        {
          field: 'fontStyle',
          key: 'searchStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.searchColor,
        },
        {
          field: 'fontSize',
          key: 'searchSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.search,
    label: I18n.t('shared.common.search'),
    toggle: 'showSearch',
    show: {
      key: 'view',
      value: BLOCK_VIEW.grid,
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.searchBackground,
        label: I18n.t('react.cabinet.background'),
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.searchActiveColor,
        label: I18n.t('react.cabinet.active_colors'),
      },
      [
        {
          field: 'font',
          key: 'searchFont',
        },
        {
          field: 'fontStyle',
          key: 'searchStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.searchColor,
        },
        {
          field: 'fontSize',
          key: 'searchSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.selectedProducts,
    label: I18n.t('react.cabinet.product.form.selected_products'),
    tooltipId: 'block_selected_products',
    fields: ['selectAndSortProducts'],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
