import React from 'react'

import './story-table-row.scss'

interface Props {
  name: string
  value: string
  description: string
  children: React.ReactNode
}

export const StoryTableRow: React.FC<Props> = ({ name, value, description, children }) => (
  <>
    <div className='story-cell'>{children}</div>
    <div className='story-cell'>
      <span className='token'>{name.toUpperCase()}</span>
      <span className='variable'>--{name}</span>
      <span className='value'>{value}</span>
    </div>
    <div className='story-cell'>
      <div className='description'>{description}</div>
    </div>
  </>
)
