import React from 'react'
import classNames from 'classnames'

import { EloUserIcon, EloPreviewIcon } from '@elo-ui/components/icons/regular'

import './elo-avatar.scss'

type Shape = 'circle' | 'square' | 'default'

type Size = 'small' | 'medium' | 'large' | 'extra-large' | 'extra-extra-large' | 'fullwidth'

interface Props {
  shape?: Shape
  size?: Size
  src?: string
  alt?: string
  className?: string
  icon?: React.ReactNode
  username?: string
}

export const EloAvatar: React.FC<Props> = ({
  shape = 'circle',
  size = 'medium',
  src,
  alt = '',
  className,
  username = '',
  icon,
}) => {
  const isPlaceholder = !!src
  const classes = classNames('elo-avatar', className, `elo-avatar__${size}`, `elo-avatar__${shape}`)

  const avatarPlaceholder =
    shape === 'circle' ? (
      <>
        {username && username.substring(0, 1).toUpperCase()}
        {!username && <EloUserIcon size={size === 'extra-large' || size === 'extra-extra-large' ? 32 : 16} />}
      </>
    ) : (
      <EloPreviewIcon size={size === 'extra-large' || size === 'extra-extra-large' ? 32 : 16} />
    )

  return (
    <div className={classes}>
      {isPlaceholder ? (
        <img src={src} alt={alt} />
      ) : (
        <span className='elo-avatar__placeholder' title={alt}>
          {icon || avatarPlaceholder}
        </span>
      )}
    </div>
  )
}
