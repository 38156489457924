import { action, makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/orderBumpsThemes.api'

export class AddonThemesStore extends SharedStore {
  storeName = 'AddonThemesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @override
  async duplicateItem(itemId) {
    const resp = await this.childApi.duplicateItem(itemId)

    if (resp?.success) {
      this.fetchList()
    }
  }

  @action duplicateByToken = (token, data) => api.duplicateByToken(token, data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new AddonThemesStore()
