import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { AUTOCOMPLETE_OFF } from 'constants/forms.constants'

import './content-block-textarea-field.scss'

const ContentBlockTextareaField = (props) => {
  const {
    autocomplete,
    className,
    errorMessage,
    field,
    isInvalid,
    label,
    name,
    onChange,
    placeholder,
    required,
    value,
  } = props

  const textFieldChange = ({ target }) => {
    const trimmedValue = target.value.trim()
    onChange(trimmedValue)
  }

  const fieldClassNames = classNames('content-block-field content-block__textarea-field', className, {
    'content-block-field--required': required,
  })

  const fieldControlClassNames = classNames('content-block-field__control content-block__textarea-field__control', {
    'content-block__textarea-field__control--error': isInvalid,
  })

  const fieldLabelClassNames = classNames('content-block-field__label')

  const fieldErrorClassNames = classNames('content-block-field__error')

  const handleChange = onChange ? ({ target }) => onChange(target.value) : field && field.onChange

  const errorText = errorMessage || (required && I18n.t('react.shared.validations.required'))

  return (
    <div className={fieldClassNames}>
      {label && (
        <label className={fieldLabelClassNames} htmlFor={name}>
          <span>{label}</span>
        </label>
      )}

      {isInvalid && (
        <div className={fieldErrorClassNames}>
          {errorText}
          <i className='fas fa-exclamation-circle' />
        </div>
      )}

      <textarea
        autoComplete={autocomplete}
        className={fieldControlClassNames}
        id={name}
        onBlur={textFieldChange}
        onChange={handleChange}
        value={value || (field && field.value) || ''}
        name={name}
        placeholder={placeholder}
        rows={6}
      />
    </div>
  )
}

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const defaultProps = {
  autocomplete: AUTOCOMPLETE_OFF,
}

ContentBlockTextareaField.propTypes = propTypes
ContentBlockTextareaField.defaultProps = defaultProps

export default ContentBlockTextareaField
