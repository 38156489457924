import React, { Fragment } from 'react'
import classNames from 'classnames'

import { FREE_PRICE } from 'constants/general.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { withStores } from 'utils/helpers.utils'

const ProductPrice = (props) => {
  const {
    price,
    currencyKey,
    mainPrice,
    contentPageStore: { convertToPrice },
    classes,
    isGift,
    previewMode,
  } = props

  const priceClasses = classNames({
    [classes.mainPrice]: mainPrice,
    [classes.price]: !mainPrice,
  })

  return (
    <Fragment>
      {isGift ? (
        <div className={classes.giftProduct}>
          <i className='fas fa-gift' />
          {I18n.t('react.shop.payment.success.gift_product')}
        </div>
      ) : (
        <span
          translate='no'
          className={priceClasses}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.price,
            'data-highlighter-selector': '',
          })}
        >
          {price === '0.0' ? FREE_PRICE : convertToPrice(price, currencyKey)}
        </span>
      )}
    </Fragment>
  )
}

export default withStores(['contentPageStore'], ProductPrice)
