import { action, observable, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/membershipThemes.api'

export class MembershipThemesStore extends SharedStore {
  storeName = 'MembershipThemesStore'
  childApi = api
  @observable templates = []

  @action setTemplates = (templates) => (this.templates = templates)

  fetchTemplatesList = async () => {
    this.toggleLoading(true)

    const { data } = (await api.fetchTemplatesList()) || {}
    const { list = [] } = data || {}
    this.setTemplates(list)

    this.toggleLoading(false)
  }

  duplicateByToken = (token, data) => api.duplicateByToken(token, data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new MembershipThemesStore()
