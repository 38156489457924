import React, { Fragment } from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { withStores } from 'utils/helpers.utils'
import { PAYMENT_FORMS, PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'
import { TRANSFER_STATES } from 'constants/transfersShared.constants'

import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { INVOICE_STATES } from 'constants/invoices.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import { arrayLast } from 'utils/array.utils'
import { getProfileFullName, getUserAddress } from 'utils/user.utils'
import { getCountryName } from 'utils/countries.utils'

import { PaymentInProgress } from './PaymentInProgress'
import InvoiceButton from './InvoiceButton'
import ProductPrice from './ProductPrice'

const TransfersOverview = (props) => {
  const {
    order,
    classes,
    showTransactionDetails,
    isGift,
    payerDataFromDeal,
    ownerDataFromDeal,
    hasDifferentBillingAddress,
    viewType,
    lastTransfer,
    previewMode,
    contentPageStore,
    contentPageStore: { convertToPrice },
    ordersStore,
    countriesStore,
    sellerStore,
  } = props
  const { currencyId } = ordersStore.data || {}

  const {
    nextChargeAmount,
    nextChargeDate,
    paymentForm,
    invoices = [],
    transfers = [],
    withCustomStart,
    withTestPeriod,
    provider,
    payerData,
  } = order || {}
  const { userProfileAttributes } = payerData || {}
  const { phone: payerPhone } = userProfileAttributes || {}
  const { inProgress } = lastTransfer || {}

  const isCartOrDigitalPayment =
    paymentForm === PAYMENT_FORMS.googlePay ||
    paymentForm === PAYMENT_FORMS.applePay ||
    paymentForm === PAYMENT_FORMS.card

  const oneTransfer =
    transfers.length === 1 ||
    transfers?.filter((transfer) => transfer.state === TRANSFER_STATES.successful).length === 1 ||
    transfers?.filter((transfer) => transfer.inProgress).length === 1

  const labelHighlightProps = {
    ...(previewMode && {
      'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.labels,
      'data-highlighter-selector': '.higlight-labels',
      'data-highlighter-parent': '.thank-you-block',
    }),
  }

  const detailsHighlightProps = {
    className: 'higlight-details',
    ...(previewMode
      ? {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.details,
          'data-highlighter-selector': '.higlight-details',
          'data-highlighter-parent': '.thank-you-block',
        }
      : {}),
  }
  const postponePaymentInfo =
    !lastTransfer &&
    (!invoices.length || (invoices || [])[0]?.state === INVOICE_STATES.unpaid) &&
    (withTestPeriod || withCustomStart)
  const isPayLater = paymentForm === PAYMENT_FORMS.payLater
  const payLaterWithFakeTransfer = provider === PAYMENT_PROVIDERS.mangoPay && isPayLater
  const formattedNextChargeDate =
    nextChargeDate && moment(nextChargeDate, DATE_FORMATS.default).format(DATE_FORMATS.DDMMYYYYHHmm)

  const payerFullName =
    getProfileFullName(ordersStore.data?.payerData?.userProfileAttributes) || ordersStore.data?.payer?.fullName
  const payerAddress =
    getUserAddress(ordersStore.data?.payerData?.userProfileAttributes, countriesStore.list) ||
    ordersStore.data?.payer?.address

  const payerFullNameFromDeal = `${payerDataFromDeal?.firstName} ${payerDataFromDeal?.lastName}`
  const payerFullCityNameFromDeal = [payerDataFromDeal?.zip, payerDataFromDeal?.city].join(' ')
  const payerCountryFromDeal = getCountryName(countriesStore.list, payerDataFromDeal?.countryCode)

  const differentFullCityName = [ownerDataFromDeal?.zip, ownerDataFromDeal?.city].join(' ')
  const differentFullStreetName = [ownerDataFromDeal?.streetNumber, ownerDataFromDeal?.street].join(' ')
  const differentFullName = `${ownerDataFromDeal?.firstName} ${ownerDataFromDeal?.lastName}`
  const differentCountryName = getCountryName(countriesStore.list, ownerDataFromDeal?.countryCode)

  const fullPayerDataFromDeal = [
    payerFullNameFromDeal,
    payerDataFromDeal?.street,
    payerFullCityNameFromDeal,
    payerCountryFromDeal,
  ]
    .filter(Boolean)
    .join(', ')

  const fullDifferentBillingDataFromDeal = [
    differentFullName,
    differentFullStreetName,
    differentFullCityName,
    differentCountryName,
    ownerDataFromDeal?.phone,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <Fragment>
      <div>
        {!oneTransfer &&
          transfers.map(({ inProgress, invoiceLink, state, total, currencyKey, idForSeller }, index) => {
            const showTitle = index === 0 || transfers[index - 1]?.state !== TRANSFER_STATES.successful
            return (
              <div className={classes.section} key={idForSeller}>
                {state === TRANSFER_STATES.successful && (
                  <Fragment>
                    <div className={classes.sectionItem}>
                      {showTitle && (
                        <div className={classNames(classes.sectionTitle, classes.mobileHide)}>
                          {I18n.t('react.shared.order_summary')}
                        </div>
                      )}
                      {showTransactionDetails && <InvoiceButton {...{ invoiceLink, classes }} lessMargin />}
                    </div>
                    <div className={classNames(classes.sectionItem, classes.sectionItemRight)}>
                      {showTitle && (
                        <Fragment>
                          <div className={classNames(classes.sectionTitle, classes.mobileShow)}>
                            {I18n.t('react.shared.summary')}
                          </div>
                          <div className={classNames(classes.sectionTitle, classes.mobileHide)}>
                            {I18n.t('react.shared.amount_paid')}
                          </div>
                        </Fragment>
                      )}
                      <ProductPrice
                        {...{
                          price: total,
                          currencyKey,
                          classes,
                          isGift,
                          previewMode,
                        }}
                      />
                    </div>
                  </Fragment>
                )}
                {inProgress && (
                  <PaymentInProgress
                    contentPageStore={contentPageStore}
                    ordersStore={ordersStore}
                    sellerStore={sellerStore}
                    {...{
                      currencyKey,
                      order,
                      viewType,
                      showProductsInfo: false,
                      classes,
                    }}
                  />
                )}
              </div>
            )
          })}

        {(isPayLater || (oneTransfer && inProgress)) && (
          <PaymentInProgress
            contentPageStore={contentPageStore}
            ordersStore={ordersStore}
            sellerStore={sellerStore}
            {...{
              currencyId,
              order,
              viewType,
              showProductsInfo: false,
              classes,
            }}
          />
        )}
      </div>

      <div className={classes.section}>
        {showTransactionDetails && (
          <div className={classes.sectionItem}>
            {((oneTransfer && !inProgress) || postponePaymentInfo) && (
              <div className={classNames(classes.sectionTitle, 'higlight-labels')} {...labelHighlightProps}>
                {I18n.t('react.shared.order_summary')}
              </div>
            )}
            <div>
              {oneTransfer && !inProgress && !payLaterWithFakeTransfer && (lastTransfer || !!invoices.length) && (
                <div>
                  <span className={classNames(classes.boldText, 'higlight-labels')} {...labelHighlightProps}>
                    {I18n.t('react.shared.transaction_id')}
                  </span>
                  <span {...detailsHighlightProps}>
                    {(lastTransfer || {}).idForSeller || (arrayLast(invoices) || {}).number}
                  </span>
                </div>
              )}
              {postponePaymentInfo && (
                <div
                  className={classes.orderInfo}
                  translate='no'
                  dangerouslySetInnerHTML={{
                    // prettier-ignore
                    __html: isCartOrDigitalPayment
                    ?
                    // prettier-ignore
                    I18n.t('react.shared.payment.success.delayed_payment_info_with_no_autocharge', { next_charge_date: formattedNextChargeDate, next_charge_amount: convertToPrice(nextChargeAmount, currencyId)})
                    :
                    // prettier-ignore
                    I18n.t('react.shared.payment.success.delayed_payment_info', { next_charge_date: formattedNextChargeDate, next_charge_amount: convertToPrice(nextChargeAmount, currencyId)}),
                  }}
                />
              )}

              {isGift && !hasDifferentBillingAddress && (
                <div>
                  <span className={classes.boldText}>{I18n.t('react.shared.payment.success.payer_details')}</span>
                  {[payerFullName, payerAddress, payerPhone].filter(Boolean).join(', ')}
                </div>
              )}

              {hasDifferentBillingAddress && (
                <div>
                  <span className={classes.boldText}>{I18n.t('react.shared.payment.success.payer_details')}</span>
                  {fullPayerDataFromDeal}
                  <span
                    className={classNames(classes.sectionTitle, classes.boldText, 'higlight-labels')}
                    {...labelHighlightProps}
                  >
                    <br />
                    {I18n.t('react.shared.payment.success.owner_details')}
                  </span>
                  <span {...detailsHighlightProps}>{fullDifferentBillingDataFromDeal}</span>
                </div>
              )}

              {oneTransfer && !inProgress && !hasDifferentBillingAddress && (
                <div>
                  <span
                    className={classNames(classes.sectionTitle, classes.boldText, 'higlight-labels')}
                    {...labelHighlightProps}
                  >
                    {I18n.t('react.shared.payment.success.owner_details')}
                  </span>
                  <span {...detailsHighlightProps}>
                    {payerDataFromDeal
                      ? fullPayerDataFromDeal
                      : [payerFullName, payerAddress, payerPhone].filter(Boolean).join(', ')}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {oneTransfer && !inProgress && !payLaterWithFakeTransfer && (
          <div className={classNames(classes.sectionItem, classes.sectionItemRight)}>
            <div className={classNames(classes.sectionTitle, 'higlight-labels')} {...labelHighlightProps}>
              {I18n.t('react.shared.amount_paid')}
            </div>
            <ProductPrice
              {...{
                price: lastTransfer.total,
                currencyKey: lastTransfer.currencyKey,
                classes,
                isGift,
                previewMode,
              }}
              mainPrice
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default withStores(['contentPageStore', 'countriesStore', 'sellerStore', 'ordersStore'], TransfersOverview)
