import React, { Fragment } from 'react'
import classNames from 'classnames'

import { FormattedTicketDates } from '@elo-kit/components/formatters/formatted-ticket-dates'
import { getDigitalIcon } from '@elo-kit/utils/block.utils'
import { createId } from '@elo-kit/utils/general.utils'
import { PRODUCT_TYPE_IDS } from 'constants/productsShared.constants'
import { ORDER_VIEW_TYPES } from 'constants/ordersShared.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { getFormattedTicketLocation } from 'utils/tickets.utils'
import { withStores } from 'utils/helpers.utils'

import ProductAccess from './ProductAccess'

const ProductCover = ({ cover, classes, small }) =>
  cover ? (
    <img src={cover} alt='cover' className={classNames(small ? classes.productCoverSmall : classes.productCover)} />
  ) : (
    <div
      className={
        small
          ? classNames(classes.noProductCoverSmall, classes.productCoverSmall)
          : classNames(classes.noProductCover, classes.productCover)
      }
    >
      <i className='fas fa-image' />
    </div>
  )

const ProductOverview = (props) => {
  const {
    sellable,
    classes,
    index,
    sellables,
    previewMode,
    viewType,
    isGift,
    order,
    block,
    contentPageStore: { downloadGoodsStore, countriesList = [], user },
  } = props

  const { product = {}, sellableItems } = sellable

  const withBundle =
    product && (product.form === PRODUCT_TYPE_IDS.bundle || product.form === PRODUCT_TYPE_IDS.membership)

  const listClasses = classNames(withBundle ? classes.innerProductsContainer : classes.productWrapper)
  const showProductSeparator = !withBundle && index + 1 !== sellables.length
  const showDownload = isGift || viewType === ORDER_VIEW_TYPES.owner
  const productNameProps = {
    className: classNames(classes.productName, 'higlight-title'),
    ...(previewMode
      ? {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.title,
          'data-highlighter-selector': '.higlight-title',
          'data-highlighter-parent': '.thank-you-block',
        }
      : {}),
  }

  const downloadGood = async (goodLink) => {
    const url = new URL(goodLink)
    const goodId = url.searchParams.get('good_id')
    const sellableItemId = url.searchParams.get('sellable_item_id')

    const resp = await downloadGoodsStore.downloadGoods(order.token, {
      goodId,
      sellableItemId,
    })

    if (resp.data?.redirectPath) {
      window.open(resp.data.redirectPath, '_self')
    }
  }

  return (
    <Fragment>
      {withBundle && (
        <div className={classes.productContainer}>
          <div className={classes.productDetailsContainer}>
            <ProductCover cover={product.cover} classes={classes} />

            <div {...productNameProps}>{product.name}</div>
          </div>
        </div>
      )}
      <div className={listClasses}>
        {sellableItems?.map((sellableProduct, index) => {
          const mergedProduct = {
            ...sellableProduct,
            ...(sellableProduct?.product || {}),
          }

          const productIsExpired =
            mergedProduct.expired &&
            !(viewType === ORDER_VIEW_TYPES.owner && user.id && user.profileTypes.includes('payer'))

          const showDownloadGoods = ({ form, goods, needStamping } = {}) =>
            showDownload && !needStamping && goods.length > 0 && form === PRODUCT_TYPE_IDS.download

          const showGoods = showDownloadGoods(mergedProduct) && !productIsExpired
          const isLastProduct = sellableItems.length === index + 1

          const isTicket = mergedProduct.form === PRODUCT_TYPE_IDS.eventTickets

          const buttonView = showGoods || sellableItems.length === 1 || !withBundle
          const smallCover = withBundle && !(showGoods && !!mergedProduct?.goods?.length)

          return (
            <Fragment key={index}>
              <div className={classes.productContainer}>
                <div className={classes.productDetailsContainer}>
                  <ProductCover cover={mergedProduct.cover} classes={classes} small={smallCover} />

                  <div>
                    <div {...productNameProps}>{mergedProduct.name}</div>
                    {isTicket && (
                      <div
                        className={classes.productInfo}
                        {...(previewMode && {
                          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.details,
                          'data-highlighter-selector': '.higlight-details',
                          'data-highlighter-parent': '.thank-you-block',
                        })}
                      >
                        <div>{getFormattedTicketLocation(mergedProduct.ticket, countriesList)}</div>
                        <FormattedTicketDates ticketDate={mergedProduct.ticketDate} withAdditionalText />
                      </div>
                    )}
                  </div>
                </div>

                {showDownload && (
                  <ProductAccess
                    sellableItemId={sellableProduct.id}
                    {...{
                      order,
                      classes,
                      buttonView,
                      product: mergedProduct,
                      productIsExpired,
                      previewMode,
                      block,
                      downloadGoodsStore,
                    }}
                  />
                )}
                {!showDownload && isGift && I18n.t('react.shared.payment.success.gift_successfully_sent')}
              </div>

              {!showGoods && !isLastProduct && <hr className={classes.lightSeparator} />}

              {showGoods && (
                <div className={classes.innerProductsContainer}>
                  {mergedProduct?.goods?.map((good, index) => {
                    const isLastGood = mergedProduct.goods.length === index + 1
                    return (
                      <Fragment key={createId(good.name, index)}>
                        <div className={classes.productContainer}>
                          <div className={classes.productDetailsContainer}>
                            <div
                              className={classNames(classes.productCoverSmall, classes.noProductCoverSmall)}
                              dangerouslySetInnerHTML={{ __html: getDigitalIcon(good.digital) }}
                            />
                            <div className={classes.productDetails}>
                              <div {...productNameProps}>{good.customeName || good.name}</div>
                              <div className={classes.fileDetails}>{good.size}</div>
                            </div>
                          </div>

                          <a className={classes.productDownloadLink} onClick={() => downloadGood(good.link)}>
                            <i className='fas fa-download' />
                            {I18n.t('react.shared.button.download')}
                          </a>
                        </div>

                        {!isLastGood && <hr className={classes.lightSeparator} />}
                      </Fragment>
                    )
                  })}
                </div>
              )}
            </Fragment>
          )
        })}
      </div>

      {showProductSeparator && <hr className={classes.lightSeparator} />}
    </Fragment>
  )
}

export default withStores(['contentPageStore'], ProductOverview)
