import Datetime from 'react-datetime'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BUILDER_FIELD_TYPES } from 'shared/components/page-builder/constants/pageBuilder.constants'
import { BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'
import {
  BAR_STYLE_OPTIONS,
  BAR_STYLE_TYPES,
  BLOCKS_WITH_COLOR_PICKER,
  COUNTDOWN_TYPE_SELECT_OPTIONS,
  COUNTDOWN_TYPES,
  COUNTDOWN_UNITS_SELECT_OPTIONS,
  COUNTING_DIRECTION_SELECT_OPTIONS,
  IMAGE_STYLE_OPTIONS,
  IMAGE_STYLE_TYPES,
  YES_NO_OPTIONS,
} from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.cabinet.common.type'),
    fields: [BUILDER_FIELD_TYPES.conversionToolsView],
  },
  {
    dataMenuHighlight: 'icon',
    show: {
      key: 'view',
      value: BLOCK_VIEW.countdown,
    },
    label: I18n.t('shared.common.style'),
    fields: [
      [
        {
          field: BUILDER_FIELD_TYPES.backgroundColor,
          key: 'countdownBackgroundColor',
        },
        {
          field: BUILDER_FIELD_TYPES.transparency,
          key: 'countdownTransparency',
        },
      ],
      ['cornerRadius', 'shadow'],
      {
        label: I18n.t('react.shared.icon'),
        field: 'uploadImage',
      },
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.icon_position'),
        key: 'iconAlignment',
      },
    ],
  },
  {
    dataMenuHighlight: 'progressbar_styles',
    show: {
      key: 'view',
      value: BLOCK_VIEW.progressBar,
    },
    label: I18n.t('shared.common.style'),
    fields: [
      {
        label: I18n.t('react.shared.bar_style'),
        field: BUILDER_FIELD_TYPES.selectItems,
        key: 'progressBarStyle',
        props: {
          options: BAR_STYLE_OPTIONS,
        },
      },
      [
        {
          label: I18n.t('react.shared.main_color'),
          field: 'color',
          key: 'progressBarMainColor',
        },
        {
          label: I18n.t('react.shared.additional_color'),
          field: 'color',
          key: 'progressBarAdditionalColor',
        },
      ],
      {
        show: {
          key: 'progressBarStyle',
          value: [BAR_STYLE_TYPES.loading, BAR_STYLE_TYPES.bordered, BAR_STYLE_TYPES.sections],
        },
        field: 'cornerRadius',
      },
      [
        {
          show: {
            key: 'progressBarStyle',
            value: [BAR_STYLE_TYPES.loading, BAR_STYLE_TYPES.bordered, BAR_STYLE_TYPES.sections],
          },
          label: I18n.t('react.shared.width'),
          field: BUILDER_FIELD_TYPES.pixels,
          key: 'progressBarWidth',
        },
        {
          show: {
            key: 'progressBarStyle',
            value: [BAR_STYLE_TYPES.loading, BAR_STYLE_TYPES.bordered, BAR_STYLE_TYPES.sections],
          },
          label: I18n.t('react.shared.height'),
          field: BUILDER_FIELD_TYPES.pixels,
          key: 'progressBarHeight',
        },
      ],
      [
        {
          show: {
            key: 'progressBarStyle',
            value: [
              BAR_STYLE_TYPES.circle,
              BAR_STYLE_TYPES.halfCircle,
              BAR_STYLE_TYPES.filledCircle,
              BAR_STYLE_TYPES.pieCircle,
            ],
          },
          label: I18n.t('react.shared.number_color'),
          field: 'color',
          key: 'progressBarNumberColor',
        },
        {
          show: {
            key: 'progressBarStyle',
            value: [
              BAR_STYLE_TYPES.circle,
              BAR_STYLE_TYPES.halfCircle,
              BAR_STYLE_TYPES.filledCircle,
              BAR_STYLE_TYPES.pieCircle,
            ],
          },
          label: I18n.t('react.shared.width'),
          key: 'circleWidth',
          field: BUILDER_FIELD_TYPES.pixels,
        },
      ],
      {
        show: {
          key: 'progressBarStyle',
          value: [BAR_STYLE_TYPES.loading, BAR_STYLE_TYPES.bordered],
        },
        label: I18n.t('react.shared.show_value'),
        key: 'showNumbers',
        field: BUILDER_FIELD_TYPES.selectItems,
        props: {
          options: YES_NO_OPTIONS,
        },
      },
      {
        dataMenuHighlight: 'progressbar_description',
        label: I18n.t('react.cabinet.description'),
        fields: [
          {
            field: 'froalaText',
            key: 'progressBarDescriptionText',
          },
        ],
      },
    ],
  },
  {
    show: {
      key: 'view',
      value: [BLOCK_VIEW.countdown, BLOCK_VIEW.progressBar],
    },
    label: I18n.t('react.shared.background'),
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    show: {
      key: 'view',
      value: [BLOCK_VIEW.modal],
    },
    dataMenuHighlight: 'modal_body_background',
    label: I18n.t('react.shared.modal_window_background'),
    fields: [
      'uploadBackgroundImage',
      [
        {
          field: BUILDER_FIELD_TYPES.backgroundColor,
          key: 'modalBackgroundColor',
        },
        {
          field: BUILDER_FIELD_TYPES.transparency,
          key: 'modalTransparency',
        },
      ],
      ['cornerRadius', 'shadow'],
      {
        label: I18n.t('react.shared.image_size'),
        key: 'imageStyle',
        field: BUILDER_FIELD_TYPES.selectItems,
        props: {
          options: IMAGE_STYLE_OPTIONS,
          defaultValue: IMAGE_STYLE_TYPES.smallImage,
        },
      },
    ],
  },
  {
    dataMenuHighlight: true,
    show: {
      key: 'view',
      value: BLOCK_VIEW.modal,
    },
    label: I18n.t('react.shared.text'),
    fields: [
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'textAlignment',
      },
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'textWidth',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'textMaxWidth',
        },
      ],
      {
        dataMenuHighlight: 'main_text',
        label: I18n.t('react.cabinet.main_text'),
        tooltipId: 'main_text',
        tooltipTitle: true,
        fields: [
          [
            {
              field: 'font',
              key: 'mainFont',
            },
            {
              field: 'fontStyle',
              key: 'mainStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.mainColor,
            },
            {
              field: 'fontSize',
              key: 'mainSize',
            },
          ],
          {
            field: 'addText',
            key: 'titleText',
          },
        ],
      },
      {
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.additionalText,
        label: I18n.t('react.cabinet.additional_text'),
        tooltipId: 'additional_text',
        tooltipTitle: true,
        fields: [
          [
            {
              field: 'font',
              key: 'additionalFont',
            },
            {
              field: 'fontStyle',
              key: 'additionalStyle',
            },
          ],
          [
            {
              field: 'color',
              key: BLOCKS_WITH_COLOR_PICKER.additionalColor,
            },
            {
              field: 'fontSize',
              key: 'additionalSize',
            },
          ],
          {
            field: 'addText',
            key: 'additionalText',
          },
        ],
      },
    ],
  },
  {
    dataMenuHighlight: 'custom_cta',
    label: I18n.t('react.cabinet.CTA_button'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.modal,
    },
    toggle: 'showButton',
    tooltipId: 'block_CTA_button',
    fields: [
      {
        field: 'link',
        key: 'buttonLink',
      },
      {
        field: 'text',
        key: 'buttonText',
      },
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      'buttonViewStyle',
      {
        field: 'cornerRadius',
        key: 'buttonCornerRadius',
      },
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      'buttonAnimation',
    ],
  },
  {
    label: I18n.t('react.shared.settings'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.modal,
    },
    fields: [
      {
        label: I18n.t('react.shared.open_modal_after'),
        field: 'text',
        key: 'openModalAfter',
        props: {
          inputDescription: I18n.t('react.cabinet.lesson.lb.seconds'),
        },
      },
    ],
  },
  {
    dataMenuHighlight: true,
    label: I18n.t('react.shared.numbers_or_text'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.countdown,
    },
    fields: [
      {
        dataMenuHighlight: 'numbers',
        label: I18n.t('react.shared.numbers'),
        fields: [
          [
            {
              field: BUILDER_FIELD_TYPES.font,
              key: 'numbersFont',
            },
            {
              field: BUILDER_FIELD_TYPES.fontStyle,
              key: 'numbersFontStyle',
            },
          ],
          [
            {
              field: BUILDER_FIELD_TYPES.color,
              key: 'numbersColor',
            },
            {
              field: BUILDER_FIELD_TYPES.fontSize,
              key: 'numbersFontSize',
            },
          ],
        ],
      },
      {
        dataMenuHighlight: 'text',
        label: I18n.t('react.shared.text'),
        fields: [
          {
            label: I18n.t('react.shared.description'),
            field: BUILDER_FIELD_TYPES.text,
            key: 'text',
            props: {
              placeholder: I18n.t('react.shared.here_goes_the_description'),
            },
          },
          [
            {
              field: BUILDER_FIELD_TYPES.font,
              key: 'textFont',
            },
            {
              field: BUILDER_FIELD_TYPES.fontStyle,
              key: 'textFontStyle',
            },
          ],
          [
            {
              field: BUILDER_FIELD_TYPES.color,
              key: 'textColor',
            },
            {
              field: BUILDER_FIELD_TYPES.fontSize,
              key: 'textFontSize',
            },
          ],
        ],
      },
    ],
  },
  {
    label: I18n.t('react.shared.settings'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.progressBar,
    },
    fields: [
      {
        field: BUILDER_FIELD_TYPES.percentage,
        label: I18n.t('react.shared.progress_bar_value'),
        key: 'progressBarValue',
      },
    ],
  },
  {
    label: I18n.t('react.shared.settings'),
    show: {
      key: 'view',
      value: BLOCK_VIEW.countdown,
    },
    fields: [
      {
        label: I18n.t('react.cabinet.common.type'),
        key: 'countdownType',
        field: BUILDER_FIELD_TYPES.selectItems,
        props: {
          options: COUNTDOWN_TYPE_SELECT_OPTIONS,
        },
      },
      {
        label: I18n.t('react.shared.counting'),
        tooltipId: 'counting',
        show: {
          key: 'countdownType',
          value: COUNTDOWN_TYPES.counter,
        },
        key: 'countingDirection',
        field: BUILDER_FIELD_TYPES.selectItems,
        props: {
          options: COUNTING_DIRECTION_SELECT_OPTIONS,
        },
      },
      {
        show: {
          key: 'countdownType',
          value: COUNTDOWN_TYPES.countdown,
        },
        label: I18n.t('react.shared.end_date'),
        tooltipId: 'end_date',
        key: 'countdownEndDate',
        field: BUILDER_FIELD_TYPES.date,
        props: {
          isValidDate: (date) => date.isAfter(Datetime.moment()),
          dateFormat: DATE_FORMATS.DDMMYYYY,
          timeFormat: 'HH:mm:ss', // will be changed soon TIME_FORMATS.HHmmss
        },
      },
      [
        {
          show: {
            key: 'countdownType',
            value: COUNTDOWN_TYPES.counter,
          },
          label: I18n.t('react.shared.step'),
          field: BUILDER_FIELD_TYPES.text,
          key: 'countdownStep',
        },
        {
          show: {
            key: 'countdownType',
            value: COUNTDOWN_TYPES.counter,
          },
          label: I18n.t('react.shared.units'),
          field: BUILDER_FIELD_TYPES.selectItems,
          key: 'countdownStepUnits',
          props: {
            options: COUNTDOWN_UNITS_SELECT_OPTIONS,
          },
        },
      ],
      [
        {
          show: {
            key: 'countdownType',
            value: COUNTDOWN_TYPES.counter,
          },
          label: I18n.t('react.shared.number'),
          field: BUILDER_FIELD_TYPES.text,
          key: 'countdownNumber',
        },
        {
          show: {
            key: 'countdownType',
            value: COUNTDOWN_TYPES.counter,
          },
          label: I18n.t('react.shared.item'),
          field: BUILDER_FIELD_TYPES.text,
          key: 'countdownItem',
        },
      ],
    ],
  },
  {
    show: {
      key: 'view',
      value: [BLOCK_VIEW.countdown, BLOCK_VIEW.modal],
    },
    label: I18n.t('react.shared.width'),
    fields: [
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    show: {
      key: 'view',
      value: [BLOCK_VIEW.countdown, BLOCK_VIEW.progressBar],
    },
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    show: {
      key: 'view',
      value: [BLOCK_VIEW.modal],
    },
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'modalPaddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'modalPaddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'modalPaddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'modalPaddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
