import React from 'react'
import classNames from 'classnames'

import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { AFFILIATE_CLASS_NAMES } from '../../../../../page-builder/constants/block.constants'

const ButtonAffiliate = (props) => {
  const {
    content: { buttonAnimation, buttonText },
    accept,
    previewMode,
    affiliateProgram,
    classes,
  } = props
  const buttonClasses = classNames(classes.button, AFFILIATE_CLASS_NAMES.buttonClassName, {
    [classes.shadow]: !buttonAnimation,
    [classes[buttonAnimation]]: !!buttonAnimation,
  })

  const handleAccept = () => (!previewMode ? accept(affiliateProgram.token) : null)

  return (
    <button
      type='button'
      className={buttonClasses}
      onClick={handleAccept}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.affiliate.button,
        'data-highlighter-selector': '',
      })}
    >
      {buttonText || I18n.t('react.shared.button.accept')}
    </button>
  )
}

export default ButtonAffiliate
