import React from 'react'

import MembershipProductsPreview from '@elo-kit/components/page-builder/blocks/membership-products/membership-products-preview'

import {
  getAllProductsCategories,
  ALL_PRODUCTS_CATEGORY_OPTION,
  getDefaultProductsList,
} from 'constants/contentPageShared.constants'

import { withStores } from 'utils/helpers.utils'

import { changeCategoryTabInUrl } from '../../../../page-builder/utils/block.utils'

const MembershipProductsPreviewContainer = (props) => {
  const { contentPageStore, productGroupsStore, payerStore, params, historyPush, LinkComponent, block } = props
  const {
    getProductLink,
    fetchMembershipProducts,
    seller,
    membershipSellable,
    membershipViewType,
    membershipProducts,
    view,
  } = contentPageStore
  const { fetchFullList, toggleLoading, loading } = productGroupsStore
  const { item: payerItem = {} } = payerStore
  const { list: products, loading: membershipProductsLoading } = membershipProducts[block?.id] || {}

  return (
    <MembershipProductsPreview
      {...props}
      fetchCategories={fetchFullList}
      productGroupsLoading={loading}
      LinkComponent={LinkComponent}
      queryCategoryId={(params || {})[`block_${block.id}_group_id`]}
      defaultSearchPlaceholder={I18n.t('shared.common.search')}
      noProductsMessage={I18n.t('react.shared.no_products_found')}
      yourProgressText={I18n.t('react.cabinet.yourProgress')}
      changeCategoryTabInUrl={(id) => changeCategoryTabInUrl(params, historyPush, id, block.id)}
      payerId={payerItem.id}
      defaultProductsList={getDefaultProductsList()}
      allProductsCategory={getAllProductsCategories()}
      allProductsCategoryOption={ALL_PRODUCTS_CATEGORY_OPTION}
      categorySelectFieldContentBefore={I18n.t('shared.common.category')}
      {...{
        getProductLink,
        membershipProductsLoading,
        fetchMembershipProducts: (data) => fetchMembershipProducts(data, block?.id),
        seller,
        membershipSellable,
        membershipViewType,
        membershipProducts: products,
        view,
        toggleLoading,
      }}
    />
  )
}

const stores = ['contentPageStore', 'productGroupsStore', 'payerStore']

export default withStores(stores, MembershipProductsPreviewContainer)
