import React, { Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { formatTimeForCountdown } from 'utils/dateTime.utils'
import { capitalize } from 'utils/nameStyle.utils'

import useTimer from './hooks/useTimer'
import { COUNTDOWN_TYPES } from '../../../../../page-builder/constants/block.constants'
import { counterMathFunction } from '../../../../../page-builder/utils/block.utils'

const Col = (props) => {
  const { alwaysShow, plural, singular, value, showSeparator = true } = props

  if (value > 0 || alwaysShow) {
    return (
      <span>
        <strong>{value || 0}</strong>
        <span>
          {value === 1 ? singular : plural}
          {showSeparator && ' '}
        </span>
      </span>
    )
  }

  return null
}

const Countdown = (props) => {
  const {
    block: {
      content: {
        countdownType,
        countdownStep,
        countdownEndDate,
        countdownStepUnits,
        countdownNumber,
        countdownItem,
        countingDirection,
        text,
      },
      createdAt,
      goods = [],
    } = {},
    classes,
    previewMode,
  } = props
  const isCounter = countdownType === COUNTDOWN_TYPES.counter
  const iconUrl = goods.length > 0 && goods[0]?.digital?.file?.original
  const additionalProps = isCounter
    ? {
        startTime: moment(moment(new Date(createdAt), DATE_FORMATS.DDMMYYYYHHmmss), DATE_FORMATS.DDMMYYYYHHmmss),
        endTime: moment(new Date(), DATE_FORMATS.DDMMYYYYHHmmss),
      }
    : {
        endTime: moment(new Date(countdownEndDate), DATE_FORMATS.DDMMYYYYHHmmss),
      }

  const duration = useTimer({
    ...props,
    ...additionalProps,
    isCounter,
    previewMode,
  })

  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  const months = duration.months()
  const years = duration.years()

  const isTimeLessThanDay = duration.asWeeks() < 1 && days === 0
  const showHours = hours > 0
  const showMinutes = minutes > 0
  const countdownIsEnded = !showHours && !showMinutes && seconds <= 0

  const amountWeeks = Math.floor(days / 7).toFixed(0)
  const remainingDays = Number((days % 7).toFixed(0))
  const countedTimes = Math.round(duration[`as${capitalize(countdownStepUnits)}`]()) * Number(countdownStep)

  return (
    <div className={classes.countdownContainer}>
      <div className={classes.countdownInfoContainer}>
        {(iconUrl || previewMode) && (
          <div
            className={classNames(classes.iconContainer)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.countdownIcon,
              'data-highlighter-selector': 'i, img',
            })}
          >
            {iconUrl ? (
              <img src={iconUrl} className={classNames(classes.icon)} alt='icon' />
            ) : (
              <i className={classNames('fas fa-bullhorn', classes.icon)} />
            )}
          </div>
        )}
        <div
          className={classNames(classes.numbers)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.countdownNumbers,
            'data-highlighter-selector': '',
          })}
        >
          {isCounter ? (
            <Fragment>
              <span>{counterMathFunction(Number(countdownNumber), Number(countedTimes), countingDirection)}</span>
              <span>{countdownItem}</span>
            </Fragment>
          ) : (
            <Fragment>
              {years >= 1 && (
                <Countdown.Col
                  plural={I18n.t('react.shared.years')}
                  singular={I18n.t('react.shared.year')}
                  value={years}
                />
              )}
              {months >= 1 && (
                <Countdown.Col
                  plural={I18n.t('react.shared.months')}
                  singular={I18n.t('react.shared.month')}
                  value={months}
                />
              )}
              {duration.asWeeks() > 1 && (
                <Countdown.Col
                  plural={I18n.t('react.shared.weeks')}
                  singular={I18n.t('react.shared.week')}
                  value={amountWeeks}
                />
              )}
              {isTimeLessThanDay ? (
                <>
                  <Countdown.Col
                    plural={I18n.t('react.shared.short_hours')}
                    singular={I18n.t('react.shared.short_hours')}
                    value={formatTimeForCountdown(hours)}
                    alwaysShow={showHours || countdownIsEnded}
                  />
                  <Countdown.Col
                    plural={I18n.t('react.shared.short_minutes')}
                    singular={I18n.t('react.shared.short_minutes')}
                    value={formatTimeForCountdown(minutes)}
                    alwaysShow={showMinutes || !showHours || countdownIsEnded}
                  />
                  <Countdown.Col
                    alwaysShow
                    plural={I18n.t('react.shared.short_seconds')}
                    singular={I18n.t('react.shared.short_seconds')}
                    value={formatTimeForCountdown(seconds)}
                    showSeparator={false}
                  />
                </>
              ) : (
                <Countdown.Col
                  plural={I18n.t('react.shared.days')}
                  singular={I18n.t('react.shared.day')}
                  value={remainingDays}
                  showSeparator={isTimeLessThanDay}
                />
              )}
            </Fragment>
          )}
        </div>
        {text && (
          <div
            className={classNames(classes.text)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.countdownText,
              'data-highlighter-selector': '',
            })}
          >
            {text}
          </div>
        )}
      </div>
    </div>
  )
}

Countdown.propTypes = {
  /** React JSS classes */
  classes: PropTypes.shape({}),
  /** Block */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
}

Countdown.defaultProps = {}

Countdown.Col = Col

export default Countdown
