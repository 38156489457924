import { createUseStyles } from 'react-jss'
import { COLORS } from 'shared/components/page-builder/constants/pageBuilder.constants'

import { PADDING_UNITS, YES, ZERO_DATA } from '@elo-kit/constants/general.constants'
import { getAlignment, hex2rgb } from '@elo-kit/utils/themes.utils'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { BUTTON_STYLE, DEFAULT_CONTACT_FORM_PADDING } from '@elo-kit/constants/contentPage.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { getPadding } from '@elo-kit/utils/contentBlock.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloGrey20, eloBlack } = COLORS

export const useContactFormStyles = createUseStyles((theme) => {
  const paddingUnits = theme.paddingUnits || PADDING_UNITS.px
  const defaultPadding = paddingUnits === PADDING_UNITS.px ? DEFAULT_CONTACT_FORM_PADDING : ZERO_DATA
  const placeholdersFont = cleanupFonts(theme.placeholdersFont) || 'Montserrat Medium'
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      maxWidth: '100%',
      padding: DEFAULT_CONTACT_FORM_PADDING,
    },
    contactForm: {
      width: `${theme.width || 100}%`,
      maxWidth: `${theme.maxWidth || 5000}px`,
      paddingTop: getPadding(theme.paddingTop, defaultPadding, theme.paddingUnits),
      paddingBottom: getPadding(theme.paddingBottom, defaultPadding, theme.paddingUnits),
      paddingRight: getPadding(theme.paddingRight, defaultPadding, theme.paddingUnits),
      paddingLeft: getPadding(theme.paddingLeft, defaultPadding, theme.paddingUnits),
      marginBottom: 0,
      borderRadius: `${theme.formRadius === undefined ? 0 : theme.formRadius}px`,
      backgroundColor: theme.formTransparency === 0 ? 'transparent' : hex2rgb(theme.formColor, theme.formTransparency),
      boxShadow: `${theme.shadow === YES ? '0 5px 10px 0 rgb(0, 0, 0, 0.1)' : 'none'}`,
      position: 'relative',

      '& input, & .date-time-field__control-wrapper .date-time-field__control': {
        border: `1px solid ${theme.inputsBorderColor || eloGrey20}`,
        height: `${theme.inputsHeight || 44}px`,
        borderRadius: `${theme.formRadius === undefined ? 0 : theme.formRadius}px`,

        '&::placeholder': {
          fontFamily: placeholdersFont,
          fontSize: `${theme.placeholdersSize || 13}px !important`,
          ...getFontStyles(theme.placeholdersStyle || 'bold'),
          color: `${theme.placeholdersColor || eloBlack} !important`,
        },
      },

      '& textarea': {
        border: `1px solid ${theme.inputsBorderColor || eloGrey20}`,
        height: '90px',
        borderRadius: `${theme.formRadius === undefined ? 0 : theme.formRadius}px`,

        '&::placeholder': {
          fontFamily: placeholdersFont,
          fontSize: `${theme.placeholdersSize || 13}px !important`,
          ...getFontStyles(theme.placeholdersStyle || 'bold'),
          color: `${theme.placeholdersColor || eloBlack} !important`,
          paddingTop: '15px',
        },
      },
    },
    contactFormTitle: {
      fontFamily: cleanupFonts(theme.mainFont) || 'Montserrat Medium',
      fontSize: `${theme.mainSize || 13}px`,
      ...getFontStyles(theme.mainStyle || 'bold'),
      color: theme.mainColor || eloBlack,
      textAlign: 'center',
      marginBottom: 20,
      lineHeight: 1,
    },
    contactFormDescription: {
      fontFamily: cleanupFonts(theme.descriptionFont) || 'Montserrat Medium',
      fontSize: `${theme.descriptionSize || 13}px`,
      ...getFontStyles(theme.descriptionStyle || 'bold'),
      color: theme.descriptionColor || eloBlack,
      lineHeight: '15px',
      marginBottom: 25,
      textAlign: 'center',
    },
    contactFormLabel: {
      display: 'inline',
      fontFamily: cleanupFonts(theme.labelsFont) || 'Montserrat Medium',
      fontSize: `${theme.labelsSize || 13}px`,
      ...getFontStyles(theme.labelsStyle || 'bold'),
      color: theme.labelsColor || eloBlack,
    },
    contactFormButtonContainer: {
      display: 'flex',
      justifyContent: getAlignment(theme.horizontalAlignment),
    },
    contactFormButton: {
      width: '100%',
      maxWidth: `${theme.buttonWidth || 420}px`,
      backgroundColor:
        theme.buttonViewStyle === BUTTON_STYLE.filled
          ? `${theme.buttonColor || '#1e84d7'} !important`
          : 'unset !important',
      color: theme.buttonTextColor || COLORS.eloBlue,
      border: `1px solid ${theme.buttonColor || COLORS.eloBlue}`,
      padding: '8px 5px 6px',
      borderRadius: `${theme.buttonRadius === undefined ? 0 : theme.buttonRadius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Reg',
      fontSize: `${theme.buttonSize || 12}px`,
      cursor: 'pointer',
      wordBreak: 'break-word',
      textAlign: 'center',
      lineHeight: 1.5,
      textDecoration: 'none',
      ...getFontStyles(theme.buttonStyle || 'bold'),
      minWidth: 104,
      whiteSpace: 'nowrap',
    },
    contactFormButtonDisabled: {
      opacity: '0.4',
      pointerEvents: 'none',
    },
    contactFormCountryField: {
      width: '100%',

      '& .elo-select-container': {
        '& .elo-select-field__control': {
          border: `1px solid ${theme.inputsBorderColor}`,
          height: `${theme.inputsHeight || 44}px`,
          borderRadius: `${theme.formRadius === undefined ? 0 : theme.formRadius}px`,

          '&:hover': {
            borderColor: theme.inputsBorderColor,
          },
        },

        '& .elo-select-field__placeholder': {
          fontFamily: placeholdersFont,
          fontSize: `${theme.placeholdersSize || 13}px !important`,
          ...getFontStyles(theme.placeholdersStyle || 'bold'),
          color: `${theme.placeholdersColor || eloBlack} !important`,
        },

        '& .elo-select-field.searchable': {
          '& .elo-select-field__value-container': {
            padding: '0 10px',
            marginTop: -3,
          },
        },

        '& .elo-select-field__single-value, & .elo-select-field__menu-list': {
          fontFamily: 'Montserrat Reg',
        },
      },
    },

    ...buttonAnimations(theme),
  }
})
