import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FieldError } from '@elo-kit/components/elo-ui/field-error/FieldError'

import { AUTOCOMPLETE_OFF } from 'constants/forms.constants'

import './content-block-text-field.scss'

const ContentBlockTextField = (props) => {
  const {
    className,
    label,
    required,
    placeholder,
    autocomplete,
    onChange,
    labelClassName,
    type,
    errorMessage,
    value,
    disabled,
    errorId,
    onClick,
    isInvalid,
    contactFormId,
    inputId,
  } = props

  const [inValid, setInValid] = useState(false)
  const [textName] = useState(`${contactFormId}-${inputId}`)

  useEffect(() => {
    if (isInvalid !== inValid) {
      setInValid(isInvalid)
    }
  }, [isInvalid, inValid])

  const textFieldChange = (e) => {
    const trimmedValue = e.target.value.trim()

    if (onChange) onChange(trimmedValue)

    const inValid = isInvalid || (required && !trimmedValue)
    setInValid(inValid)
  }

  const errorText = errorMessage || (required && I18n.t('react.shared.validations.required'))

  const fieldClassNames = classNames('content-block-field content-block__text-field', className, {
    'content-block__text-field--disabled': disabled,
    'content-block__text-field--required': required,
  })

  const fieldControlClassNames = classNames('content-block-field__control content-block__text-field__control', {
    'content-block__text-field__control--error': inValid,
  })

  const labelClasses = classNames(
    {
      [labelClassName]: labelClassName,
    },
    'content-block-field__label'
  )
  const controlWrapperClassNames = classNames('content-block__text-field__control-wrapper')

  return (
    <div className={fieldClassNames}>
      {label && (
        <label className={labelClasses} htmlFor={textName}>
          <span>{label}</span>
        </label>
      )}

      {inValid && <FieldError id={errorId} errorText={errorText} />}

      <div className={controlWrapperClassNames}>
        <input
          id={textName}
          className={fieldControlClassNames}
          autoComplete={autocomplete}
          onBlur={(e) => textFieldChange(e)}
          onChange={(e) => onChange(e.target.value)}
          onClick={onClick}
          placeholder={placeholder}
          type={type}
          value={value}
        />
      </div>
    </div>
  )
}

const propTypes = {
  contactFormId: PropTypes.number,
  inputId: PropTypes.number,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  autocomplete: PropTypes.string,
  type: PropTypes.string,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  errorId: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
}

const defaultProps = {
  autocomplete: AUTOCOMPLETE_OFF,
  disabled: false,
  type: 'text',
}

ContentBlockTextField.propTypes = propTypes
ContentBlockTextField.defaultProps = defaultProps

export default ContentBlockTextField
