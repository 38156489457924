import { action, computed, flow, makeObservable, observable, toJS } from 'mobx'

import { DEFAULT_TIMEZONE } from 'constants/timezones.constants'
import {
  EMAIL_FORMS,
  PRODUCT_TYPE_IDS,
  TRACKING_FORMS,
  WEBHOOK_ENDPOINT_FORMS,
} from 'constants/productsShared.constants'
import { LOCALES_LIST } from '@elo-kit/constants/locales.constants'
import { COLORS } from '@elo-kit/constants/general.constants'

import { notify } from 'libs/common/notify'

import { capitalize, snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import { getSearchParams } from 'utils/queryString.utils'

import SharedStore from 'shared/stores/shared.store'
import { ALLOWED_LANGUAGES } from 'constants/general.constants'

import * as api from '../api/products.api'

const initialItem = {
  accessPassword: '',
  accessPeriod: '',
  checkoutHtml: '',
  checkoutHtmlFooter: '',
  emailConfiguration: {},
  customVats: [],
  form: PRODUCT_TYPE_IDS.download,
  forwardParams: '',
  forwardedParams: [],
  free: false,
  klickTippApiKey: '',
  klickTippSyncOld: false,
  landingPageUrl: '',
  mailingWorkTags: [],
  metaDescription: '',
  metaTitle: '',
  optInId: '',
  paymentPlanChangeAllowed: false,
  pricingPlans: [],
  private: false,
  productCovers: [],
  selectedMailingWorkTags: [],
  stampingAllowed: false,
  successUrl: '',
  timezone: DEFAULT_TIMEZONE,
  trackingCodeIds: [],
  trackingForm: TRACKING_FORMS.noneTrackingCodes,
  useUpgradePlans: false,
  vatSettingId: '',
  webhookUrl: '',
  apiSettings: {},
  accessSessionLimit: 0,
  shortDescription: '',
  invoiceShortDescription: false,
  sessionTimerSettings: {
    isSessionTimerActive: false,
    timerTime: '',
    timerText: '',
    timerBackgroundColor: COLORS.eloBlack,
    timerFontColor: COLORS.eloWhite,
    backgroundImageActive: false,
    backgroundImage: {
      coverId: '',
      coverUrl: '',
    },
  },
  socialProofBubbleActivated: false,
}

export class ProductsStore extends SharedStore {
  storeName = 'ProductsStore'
  childApi = api
  @observable productArchiveModalOpen = false
  @observable productToArchiveId = ''
  @observable itemTouched = false
  @observable productDuplicateModalOpen = false
  @observable productNoCategoryModalOpen = false
  @observable duplicatedProductId = ''
  @observable productIdToCheckCategory = ''

  @action prepareNew = (form, category) => {
    this.item = { form, category }
    this.loading = false
  }

  @action setItemTouched = (value) => {
    this.itemTouched = value
  }

  @action changeProduct = (name, value, props = {}) => {
    const { trackItemTouched = true } = props || {}

    let keys = name.split('.')
    keys = keys.reverse()

    let obj = { [keys[0]]: value }

    for (let i = 1; i < keys.length; i++) {
      obj = {
        [keys[i]]: {
          ...this.item[keys[i]],
          ...obj,
          ...this.initialItem,
        },
      }
    }

    this.item = {
      ...this.item,
      ...obj,
    }

    if (trackItemTouched) {
      this.setItemTouched(true)
    }
  }

  @action handleCustomEmailChange = (field, value) => {
    if (!this.item.emailConfiguration.payerConfirmationEmail) {
      this.item.emailConfiguration.payerConfirmationEmail = {}
    }
    this.item.emailConfiguration.payerConfirmationEmail[field] = value
    this.setItemTouched(true)
  }

  @action handleAttendeeEmailChange = (field, value) => {
    if (!this.item.emailConfiguration.ticketAttendeeConfirmationEmail) {
      this.item.emailConfiguration.ticketAttendeeConfirmationEmail = {}
    }
    this.item.emailConfiguration.ticketAttendeeConfirmationEmail[field] = value
    this.setItemTouched(true)
  }

  @action commitCheckoutPageStep = (item) => {
    const {
      additionalFeeId,
      cancellationTermId,
      checkoutHtml,
      checkoutHtmlFooter,
      free,
      id,
      optInId,
      b2bCancellationTermId,
      paymentPageTemplateId,
      paymentPlanChangeAllowed,
      protectionUrl,
      protectionUrlForOrder,
      vatSettingId,
      shortDescription,
      invoiceShortDescription,
      sessionTimerSettings: {
        isSessionTimerActive,
        timerTime,
        timerText,
        timerBackgroundColor,
        timerFontColor,
        backgroundImageActive,
        backgroundImage,
      } = {},
      socialProofBubbleActivated,
    } = this.item

    const data = {
      additionalFeeId,
      cancellationTermId,
      checkoutHtml,
      checkoutHtmlFooter,
      free,
      optInId,
      paymentPageTemplateId,
      paymentPlanChangeAllowed,
      protectionUrl,
      protectionUrlForOrder,
      vatSettingId,
      b2bCancellationTermId,
      pricesAttributes: this.preparePricesForSave,
      shortDescription,
      invoiceShortDescription,
      sessionTimerSettings: {
        isSessionTimerActive,
        timerTime,
        timerText,
        timerBackgroundColor,
        timerFontColor,
        backgroundImageActive,
        backgroundImage,
      },
      socialProofBubbleActivated,
      ...item,
    }

    this.setItemTouched(false)
    return this.updateItem(id, data)
  }

  @action commitCheckoutStep = (item) => {
    const {
      additionalFeeId,
      cancellationTermId,
      checkoutHtml,
      checkoutHtmlFooter,
      id,
      b2bCancellationTermId,
      optInId,
      paymentPageTemplateId,
      protectionUrl,
      protectionUrlForOrder,
      vatSettingId,
      ticketView,
      shortDescription,
      invoiceShortDescription,
      sessionTimerSettings: {
        isSessionTimerActive,
        timerTime,
        timerText,
        timerBackgroundColor,
        timerFontColor,
        backgroundImageActive,
        backgroundImage,
      },
      socialProofBubbleActivated,
    } = this.item

    const data = {
      additionalFeeId,
      cancellationTermId,
      b2bCancellationTermId,
      checkoutHtml,
      checkoutHtmlFooter,
      optInId,
      paymentPageTemplateId,
      protectionUrl,
      protectionUrlForOrder,
      vatSettingId,
      ticketView,
      shortDescription,
      invoiceShortDescription,
      sessionTimerSettings: {
        isSessionTimerActive,
        timerTime,
        timerText,
        timerBackgroundColor,
        timerFontColor,
        backgroundImageActive,
        backgroundImage,
      },
      socialProofBubbleActivated,
      ...item,
    }

    this.setItemTouched(false)
    return this.updateItem(id, data)
  }

  @action commitDetailsPageStep = () => {
    const { id, validForm, validFrom, validTill, keyFormat, keysList, keyForm, recurringDuration } = this.item

    const data = {
      validForm,
      validFrom,
      validTill,
      keyFormat,
      keysList,
      keyForm,
      recurringDuration,
    }

    this.setItemTouched(false)
    return this.updateItem(id, data)
  }

  //TODO: remove after the bundle gone from app
  toolTipForProductType = {
    [PRODUCT_TYPE_IDS.bundle]: I18n.t('react.cabinet.common.bundle_will_gone'),
  }

  getDefaultEmailTemplate = (isCustomEmails) => {
    let defaultEmailConfiguration = {}

    let translationVars = [
      'payer_first_name',
      'payer_last_name',
      'attendee_first_name',
      'attendee_last_name',
      'product_name',
      'event_name',
      'ticket_address',
      'ticket_country',
      'ticket_description',
      'start_date',
      'end_date',
      'timezone',
      'description',
      'attendee_email',
      'payer_email',
      'location_info',
      'ticket_date_info',
      'first_name',
      'last_name',
      'product_name',
      'amount',
      'recurring_type',
      'next_page_link',
    ]

    let interpolationOptions = {}
    //Workaround replace %{foo} to %{foo} to handle the library interpolation
    translationVars.forEach((v) => {
      interpolationOptions[v] = `%{${v}}`
    })

    LOCALES_LIST.forEach((locale) => {
      let langCapitalise = snakeCaseToCamelCase(capitalize(locale))

      if (isCustomEmails) {
        defaultEmailConfiguration[`body${langCapitalise}`] = I18n.t('react.cabinet.custom_emails.body_prefill', {
          locale,
          ...interpolationOptions,
        })

        defaultEmailConfiguration[`subject${langCapitalise}`] = I18n.t('react.cabinet.custom_emails.subject_prefill', {
          locale,
          ...interpolationOptions,
        })
      } else {
        // prettier-ignore
        defaultEmailConfiguration[`body${langCapitalise}`] = I18n.t('react.cabinet.ticket_attendee_confirmation_email.body_prefill', { locale, ...interpolationOptions })
        // prettier-ignore
        defaultEmailConfiguration[`subject${langCapitalise}`] = I18n.t('react.cabinet.ticket_attendee_confirmation_email.subject_prefill', { locale, ...interpolationOptions })
      }
    })

    return defaultEmailConfiguration
  }

  sendTestEmail = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.sendTestAttendeeEmail(id, data)
    this.toggleLoading(false)
    return resp
  }

  @action commitDeliveryPageStep = (commitData) => {
    const {
      additionalInfo,
      forwardParams,
      id,
      successUrl,
      apiSettings,
      forwardedParams,
      klickTippConnectionId,
      emailConfiguration,
    } = this.item

    const editedApiSettings = {
      ...apiSettings,
      klickTippSyncOld: apiSettings && apiSettings.klickTippSyncOld ? 1 : 0,
    }

    const { attendeeEventEmailForm, payerConfirmationEmail, payerEmailForm } = emailConfiguration || {}
    const { show_hidden_cfgs: showHiddenCfgs } = getSearchParams()
    const langAttributes = {}
    Object.values(ALLOWED_LANGUAGES).map((lang) => {
      langAttributes[`body${capitalize(lang.value)}`] = payerConfirmationEmail[`body${capitalize(lang.value)}`]
      langAttributes[`subject${capitalize(lang.value)}`] = payerConfirmationEmail[`subject${capitalize(lang.value)}`]
    })
    const emailAttributes = showHiddenCfgs ? payerConfirmationEmail : langAttributes

    const emailConfigurationAttributes = {
      id: emailConfiguration.id,
      attendeeEventEmailForm: attendeeEventEmailForm,
      ...(attendeeEventEmailForm === EMAIL_FORMS.customEmail
        ? {
            ticketAttendeeConfirmationEmailAttributes: {
              ...emailConfiguration.ticketAttendeeConfirmationEmail,
              receiverType: 'ticket_attendee',
            },
          }
        : {}),

      payerEmailForm: payerEmailForm,
      ...(payerEmailForm === EMAIL_FORMS.customEmail
        ? {
            payerConfirmationEmailAttributes: {
              ...emailAttributes,
              receiverType: 'payer',
            },
          }
        : {}),
    }

    const data = {
      additionalInfo,
      forwardParams,
      forwardedParams,
      successUrl,
      apiSettings: editedApiSettings,
      klickTippConnectionId,
      emailConfigurationAttributes,
      ...commitData,
    }

    this.setItemTouched(false)
    return this.updateItem(id, data)
  }

  @action commitAdvancedStep = (commitData) => {
    const {
      accessPassword,
      accessPeriod,
      affiliateProgramId,
      id,
      landingPageUrl,
      membershipThemeId,
      metaDescription,
      metaTitle,
      performancePeriod,
      performancePeriodText,
      performancePeriodType,
      private: isPrivate,
      stampingAllowed,
      trackingCodeIds,
      trackingForm,
      webhookEndpointForm,
      webhookEndpointIds,
      accessSessionLimit,
      internalName,
      costCenter,
    } = this.item
    const data = {
      accessPassword,
      accessPeriod,
      affiliateProgramId,
      landingPageUrl,
      membershipThemeId,
      performancePeriod,
      performancePeriodText,
      performancePeriodType,
      stampingAllowed,
      trackingForm,
      webhookEndpointForm,
      internalName,
      costCenter,
      private: isPrivate,
      metaTitle: isPrivate ? '' : metaTitle,
      metaDescription: isPrivate ? '' : metaDescription,
      webhookEndpointIds:
        webhookEndpointForm === WEBHOOK_ENDPOINT_FORMS.selectedWebhookEndpoints ? toJS(webhookEndpointIds) : [],
      trackingCodeIds: trackingForm === TRACKING_FORMS.selectedTrackingCodes ? toJS(trackingCodeIds) : [],
      accessSessionLimit,
      ...commitData,
    }

    this.setItemTouched(false)
    return this.updateItem(id, data)
  }

  @action commitLicenseKeysStep = () => {
    const { id, keyForm, keyFormat, keysList } = this.item

    const data = {
      keyForm,
      keyFormat,
      keysList,
    }

    this.setItemTouched(false)
    return this.updateItem(id, data)
  }

  @computed get preparePricesForSave() {
    return this.item.pricingPlans.reduce((plansForParams, plan, index) => {
      const { _destroy, id, position, priceId, recommended } = plan
      plansForParams[index] = JSON.parse(
        JSON.stringify({
          _destroy,
          position,
          id: priceId,
          pricingPlanId: id,
          recommended,
        })
      )
      return plansForParams
    }, {})
  }

  @action selectRecommendedPricingPlan = (planId) => {
    const pricingPlans = this.item.pricingPlans.map((val) => ({
      ...val,
      recommended: val.recommended ? false : val.id === planId,
    }))
    this.item = {
      ...this.item,
      pricingPlans: pricingPlans,
    }
  }

  @action productArchiveModalToggle = () => {
    this.productArchiveModalOpen = !this.productArchiveModalOpen
  }

  @action setProductToArchiveId = (id) => {
    this.productToArchiveId = id
  }

  @action productDuplicateModalToggle = () => {
    this.productDuplicateModalOpen = !this.productDuplicateModalOpen
  }

  @action productNoCategoryModalToggle = () => {
    this.productNoCategoryModalOpen = !this.productNoCategoryModalOpen
  }

  @action setDuplicatedProductId = (id) => {
    this.duplicatedProductId = id
  }

  @action setProductIdToCheckCategory = (id) => {
    this.productIdToCheckCategory = id
  }

  sendProductsToReview = async (data, multiple = true) => {
    this.toggleLoading(true)

    const resp = await api.sendProductsToReview(data)
    multiple ? await this.fetchList() : await this.fetchItem(this.item.id)

    this.toggleLoading(false)
    return resp
  }

  checkCategory = (id, categoryKey = null) => {
    if (!categoryKey) {
      this.setProductIdToCheckCategory(id)
      this.productNoCategoryModalToggle()
    }

    return !!categoryKey
  }

  duplicateProduct = flow(function* ({ id, categoryKey = null, duplicatedFromList = true }) {
    if (!this.checkCategory(id, categoryKey)) {
      return
    }

    this.loading = true
    const resp = yield api.duplicateItem(id)

    if (resp.success) {
      if (duplicatedFromList) {
        this.fetchList().then(() => notify('success', I18n.t('react.cabinet.notific.duplicated')))
      } else {
        this.fetchList().then(() => {
          this.setDuplicatedProductId(resp.data.id)
          this.productDuplicateModalToggle()
        })
      }
    } else {
      this.loading = false
    }
  })

  @action setProp = (propName, value) => {
    this[propName] = value
  }

  addProductUpsell = (id, data) => this.childApi.addProductUpsell(id, data)

  removeProductUpsell = (id, data) => this.childApi.removeProductUpsell(id, data)

  updateProductFunnel = (id, data) => this.childApi.updateProductFunnel(id, data)

  updateProductUpsell = (id, data) => this.childApi.updateProductUpsell(id, data)

  duplicateByToken = (token, data) => this.childApi.duplicateByToken(token, data)

  fetchBubble = (id) => this.childApi.fetchBubble(id)

  createBubble = (data) => this.childApi.createBubble(data)

  updateBubble = (id, data) => this.childApi.updateBubble(id, data)

  constructor() {
    super()

    makeObservable(this)

    this.item = initialItem
  }
}

export default new ProductsStore()
