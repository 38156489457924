import React from 'react'

interface Props {
  name?: string
  value: string
  classes: Record<string, string>
}

const MesageBoxSection: React.FC<Props> = ({ name, value, classes }) => (
  <div className={classes.messageBoxSection}>
    <div>{name}</div>
    <div dangerouslySetInnerHTML={{ __html: value }} />
  </div>
)

export default MesageBoxSection
