import { createUseStyles } from 'react-jss'

import { getPadding } from '@elo-kit/utils/contentBlock.utils'
import { TEXT, DEFAULT_TEXT_PADDING } from '@elo-kit/constants/contentPage.constants'
import { COLORS, POSITIONS, ZERO_DATA, PADDING_UNITS } from '@elo-kit/constants/general.constants'

export const useTextStyles = createUseStyles((theme) => {
  const defaultPadding = theme.paddingUnits === PADDING_UNITS.px ? DEFAULT_TEXT_PADDING : ZERO_DATA

  return {
    textContainer: {
      position: 'relative',
      paddingTop: getPadding(theme[TEXT.paddingTop], defaultPadding, theme.paddingUnits),
      paddingBottom: getPadding(theme[TEXT.paddingBottom], defaultPadding, theme.paddingUnits),
      paddingRight: getPadding(theme[TEXT.paddingRight], defaultPadding, theme.paddingUnits),
      paddingLeft: getPadding(theme[TEXT.paddingLeft], defaultPadding, theme.paddingUnits),
      height: '100%',
      width: `${theme.width || 100}%`,
      maxWidth: theme.maxWidth ? `${theme.maxWidth}px` : 'none',
      margin: '0 auto',
      wordBreak: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      '& span': {
        wordBreak: 'break-word',
      },
    },
    container: {
      height: '100%',
      maxWidth: '100%',
      position: 'relative',
    },
    noTextContainer: {
      minHeight: '300px',
      width: '100%',
      height: '100%',
      background: COLORS.eloGrey20,
      display: 'flex',
      justifyContent: POSITIONS.center,
      alignItems: POSITIONS.center,
      '& i': {
        fontSize: '100px',
        color: COLORS.eloWhite,
        width: '150px',
        display: 'flex',
        justifyContent: POSITIONS.center,
      },
    },
  }
})
