import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'

export default () => [
  {
    label: I18n.t('react.shared.audio_file'),
    fields: ['uploadAudioFiles'],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.audioView,
    label: I18n.t('react.shared.audio_view'),
    fields: ['audioView'],
  },
  {
    label: I18n.t('react.shared.background'),
    fields: [['backgroundColor', 'transparency']],
  },
  {
    label: I18n.t('react.shared.player_background'),
    fields: [
      [
        {
          field: 'color',
          key: 'playerBackgroundColor',
        },
      ],
      'cornerRadius',
      'shadow',
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.color,
    label: I18n.t('react.shared.color'),
    fields: [['buttonColor'], 'progressColor'],
  },
  {
    dataMenuHighlight: true,
    label: I18n.t('react.shared.text'),
    fields: [
      {
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.mainText,
        label: I18n.t('react.cabinet.main_text'),
        tooltipId: 'main_text',
        tooltipTitle: true,
        fields: [
          [
            {
              field: 'font',
              key: 'mainFont',
            },
            {
              field: 'fontStyle',
              key: 'mainStyle',
            },
          ],
          [
            {
              field: 'color',
              key: 'mainColor',
            },
            {
              field: 'fontSize',
              key: 'mainSize',
            },
          ],
          {
            field: 'addText',
            key: 'titleText',
          },
        ],
      },
      {
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.additionalText,
        label: I18n.t('react.cabinet.additional_text'),
        tooltipId: 'additional_text',
        tooltipTitle: true,
        fields: [
          [
            {
              field: 'font',
              key: 'additionalFont',
            },
            {
              field: 'fontStyle',
              key: 'additionalStyle',
            },
          ],
          [
            {
              field: 'color',
              key: 'additionalColor',
            },
            {
              field: 'fontSize',
              key: 'additionalSize',
            },
          ],
          {
            field: 'addText',
            key: 'additionalText',
          },
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.image,
    label: I18n.t('react.common.image'),
    fields: ['uploadBackgroundImage'],
  },
  {
    label: I18n.t('react.shared.width'),
    fields: [
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.alignment'),
    fields: [
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
