import { hasWindow } from '@elo-kit/utils/browser.utils'

interface Controls {
  // eslint-disable-next-line
  buttons: NodeListOf<HTMLElement>
  // eslint-disable-next-line
  progressBars: NodeListOf<HTMLElement>
  // eslint-disable-next-line
  durations: NodeListOf<HTMLElement>
  // eslint-disable-next-line
  rewindButtons: NodeListOf<HTMLElement>
}

interface AudioParams {
  status: 'play' | 'pause'
  source: HTMLAudioElement | null
  style?: string
  durationFull?: string
  durationDemo?: string
  speed?: string | number
  currentProgressColor?: string
  progressColor?: string
  srcDemo?: string
  srcFull?: string
  paramName?: string
  paramUrl?: string
  id?: string
  audioBlockId?: string
}

export class AudioService {
  private controls: Controls
  // eslint-disable-next-line
  private intervals: NodeJS.Timeout[] = []
  public readonly audio: AudioParams

  constructor(private root: HTMLElement) {
    if (this.root) {
      this.root.classList.add('inited')

      const buttons = this.root.querySelectorAll<HTMLElement>('.audio-button')
      const progressBars = this.root.querySelectorAll<HTMLElement>('.progressbar')
      const durations = this.root.querySelectorAll<HTMLElement>('.duration')
      const rewindButtons = this.root.querySelectorAll<HTMLElement>('.rewind-button')

      this.controls = { buttons, progressBars, durations, rewindButtons }

      const source = this.root.querySelector('audio')
      this.audio = {
        ...this.root.dataset,
        status: 'pause',
        source,
      }

      this.audio.source?.load()
      this.controls.buttons.forEach((btn) => {
        btn.classList.add('pause')
        btn.classList.remove('play')
      })
      this.selectSrc()
      this.initDuration()
      this.bindEvents()
      this.updateDrawing()
    }
  }

  setAudioSpeed(speed: string | number) {
    this.audio.speed = speed
  }

  setAudioStatus(status: 'play' | 'pause') {
    this.audio.status = status
  }

  setProgressColor(color: string) {
    this.audio.progressColor = color
  }

  setCurrentProgressColor(color: string) {
    this.audio.currentProgressColor = color
  }

  play() {
    this.stopPlayedNow()
    this.audio.source?.play()
    this.audio.status = 'play'
    this.controls.buttons.forEach((btn) => {
      btn.classList.add('play', 'elo-play')
      btn.classList.remove('pause', 'elo-pause')
    })
    this.controls.progressBars.forEach((pb) => {
      pb.classList.remove('hidden', 'd-none')
    })
    this.showProgress()
  }

  pause() {
    this.audio.source?.pause()
    this.audio.status = 'pause'
    this.showProgress()
    this.intervals.forEach((i) => clearInterval(i))
    this.intervals = []
    this.controls.buttons.forEach((btn) => {
      btn.classList.add('pause', 'elo-pause')
      btn.classList.remove('play', 'elo-play')
    })
  }

  isPaused() {
    return this.audio.status === 'pause'
  }

  isPlaying() {
    return this.audio.status === 'play'
  }

  private updateDrawing() {
    if (this.audio.source) {
      this.showDuration(this.audio.source.currentTime)
      this.drawProgress()
      this.audio.source.playbackRate = parseFloat(String(this.audio.speed || 1.0))
    }
  }

  private selectSrc(): void {
    if (this.audio && this.audio.style && this.audio.srcDemo) {
      const key = this.audio.style.charAt(0).toUpperCase() + this.audio.style.slice(1)
      return this.audio.source?.setAttribute('src', this.audio[`src${key}`])
    }
  }

  private initDuration(): void {
    if (this.audio && this.audio.style) {
      const key = this.audio.style?.charAt(0).toUpperCase() + this.audio.style.slice(1)
      return this.showDuration(this.audio[`duration${key}`])
    }
  }

  private showDuration(time: number): void {
    if (this.controls.durations) {
      return this.controls.durations.forEach((dur) => (dur.innerText = this.formatTime(time)))
    }
  }

  private formatTime(time: number): string {
    const minutes = Math.floor(time / 60)
    const formattedMinutes = minutes >= 10 ? minutes : '0' + minutes
    const seconds = Math.floor(time % 60)
    const formattedSeconds = seconds >= 10 ? seconds : '0' + seconds
    return `${formattedMinutes}:${formattedSeconds}`
  }

  private drawProgress() {
    const currentProgressColor = this.audio.currentProgressColor || '#DDE8EB'
    const bgColor = this.audio.progressColor || '#FCFCFC'
    const percent = this.audio.source
      ? (this.audio.source.currentTime * 100) / (this.audio.source.duration || parseInt(this.audio.durationFull || '0'))
      : 0
    return this.controls.progressBars.forEach((pb) => {
      pb.style.background = `linear-gradient(90deg, ${currentProgressColor} ${percent}%, ${bgColor} 0)`
    })
  }

  private stopPlayedNow() {
    audioPlayer.getPlayers().forEach((player) => player.pause())
  }

  private showProgress() {
    const intervalId = setInterval(() => this.updateDrawing(), 100)
    this.intervals.push(intervalId)
  }

  private bindEvents() {
    this.controls.buttons.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.stopPropagation()
        if (this.isPaused()) {
          return this.play()
        }
        return this.pause()
      })
    })

    this.controls.progressBars.forEach((pb) => {
      pb.addEventListener('click', (e) => {
        const event = e as PointerEvent
        const currentTarget = event.currentTarget as HTMLElement
        if (this.isPlaying() || this.isPaused()) {
          const x = event.offsetX
          if (!x) return

          if (this.audio.source) {
            this.audio.source.currentTime =
              ((this.audio.source?.duration || parseInt(this.audio.durationFull || '0')) / 100) *
              ((x * 100) / currentTarget.offsetWidth)
            this.drawProgress()
            return this.showDuration(this.audio.source.currentTime)
          }
        }
      })
    })

    this.controls.rewindButtons.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        const REWIND_SECONDS = 15
        const event = e as PointerEvent
        const currentTarget = event.currentTarget as HTMLElement
        if (this.isPlaying() || this.isPaused()) {
          const { forward, back } = currentTarget.dataset
          if (this.audio.source) {
            if (forward) {
              this.audio.source.currentTime += REWIND_SECONDS
            }
            if (back) {
              this.audio.source.currentTime -= REWIND_SECONDS
            }
            this.drawProgress()
            return this.showDuration(this.audio.source.currentTime)
          }
        }
      })
    })

    if (this.audio.source) {
      return this.audio.source.addEventListener('ended', () => this.pause())
    }
  }
}

class AudioPlayer {
  private audioPlayers: AudioService[] = []
  private static instance: AudioPlayer

  public static getInstance() {
    if (!AudioPlayer.instance) {
      AudioPlayer.instance = new AudioPlayer()
    }
    return AudioPlayer.instance
  }

  init(el: HTMLElement) {
    if (!hasWindow || !el) return

    const currentId = el.dataset.id
    const isInited = this.audioPlayers.some((p) => p.audio.id === currentId)

    if (isInited) return

    this.setPlayer(new AudioService(el))
  }

  getPlayers() {
    return this.audioPlayers
  }

  getPlayerById(id: string) {
    return this.audioPlayers.find((player) => player.audio.id === id)
  }

  getPlayerByStatus(status: string) {
    return this.audioPlayers.find((player) => player.audio.status === status)
  }

  setPlayer(player: AudioService) {
    this.audioPlayers.push(player)
  }

  removePlayer(id: string) {
    this.audioPlayers = this.audioPlayers.filter((player) => player.audio.id !== id)
  }

  clearPlayers() {
    this.audioPlayers = []
  }
}

export const audioPlayer = AudioPlayer.getInstance()
