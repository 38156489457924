import React, { Component } from 'react'
import { toJS } from 'mobx'
import { Banner } from '@elo-kit/components/page-builder/blocks/banner/Banner'

class BannerPreview extends Component {
  shouldComponentUpdate() {
    const { isDragging } = this.props

    if (isDragging) {
      return false
    }

    return true
  }

  render() {
    const { block, previewMode } = this.props

    return <Banner block={block} previewMode={previewMode} toJS={toJS} />
  }
}

BannerPreview.defaultProps = {
  previewMode: false,
}

export default BannerPreview
