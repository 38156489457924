import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { NO, YES } from '@elo-kit/constants/general.constants'
import { BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { PRODUCT_SELECTION_TYPES } from 'constants/productsShared.constants'

export default () => [
  {
    label: I18n.t('react.cabinet.common.type'),
    tooltipId: 'funnel_type',
    fields: ['funnelType'],
  },
  {
    label: I18n.t('react.shared.view'),
    tooltipId: 'block_view',
    show: {
      key: 'type',
      value: [YES, NO],
    },
    fields: ['funnelView'],
  },
  {
    label: I18n.t('react.shared.product'),
    tooltipId: 'block_add_product',
    show: {
      key: 'type',
      value: [YES, BLOCK_VIEW.text],
    },
    fields: [
      {
        field: 'selectProduct',
        props: {
          selectionType: PRODUCT_SELECTION_TYPES.ticketDates,
        },
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.common.pricingPlan'),
    tooltipId: 'block_selected_products',
    show: {
      key: 'type',
      value: [YES, BLOCK_VIEW.text],
    },
    fields: ['selectPricingPlan'],
  },
  {
    label: I18n.t('react.shared.background'),
    tooltipId: 'block_background_settings',
    fields: [
      'uploadBackgroundImage',
      ['backgroundColor', 'transparency'],
      {
        field: 'pixels',
        label: I18n.t('react.shared.min_height'),
        key: 'minHeight',
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.funnel.button,
    label: I18n.t('shared.common.style'),
    tooltipId: 'block_button_style',
    show: {
      key: 'view',
      value: BLOCK_VIEW.button,
    },
    fields: [
      'buttonColor',
      'buttonViewStyle',
      'cornerRadius',
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      'buttonAnimation',
      {
        field: 'pixels',
        label: I18n.t('react.shared.width'),
        key: 'buttonWidth',
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.funnel.button,
    label: I18n.t('react.cabinet.text'),
    tooltipId: 'block_button_text',
    show: {
      key: 'view',
      value: BLOCK_VIEW.button,
    },
    fields: [
      {
        field: 'text',
        key: 'buttonText',
      },
      ['buttonFont', 'buttonStyle'],
      ['buttonTextColor', 'buttonSize'],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.funnel.customCta,
    label: I18n.t('react.shared.custom_cta'),
    tooltipId: 'custom_cta',
    show: {
      key: 'type',
      value: BLOCK_VIEW.text,
    },
    fields: [
      'funnelFroalaText',
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.text_style'),
    tooltipId: 'custom_cta',
    show: {
      key: 'view',
      value: BLOCK_VIEW.text,
    },
    fields: [
      'froalaText',
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.common.button_style'),
    tooltipId: 'block_button_style',
    fields: [
      'buttonColor',
      'buttonViewStyle',
      'cornerRadius',
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      'buttonAnimation',
      {
        field: 'pixels',
        label: I18n.t('react.shared.width'),
        key: 'buttonWidth',
      },
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    show: {
      key: 'view',
      value: BLOCK_VIEW.text,
    },
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
]
