import React from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import { get } from 'utils/lodash.utils'

import { EloProductDisplay } from '@elo-ui/components/elo-product-display'
import { parseTextFromHtml } from '@elo-kit/utils/froala.utils'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import funnelProductStyles from 'shared/components/content-page/preview/blocks/funnel_product/FunnelProduct.styles'

import { getPricingPlanFormLocale } from 'constants/pricingPlans.constants'

const FunnelProductBlock = ({ content, classes, cover = {} }) => {
  const { product } = content
  const { name, coverUrl, description, pricingPlanPrice, pricingPlanForm } = product || {}

  const backgroundSrc = get(cover, 'file.custom', '') || get(cover, 'file.original', '') || ''
  const price = content.pricingPlan ? pricingPlanPrice : ''
  const priceText = content.pricingPlan ? getPricingPlanFormLocale()[pricingPlanForm] : ''
  const productDescription = content.productDescription ? parseTextFromHtml(description || '') : ''

  return (
    <div>
      <div className={classes.productContainer} style={{ backgroundImage: `url(${backgroundSrc})` }}>
        <EloProductDisplay
          size='extra-large'
          name={name}
          price={price}
          image={coverUrl}
          supportivePriceText={priceText}
          supportiveText={productDescription}
          hideImage={!content.productImage}
        />
      </div>
      <div className={classes.productBackground} />
    </div>
  )
}

const StyledFunnelProductBlock = withStyles(funnelProductStyles)(FunnelProductBlock)

export const FunnelProduct = (props) => {
  const {
    block: { content, cover },
  } = props
  const I18n = useI18n()

  return (
    <ThemeProvider theme={content}>
      <StyledFunnelProductBlock content={content} cover={cover} I18n={I18n} {...props} />
    </ThemeProvider>
  )
}

export default FunnelProduct
