import React, { useRef } from 'react'
import classNames from 'classnames'

import { useOverflowed } from '@elo-ui/hooks/use-overflowed'
import { EloAvatar } from '@elo-ui/components/elo-avatar'
import { EloTooltip, EloTooltipContent, EloTooltipTitle } from '@elo-ui/components/elo-tooltip'

import './elo-user-display.scss'

type Size = 'large' | 'medium'

interface Props {
  username: string
  role?: string
  email?: string
  avatarUrl?: string
  size?: Size
  withAvatar?: boolean
  className?: string
  tooltipPosition?: 'right' | 'top' | 'left' | 'bottom'
  isClickable?: boolean
}

const Username = ({ username, nameRef }) => (
  <div className='elo-user-display__username' ref={nameRef}>
    {username}
  </div>
)

const Description = ({ description, descriptionRef }) => (
  <div className='elo-user-display__description' ref={descriptionRef}>
    {description}
  </div>
)

const Display = ({ withAvatar, size, avatarUrl, nameRef, username, description, descriptionRef }) => (
  <>
    {withAvatar && <EloAvatar shape='circle' size={size} src={avatarUrl} username={username} />}
    <div className='elo-user-display__block'>
      <Username nameRef={nameRef} username={username} />
      <Description description={description} descriptionRef={descriptionRef} />
    </div>
  </>
)
export const EloUserDisplay: React.FC<Props> = ({
  username,
  role,
  email,
  avatarUrl,
  size = 'large',
  withAvatar = true,
  className,
  tooltipPosition = 'right',
  isClickable = false,
}) => {
  const classes = classNames('elo-user-display', className, { 'elo-user-display--clickable': isClickable })
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const { isOverflowed: isNameOverflowed } = useOverflowed(nameRef)
  const { isOverflowed: isDescriptionOverflowed } = useOverflowed(descriptionRef)
  const description = (
    <>
      {role && role}
      {email && role && <>&nbsp;-&nbsp;</>}
      {email && email}
    </>
  )

  return (
    <div className={classes}>
      {isNameOverflowed || isDescriptionOverflowed ? (
        <EloTooltip
          wrapperClassName='elo-user-display__tooltip-wrapper'
          placement={tooltipPosition}
          body={
            <>
              <EloTooltipTitle title={'username'} />
              <EloTooltipContent content={username} />
              {email && (
                <>
                  <EloTooltipTitle title={'email'} />
                  <EloTooltipContent content={email} />
                </>
              )}
              {role && (
                <>
                  <EloTooltipTitle title={'role'} />
                  <EloTooltipContent content={role} />
                </>
              )}
            </>
          }
        >
          <Display
            withAvatar={withAvatar}
            size={size}
            avatarUrl={avatarUrl}
            nameRef={nameRef}
            username={username}
            description={description}
            descriptionRef={descriptionRef}
          />
        </EloTooltip>
      ) : (
        <Display
          withAvatar={withAvatar}
          size={size}
          avatarUrl={avatarUrl}
          nameRef={nameRef}
          username={username}
          description={description}
          descriptionRef={descriptionRef}
        />
      )}
    </div>
  )
}
