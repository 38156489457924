import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { PDF_FILE } from 'constants/contentPageShared.constants'
import { BUILDER_FIELD_TYPES } from '../../../../page-builder/constants/pageBuilder.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.cabinet.pdf.file'),
    tooltipId: 'block_files_list',
    fields: [BUILDER_FIELD_TYPES.uploadPdfFiles],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.filePdf.downloadButtonPdf,
    label: I18n.t('react.cabinet.pdf.download_button'),
    tooltipId: 'download_button_pdf',
    fields: [BUILDER_FIELD_TYPES.downloadPdfFiles],
  },
  {
    label: I18n.t('react.cabinet.background'),
    tooltipId: 'block_background_settings',
    fields: ['uploadBackgroundImage', [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency']],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.filePdf.blockButtonStyle,
    label: I18n.t('react.cabinet.pdf.buttons'),
    tooltipId: 'block_button_style',
    fields: [
      {
        field: BLOCKS_WITH_COLOR_PICKER.buttonColor,
        key: BLOCKS_WITH_COLOR_PICKER.buttonColor,
      },
      {
        field: 'buttonSize',
        key: PDF_FILE.toolbarButtonSize,
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.filePdf.pdfToolbar,
    label: I18n.t('react.cabinet.pdf.toolbar'),
    tooltipId: 'pdf_toolbar',
    fields: [
      {
        field: BLOCKS_WITH_COLOR_PICKER.backgroundColor,
        key: PDF_FILE.toolbarBackgroundColor,
        label: I18n.t('react.shared.background_color'),
      },
      [
        {
          field: 'font',
          key: PDF_FILE.toolbarFont,
        },
        {
          field: 'fontStyle',
          key: PDF_FILE.toolbarFontStyle,
        },
      ],
      [
        {
          field: 'color',
          key: PDF_FILE.toolbarColor,
        },
        {
          field: 'fontSize',
          key: PDF_FILE.toolbarFontSize,
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.filePdf.pdfProgressBar,
    label: I18n.t('react.cabinet.pdf.progress_bar'),
    tooltipId: 'pdf_progress_bar',
    fields: [
      {
        field: BLOCKS_WITH_COLOR_PICKER.backgroundColor,
        key: PDF_FILE.progressBackgroundColor,
        label: I18n.t('react.shared.color'),
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
