import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.background'),
    fields: [BLOCKS_WITH_COLOR_PICKER.backgroundColor],
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.background,
  },
  {
    label: I18n.t('react.shared.width'),
    fields: [
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.text'),
    dataMenuHighlight: true,
    fields: [
      {
        label: I18n.t('react.shared.thank_you_text'),
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.header,
        fields: [
          {
            field: 'text',
            key: 'headerText',
          },
          [
            {
              field: 'font',
              key: 'headerFont',
            },
            {
              field: 'fontStyle',
              key: 'headerStyle',
            },
          ],
          {
            field: 'color',
            key: 'headerColor',
          },
        ],
      },
      {
        label: I18n.t('react.shared.product_name'),
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.title,
        fields: [
          [
            {
              field: 'font',
              key: 'productNameFont',
            },
            {
              field: 'fontStyle',
              key: 'productNameStyle',
            },
          ],
          {
            field: 'color',
            key: 'productNameColor',
          },
        ],
      },
      {
        label: I18n.t('react.shared.labels'),
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.labels,
        fields: [
          [
            {
              field: 'font',
              key: 'labelsFont',
            },
            {
              field: 'fontStyle',
              key: 'labelsStyle',
            },
          ],
          {
            field: 'color',
            key: 'labelsColor',
          },
        ],
      },
      {
        label: I18n.t('react.shared.details'),
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.details,
        fields: [
          [
            {
              field: 'font',
              key: 'additionalTextFont',
            },
            {
              field: 'fontStyle',
              key: 'additionalTextStyle',
            },
          ],
          {
            field: 'color',
            key: 'additionalTextColor',
          },
        ],
      },
      {
        label: I18n.t('react.cabinet.common.price'),
        dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.price,
        fields: [
          [
            {
              field: 'font',
              key: 'priceFont',
            },
            {
              field: 'fontStyle',
              key: 'priceStyle',
            },
          ],
          {
            field: 'color',
            key: 'priceColor',
          },
        ],
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.mainButton,
    label: I18n.t('react.shared.main_button'),
    fields: [
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      ['buttonFont', 'buttonStyle'],
      'buttonViewStyle',
      BLOCKS_WITH_COLOR_PICKER.buttonTextColor,
      'cornerRadius',
      'buttonAnimation',
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.secondaryButton,
    label: I18n.t('react.shared.secondary_button'),
    fields: [
      {
        field: BLOCKS_WITH_COLOR_PICKER.buttonColor,
        key: 'secondaryButtonColor',
      },
      [
        {
          field: 'buttonFont',
          key: 'secondaryButtonFont',
        },
        {
          field: 'buttonStyle',
          key: 'secondaryButtonStyle',
        },
      ],
      {
        field: 'cornerRadius',
        key: 'secondaryCornerRadius',
      },
    ],
  },
]
