import React from 'react'
import classNames from 'classnames'

import { BAR_STYLE_TYPES } from 'shared/components/page-builder/constants/block.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

const CircleWrapper = ({ className, children, classes, previewMode }) => (
  <div
    className={classNames(classes.circle, className)}
    {...(previewMode && {
      'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.progressBarStyles,
      'data-highlighter-selector': '',
    })}
  >
    {children}
  </div>
)

const CircleProgress = (props) => {
  const {
    block: {
      content: { progressBarStyle, progressBarValue },
    },
  } = props

  switch (progressBarStyle) {
    case BAR_STYLE_TYPES.circle: {
      return <CircleProgress.Circle {...{ ...props, progressBarValue }} />
    }

    case BAR_STYLE_TYPES.filledCircle: {
      return <CircleProgress.Filled {...{ ...props, progressBarValue }} />
    }

    case BAR_STYLE_TYPES.halfCircle: {
      return <CircleProgress.HalfCircle {...{ ...props, progressBarValue }} />
    }

    case BAR_STYLE_TYPES.pieCircle: {
      return <CircleProgress.Pie {...{ ...props, progressBarValue }} />
    }

    default: {
      return null
    }
  }
}

CircleProgress.Circle = (props) => {
  const { classes, progressBarValue } = props
  return (
    <CircleWrapper {...props}>
      <div className={classes.circlesContainer}>
        <div className={classes.leftCircle} />
        <div className={classes.rightCircle} />
      </div>
      <div className={classes.circleShadow} />
      <div className={classes.percentageNumber}>{`${progressBarValue}%`}</div>
    </CircleWrapper>
  )
}

CircleProgress.Filled = (props) => {
  const { classes, progressBarValue } = props
  return (
    <CircleWrapper {...props}>
      <div className={classes.circleBackground} />
      <div className={classes.circlesContainer}>
        <div className={classes.leftCircle} />
        <div className={classes.rightCircle} />
      </div>
      <div className={classes.percentageNumber}>{`${progressBarValue}%`}</div>
    </CircleWrapper>
  )
}

CircleProgress.Pie = (props) => {
  const { classes, progressBarValue } = props
  return (
    <CircleWrapper {...props} className={classes.pie}>
      <div className={classes.percentageNumber}>{`${progressBarValue}%`}</div>
    </CircleWrapper>
  )
}

CircleProgress.HalfCircle = (props) => {
  const { classes, progressBarValue } = props
  return (
    <CircleWrapper {...props}>
      <div className={classes.halfCircle} />
      <div className={classes.percentageNumber}>{`${progressBarValue}%`}</div>
    </CircleWrapper>
  )
}

export default CircleProgress
