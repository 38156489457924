import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/payment_page_templates'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const fetchNew = (data) => GET(`${BASE_URL}/new`, data)

export const duplicateByToken = (token, data) => POST(`${BASE_URL}/${token}/duplicate_by_token`, data)
