import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.form_name'),
    fields: [
      {
        field: 'text',
        key: 'formName',
        label: I18n.t('react.shared.name'),
      },
    ],
  },
  {
    label: I18n.t('react.shared.form_input_fields'),
    fields: ['selectInputFields'],
  },
  {
    label: I18n.t('react.shared.form_contact_styles'),
    fields: [
      [
        {
          field: 'color',
          key: 'formColor',
        },
        {
          field: 'transparency',
          key: 'formTransparency',
        },
      ],
      [
        {
          field: 'color',
          key: 'inputsBorderColor',
          label: I18n.t('react.shared.inputs_border_color'),
        },
        {
          field: 'fontSize',
          key: 'inputsHeight',
          label: I18n.t('react.shared.inputs_height'),
        },
      ],
      [{ field: 'cornerRadius', key: 'formRadius' }, 'shadow'],
    ],
  },
  {
    label: I18n.t('react.shared.background'),
    tooltipId: 'block_background_settings',
    fields: [
      'uploadBackgroundImage',
      [BLOCKS_WITH_COLOR_PICKER.backgroundColor, { field: 'transparency', key: 'backgroundTransparency' }],
    ],
  },
  {
    dataMenuHighlight: true,
    label: I18n.t('react.cabinet.text'),
    fields: [
      {
        dataMenuHighlight: 'contact-form-title',
        label: I18n.t('react.shared.contact_form_title'),
        fields: [
          [
            {
              field: 'font',
              key: 'mainFont',
            },
            {
              field: 'fontStyle',
              key: 'mainStyle',
            },
          ],
          [
            {
              field: 'color',
              key: 'mainColor',
            },
            {
              field: 'fontSize',
              key: 'mainSize',
            },
          ],
          {
            field: 'addText',
            key: 'titleText',
          },
        ],
      },
      {
        dataMenuHighlight: 'contact-form-description',
        label: I18n.t('react.cabinet.description'),
        fields: [
          [
            {
              field: 'font',
              key: 'descriptionFont',
            },
            {
              field: 'fontStyle',
              key: 'descriptionStyle',
            },
          ],
          [
            {
              field: 'color',
              key: 'descriptionColor',
            },
            {
              field: 'fontSize',
              key: 'descriptionSize',
            },
          ],
          {
            field: 'addText',
            key: 'descriptionText',
          },
        ],
      },
      {
        dataMenuHighlight: 'contact-form-labels',
        label: I18n.t('react.cabinet.embeddable_items.labels'),
        fields: [
          [
            {
              field: 'font',
              key: 'labelsFont',
            },
            {
              field: 'fontStyle',
              key: 'labelsStyle',
            },
          ],
          [
            {
              field: 'color',
              key: 'labelsColor',
            },
            {
              field: 'fontSize',
              key: 'labelsSize',
            },
          ],
        ],
      },
      {
        dataMenuHighlight: 'contact-form-placeholders',
        label: I18n.t('react.shared.form_placeholders'),
        fields: [
          [
            {
              field: 'font',
              key: 'placeholdersFont',
            },
            {
              field: 'fontStyle',
              key: 'placeholdersStyle',
            },
          ],
          [
            {
              field: 'color',
              key: 'placeholdersColor',
            },
            {
              field: 'fontSize',
              key: 'placeholdersSize',
            },
          ],
        ],
      },
    ],
  },
  {
    dataMenuHighlight: 'contact-form-button',
    label: I18n.t('react.shared.button.title'),
    tooltipId: 'block_button_style',
    fields: [
      BLOCKS_WITH_COLOR_PICKER.buttonColor,
      {
        field: 'text',
        key: 'buttonText',
      },
      ['buttonFont', 'buttonStyle'],
      [BLOCKS_WITH_COLOR_PICKER.buttonTextColor, 'buttonSize'],
      'buttonViewStyle',
      { field: 'cornerRadius', key: 'buttonRadius' },
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      {
        field: 'pixels',
        label: I18n.t('react.shared.width'),
        key: 'buttonWidth',
      },
      'buttonAnimation',
    ],
  },
  {
    label: I18n.t('react.shared.width'),
    fields: [
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
