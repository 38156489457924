import { BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { BLOCKS_WITH_COLOR_PICKER } from 'shared/components/page-builder/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'

export default () => [
  {
    label: I18n.t('react.shared.affiliate_program.affiliate_program'),
    fields: ['affiliateProgram'],
  },
  {
    label: I18n.t('react.shared.view'),
    tooltipId: 'block_view',
    fields: ['affiliateView'],
  },
  {
    dataMenuHighlight: 'text',
    label: I18n.t('react.shared.custom_cta'),
    tooltipId: 'custom_cta',
    show: {
      key: 'view',
      value: BLOCK_VIEW.text,
    },
    fields: [
      'affiliateFroalaText',
      [
        {
          field: 'percentage',
          label: I18n.t('react.shared.width'),
          key: 'width',
        },
        {
          field: 'pixels',
          label: I18n.t('react.cabinet.max_width'),
          key: 'maxWidth',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: 'button',
    label: I18n.t('react.shared.common.button_style'),
    tooltipId: 'block_button_style',
    fields: [
      'buttonColor',
      'buttonViewStyle',
      'cornerRadius',
      {
        field: 'horizontalAlignment',
        label: I18n.t('react.shared.horisontal_position'),
        key: 'horizontalAlignment',
      },
      'buttonAnimation',
      {
        field: 'pixels',
        label: I18n.t('react.shared.width'),
        key: 'buttonWidth',
      },
      {
        label: I18n.t('react.shared.button_text'),
        fields: [
          {
            field: 'text',
            key: 'buttonText',
          },
          ['buttonFont', 'buttonStyle'],
          ['buttonTextColor', 'buttonSize'],
        ],
      },
    ],
  },
  {
    label: I18n.t('react.shared.background'),
    tooltipId: 'block_background_settings',
    fields: [
      'uploadBackgroundImage',
      [BLOCKS_WITH_COLOR_PICKER.backgroundColor, 'transparency'],
      {
        field: 'pixels',
        label: I18n.t('react.shared.min_height'),
        key: 'minHeight',
      },
    ],
  },
  {
    label: I18n.t('react.shared.padding'),
    tooltipId: 'padding',
    show: {
      key: 'view',
      value: BLOCK_VIEW.text,
    },
    fields: [
      'paddingUnitsOptions',
      [
        {
          field: 'padding',
          key: 'paddingTop',
          label: I18n.t('react.shared.position.top'),
        },
        {
          field: 'padding',
          key: 'paddingBottom',
          label: I18n.t('react.shared.position.bottom'),
        },
      ],
      [
        {
          field: 'padding',
          key: 'paddingLeft',
          label: I18n.t('react.shared.position.left'),
        },
        {
          field: 'padding',
          key: 'paddingRight',
          label: I18n.t('react.shared.position.right'),
        },
      ],
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
