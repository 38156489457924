import React from 'react'
import { toJS } from 'mobx'

import BlockPopularProducts from '@elo-kit/components/page-builder/blocks/popular-products/block-popular-products'
import { POPULAR_PRODUCTS } from '@elo-kit/constants/contentPage.constants'

import { amountCrossed, withStores } from 'utils/helpers.utils'
import { isWindows } from 'utils/browsers.utils'
import { getMP3dsBrowserMetaData } from 'utils/requests.utils'
import { formatMoney } from 'utils/money.utils'

import { manageOrder } from 'shop/api/orders.api'
import { acceptPostsell } from 'shop/api/funnels.api'

const PopularProductsBlock = (props) => {
  const {
    contentPageStore,
    historyPush,
    params,
    LinkComponent,
    currenciesStore,
    block,
    sellablesStore,
    productsStore,
  } = props
  const { membershipViewType, membershipSellable, setProductsCustomLoading, fetchPopularProducts, seller, view } =
    contentPageStore
  const { convertToPrice, getUnit, getCurrencySignPosition } = currenciesStore
  const { toggleCustomLoading } = sellablesStore
  const { username } = params || {}

  return (
    <BlockPopularProducts
      {...props}
      historyPush={historyPush}
      sellerItem={seller}
      convertToPriceWithoutCurrency={(value) => formatMoney(value, 2, ',', '.')}
      LinkComponent={LinkComponent}
      contentProducts={toJS(block.content[POPULAR_PRODUCTS.products])}
      formatPrice={(value, currencySign) => `${formatMoney(value || 0, 2, ',', '.')}${currencySign}`}
      sellable={sellablesStore.item}
      fetchProduct={productsStore.fetchItem}
      manageOrder={manageOrder}
      fetchSellable={sellablesStore.fetchItem}
      product={productsStore.item}
      acceptPostsell={acceptPostsell}
      {...{
        setProductsCustomLoading,
        fetchPopularProducts,
        view,
        getUnit,
        username,
        membershipViewType,
        membershipSellable,
        toggleCustomLoading,
        convertToPrice,
        amountCrossed,
        isWindows,
        getUnitPosition: getCurrencySignPosition,
        getMP3dsBrowserMetaData,
      }}
    />
  )
}

const stores = ['contentPageStore', 'currenciesStore', 'sellablesStore', 'productsStore']

export default withStores(stores, PopularProductsBlock)
