import { BLOCKS_WITH_COLOR_PICKER } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    label: I18n.t('react.shared.product_name'),
    tooltipId: 'pb_product_name',
    fields: [
      {
        field: 'font',
        key: 'productFont',
      },
      {
        field: 'fontStyle',
        key: 'productStyle',
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.productColor,
      },
      {
        field: 'fontSize',
        key: 'productSize',
      },
    ],
  },
  {
    tooltipId: 'pb_product_img_settings',
    label: I18n.t('react.cabinet.common.product_image'),
    toggle: 'productImage',
    fields: [
      {
        field: 'pixels',
        label: I18n.t('react.cabinet.common.roundness'),
        key: 'borderRadius',
      },
    ],
  },
  {
    label: I18n.t('react.shared.product_price'),
    tooltipId: 'pb_product_price',
    fields: [
      {
        field: 'font',
        key: 'priceFont',
      },
      {
        field: 'fontStyle',
        key: 'priceStyle',
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.priceColor,
      },
      {
        field: 'fontSize',
        key: 'priceSize',
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.common.pricing_plan'),
    toggle: 'pricingPlan',
    tooltipId: 'pb_product_price_form',
    fields: [
      {
        field: 'font',
        key: 'priceFormFont',
      },
      {
        field: 'fontStyle',
        key: 'priceFormStyle',
      },
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.priceFormColor,
      },
      {
        field: 'fontSize',
        key: 'priceFormSize',
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.product_description_title'),
    toggle: 'productDescription',
    tooltipId: 'pb_product_description',
    fields: [
      {
        field: 'font',
        key: 'productDescriptionFont',
      },
      {
        field: 'fontStyle',
        key: 'productDescriptionStyle',
      },
      {
        field: 'color',
        key: 'productDescriptionColor',
      },
      {
        field: 'fontSize',
        key: 'productDescriptionSize',
      },
    ],
  },
  {
    label: I18n.t('react.cabinet.background'),
    tooltipId: 'pb_background_settings',
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.backgroundColor,
      },
      {
        field: 'transparency',
        key: 'backgroundTransparency',
      },
      {
        field: 'uploadBackgroundImage',
        cropAllowed: true,
      },
    ],
  },
]
