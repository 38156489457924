import { action, makeObservable, observable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { defaultTemplateData } from 'constants/paymentPageTemplates.constants'
import * as api from '../api/paymentPageTemplates.api'

export class PaymentPageTemplatesStore extends SharedStore {
  storeName = 'PaymentPageTemplatesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable historyModal = false
  @observable editInfoModal = false

  @action
  handleThemeChange = (name, value) => {
    const { theme } = this.item

    this.item = {
      ...this.item,
      theme: {
        ...theme,
        [name]: value,
      },
    }
  }

  @action
  handleTemplateChange = (name, value) => {
    this.item = {
      ...this.item,
      [name]: value,
    }
  }

  @action handleTrustSealChange = (name, value) => {
    this.item = {
      ...this.item,
      trustSealsSettings: {
        ...this.item.trustSealsSettings,
        [name]: value,
      },
    }
  }

  @action handleTrustSealAttributesChange = (name, value) => {
    this.item = {
      ...this.item,
      templatesTrustSealsAttributes: [
        {
          [name]: value,
        },
      ],
    }
  }

  @action handleRemoveTrustSeal = (relationId, templateId) => {
    if (relationId) {
      this.item = {
        ...this.item,
        templatesTrustSealsAttributes: [
          {
            _destroy: true,
            id: relationId,
          },
        ],
      }
    } else {
      this.handleTrustSealAttributesChange('trustSealId', null)
    }

    this.updateItem(templateId).then(() => {
      this.resetItem()
      this.fetchItem(templateId)
    })
  }

  @action
  handleBuyerFields = (type, name, value) => {
    const { buyerInformation } = this.item
    const info = buyerInformation[type]

    this.item = {
      ...this.item,
      buyerInformation: {
        ...buyerInformation,
        [type]: {
          ...info,
          [name]: value,
        },
      },
    }
  }

  @action
  handleBuyerCheckboxesChange = (field, name, { visible, required }) => {
    const FIELDS_STATE_MAP = {
      onoff: 'visible',
      onon: 'required',
      offoff: 'hidden',
      offon: 'required',
    }
    const { buyerInformation } = this.item
    const info = buyerInformation[field]
    const fieldItem = FIELDS_STATE_MAP[`${visible}${required}`]

    this.item = {
      ...this.item,
      buyerInformation: {
        ...buyerInformation,
        [field]: {
          ...info,
          [name]: fieldItem,
        },
      },
    }
  }

  @action
  fetchNew = async () => {
    this.loading = true
    const resp = await api.fetchNew()
    this.item = {
      ...defaultTemplateData,
      ...resp.data,
    }
    this.loading = false
  }

  @override
  async fetchItem(id) {
    this.loading = true
    const resp = await api.fetchItem(id)
    this.item = {
      ...defaultTemplateData,
      ...this.item,
      ...resp.data,
      theme: {
        ...defaultTemplateData.theme,
        ...this.item.theme,
        ...resp.data.theme,
      },
    }
    this.loading = false
    return resp
  }

  duplicateByToken = api.duplicateByToken

  constructor() {
    super()

    makeObservable(this)

    this.item = defaultTemplateData
  }
}

export default new PaymentPageTemplatesStore()
