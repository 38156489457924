import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/funnels.api'

export class FunnelsStore extends SharedStore {
  storeName = 'FunnelsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @action getFunnelsByIds = (idsList) => this.list.filter(({ id }) => idsList.find(({ funnelId }) => funnelId === id))

  duplicateByToken = (token, data) => api.duplicateByToken(token, data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new FunnelsStore()
