export const DEFAULT_TIMEZONE = 'Europe/Berlin'

export const TIMEZONES = {
  '(UTC-11:00) Niue': 'Pacific/Niue',
  '(UTC-11:00) Pago Pago': 'Pacific/Pago_Pago',
  '(UTC-10:00) Hawaii Time': 'Pacific/Honolulu',
  '(UTC-10:00) Rarotonga': 'Pacific/Rarotonga',
  '(UTC-10:00) Tahiti': 'Pacific/Tahiti',
  '(UTC-09:30) Marquesas': 'Pacific/Marquesas',
  '(UTC-09:00) Alaska Time': 'America/Anchorage',
  '(UTC-09:00) Gambier': 'Pacific/Gambier',
  '(UTC-08:00) Pacific Time': 'America/Los_Angeles',
  '(UTC-08:00) Pacific Time - Tijuana': 'America/Tijuana',
  '(UTC-08:00) Pacific Time - Vancouver': 'America/Vancouver',
  '(UTC-08:00) Pacific Time - Whitehorse': 'America/Whitehorse',
  '(UTC-08:00) Pitcairn': 'Pacific/Pitcairn',
  '(UTC-07:00) Mountain Time': 'America/Denver',
  '(UTC-07:00) Mountain Time - Arizona': 'America/Phoenix',
  '(UTC-07:00) Mountain Time - Chihuahua, Mazatlan': 'America/Mazatlan',
  '(UTC-07:00) Mountain Time - Dawson Creek': 'America/Dawson_Creek',
  '(UTC-07:00) Mountain Time - Edmonton': 'America/Edmonton',
  '(UTC-07:00) Mountain Time - Hermosillo': 'America/Hermosillo',
  '(UTC-07:00) Mountain Time - Yellowknife': 'America/Yellowknife',
  '(UTC-06:00) Belize': 'America/Belize',
  '(UTC-06:00) Central Time': 'America/Chicago',
  '(UTC-06:00) Central Time - Mexico City': 'America/Mexico_City',
  '(UTC-06:00) Central Time - Regina': 'America/Regina',
  '(UTC-06:00) Central Time - Tegucigalpa': 'America/Tegucigalpa',
  '(UTC-06:00) Central Time - Winnipeg': 'America/Winnipeg',
  '(UTC-06:00) Costa Rica': 'America/Costa_Rica',
  '(UTC-06:00) El Salvador': 'America/El_Salvador',
  '(UTC-06:00) Galapagos': 'Pacific/Galapagos',
  '(UTC-06:00) Guatemala': 'America/Guatemala',
  '(UTC-06:00) Managua': 'America/Managua',
  '(UTC-05:00) America Cancun': 'America/Cancun',
  '(UTC-05:00) Bogota': 'America/Bogota',
  '(UTC-05:00) Easter Island': 'Pacific/Easter',
  '(UTC-05:00) Eastern Time': 'America/New_York',
  '(UTC-05:00) Eastern Time - Iqaluit': 'America/Iqaluit',
  '(UTC-05:00) Eastern Time - Toronto': 'America/Toronto',
  '(UTC-05:00) Guayaquil': 'America/Guayaquil',
  '(UTC-05:00) Havana': 'America/Havana',
  '(UTC-05:00) Jamaica': 'America/Jamaica',
  '(UTC-05:00) Lima': 'America/Lima',
  '(UTC-05:00) Nassau': 'America/Nassau',
  '(UTC-05:00) Panama': 'America/Panama',
  '(UTC-05:00) Port-au-Prince': 'America/Port-au-Prince',
  '(UTC-05:00) Rio Branco': 'America/Rio_Branco',
  '(UTC-04:00) Atlantic Time - Halifax': 'America/Halifax',
  '(UTC-04:00) Barbados': 'America/Barbados',
  '(UTC-04:00) Bermuda': 'Atlantic/Bermuda',
  '(UTC-04:00) Boa Vista': 'America/Boa_Vista',
  '(UTC-04:00) Caracas': 'America/Caracas',
  '(UTC-04:00) Curacao': 'America/Curacao',
  '(UTC-04:00) Grand Turk': 'America/Grand_Turk',
  '(UTC-04:00) Guyana': 'America/Guyana',
  '(UTC-04:00) La Paz': 'America/La_Paz',
  '(UTC-04:00) Manaus': 'America/Manaus',
  '(UTC-04:00) Martinique': 'America/Martinique',
  '(UTC-04:00) Port of Spain': 'America/Port_of_Spain',
  '(UTC-04:00) Porto Velho': 'America/Porto_Velho',
  '(UTC-04:00) Puerto Rico': 'America/Puerto_Rico',
  '(UTC-04:00) Santo Domingo': 'America/Santo_Domingo',
  '(UTC-04:00) Thule': 'America/Thule',
  '(UTC-03:30) Newfoundland Time - St. Johns': 'America/St_Johns',
  '(UTC-03:00) Araguaina': 'America/Araguaina',
  '(UTC-03:00) Asuncion': 'America/Asuncion',
  '(UTC-03:00) Belem': 'America/Belem',
  '(UTC-03:00) Buenos Aires': 'America/Argentina/Buenos_Aires',
  '(UTC-03:00) Campo Grande': 'America/Campo_Grande',
  '(UTC-03:00) Cayenne': 'America/Cayenne',
  '(UTC-03:00) Cuiaba': 'America/Cuiaba',
  '(UTC-03:00) Fortaleza': 'America/Fortaleza',
  '(UTC-03:00) Godthab': 'America/Godthab',
  '(UTC-03:00) Maceio': 'America/Maceio',
  '(UTC-03:00) Miquelon': 'America/Miquelon',
  '(UTC-03:00) Montevideo': 'America/Montevideo',
  '(UTC-03:00) Palmer': 'Antarctica/Palmer',
  '(UTC-03:00) Paramaribo': 'America/Paramaribo',
  '(UTC-03:00) Punta Arenas': 'America/Punta_Arenas',
  '(UTC-03:00) Recife': 'America/Recife',
  '(UTC-03:00) Rothera': 'Antarctica/Rothera',
  '(UTC-03:00) Salvador': 'America/Bahia',
  '(UTC-03:00) Santiago': 'America/Santiago',
  '(UTC-03:00) Stanley': 'Atlantic/Stanley',
  '(UTC-02:00) Noronha': 'America/Noronha',
  '(UTC-02:00) Sao Paulo': 'America/Sao_Paulo',
  '(UTC-02:00) South Georgia': 'Atlantic/South_Georgia',
  '(UTC-01:00) Azores': 'Atlantic/Azores',
  '(UTC-01:00) Cape Verde': 'Atlantic/Cape_Verde',
  '(UTC-01:00) Scoresbysund': 'America/Scoresbysund',
  '(UTC+00:00) Abidjan': 'Africa/Abidjan',
  '(UTC+00:00) Accra': 'Africa/Accra',
  '(UTC+00:00) Bissau': 'Africa/Bissau',
  '(UTC+00:00) Canary Islands': 'Atlantic/Canary',
  '(UTC+00:00) Casablanca': 'Africa/Casablanca',
  '(UTC+00:00) Danmarkshavn': 'America/Danmarkshavn',
  '(UTC+00:00) Dublin': 'Europe/Dublin',
  '(UTC+00:00) El Aaiun': 'Africa/El_Aaiun',
  '(UTC+00:00) Faeroe': 'Atlantic/Faroe',
  '(UTC+00:00) GMT (no daylight saving)': 'Etc/GMT',
  '(UTC+00:00) Lisbon': 'Europe/Lisbon',
  '(UTC+00:00) London': 'Europe/London',
  '(UTC+00:00) Monrovia': 'Africa/Monrovia',
  '(UTC+00:00) Reykjavik': 'Atlantic/Reykjavik',
  UTC: 'UTC',
  '(UTC+01:00) Algiers': 'Africa/Algiers',
  '(UTC+01:00) Amsterdam': 'Europe/Amsterdam',
  '(UTC+01:00) Andorra': 'Europe/Andorra',
  '(UTC+01:00) Berlin': DEFAULT_TIMEZONE,
  '(UTC+01:00) Brussels': 'Europe/Brussels',
  '(UTC+01:00) Budapest': 'Europe/Budapest',
  '(UTC+01:00) Central European Time - Belgrade': 'Europe/Belgrade',
  '(UTC+01:00) Central European Time - Prague': 'Europe/Prague',
  '(UTC+01:00) Ceuta': 'Africa/Ceuta',
  '(UTC+01:00) Copenhagen': 'Europe/Copenhagen',
  '(UTC+01:00) Gibraltar': 'Europe/Gibraltar',
  '(UTC+01:00) Lagos': 'Africa/Lagos',
  '(UTC+01:00) Luxembourg': 'Europe/Luxembourg',
  '(UTC+01:00) Madrid': 'Europe/Madrid',
  '(UTC+01:00) Malta': 'Europe/Malta',
  '(UTC+01:00) Monaco': 'Europe/Monaco',
  '(UTC+01:00) Ndjamena': 'Africa/Ndjamena',
  '(UTC+01:00) Oslo': 'Europe/Oslo',
  '(UTC+01:00) Paris': 'Europe/Paris',
  '(UTC+01:00) Rome': 'Europe/Rome',
  '(UTC+01:00) Stockholm': 'Europe/Stockholm',
  '(UTC+01:00) Tirane': 'Europe/Tirane',
  '(UTC+01:00) Tunis': 'Africa/Tunis',
  '(UTC+01:00) Vienna': 'Europe/Vienna',
  '(UTC+01:00) Warsaw': 'Europe/Warsaw',
  '(UTC+01:00) Zurich': 'Europe/Zurich',
  '(UTC+02:00) Amman': 'Asia/Amman',
  '(UTC+02:00) Athens': 'Europe/Athens',
  '(UTC+02:00) Beirut': 'Asia/Beirut',
  '(UTC+02:00) Bucharest': 'Europe/Bucharest',
  '(UTC+02:00) Cairo': 'Africa/Cairo',
  '(UTC+02:00) Chisinau': 'Europe/Chisinau',
  '(UTC+02:00) Damascus': 'Asia/Damascus',
  '(UTC+02:00) Gaza': 'Asia/Gaza',
  '(UTC+02:00) Helsinki': 'Europe/Helsinki',
  '(UTC+02:00) Jerusalem': 'Asia/Jerusalem',
  '(UTC+02:00) Johannesburg': 'Africa/Johannesburg',
  '(UTC+02:00) Khartoum': 'Africa/Khartoum',
  '(UTC+02:00) Kyiv': 'Europe/Kiev',
  '(UTC+02:00) Maputo': 'Africa/Maputo',
  '(UTC+02:00) Moscow-01 - Kaliningrad': 'Europe/Kaliningrad',
  '(UTC+02:00) Nicosia': 'Asia/Nicosia',
  '(UTC+02:00) Riga': 'Europe/Riga',
  '(UTC+02:00) Sofia': 'Europe/Sofia',
  '(UTC+02:00) Tallinn': 'Europe/Tallinn',
  '(UTC+02:00) Tripoli': 'Africa/Tripoli',
  '(UTC+02:00) Vilnius': 'Europe/Vilnius',
  '(UTC+02:00) Windhoek': 'Africa/Windhoek',
  '(UTC+03:00) Baghdad': 'Asia/Baghdad',
  '(UTC+03:00) Istanbul': 'Europe/Istanbul',
  '(UTC+03:00) Minsk': 'Europe/Minsk',
  '(UTC+03:00) Moscow+00 - Moscow': 'Europe/Moscow',
  '(UTC+03:00) Nairobi': 'Africa/Nairobi',
  '(UTC+03:00) Qatar': 'Asia/Qatar',
  '(UTC+03:00) Riyadh': 'Asia/Riyadh',
  '(UTC+03:00) Syowa': 'Antarctica/Syowa',
  '(UTC+03:30) Tehran': 'Asia/Tehran',
  '(UTC+04:00) Baku': 'Asia/Baku',
  '(UTC+04:00) Dubai': 'Asia/Dubai',
  '(UTC+04:00) Mahe': 'Indian/Mahe',
  '(UTC+04:00) Mauritius': 'Indian/Mauritius',
  '(UTC+04:00) Moscow+01 - Samara': 'Europe/Samara',
  '(UTC+04:00) Reunion': 'Indian/Reunion',
  '(UTC+04:00) Tbilisi': 'Asia/Tbilisi',
  '(UTC+04:00) Yerevan': 'Asia/Yerevan',
  '(UTC+04:30) Kabul': 'Asia/Kabul',
  '(UTC+05:00) Aqtau': 'Asia/Aqtau',
  '(UTC+05:00) Aqtobe': 'Asia/Aqtobe',
  '(UTC+05:00) Ashgabat': 'Asia/Ashgabat',
  '(UTC+05:00) Dushanbe': 'Asia/Dushanbe',
  '(UTC+05:00) Karachi': 'Asia/Karachi',
  '(UTC+05:00) Kerguelen': 'Indian/Kerguelen',
  '(UTC+05:00) Maldives': 'Indian/Maldives',
  '(UTC+05:00) Mawson': 'Antarctica/Mawson',
  '(UTC+05:00) Moscow+02 - Yekaterinburg': 'Asia/Yekaterinburg',
  '(UTC+05:00) Tashkent': 'Asia/Tashkent',
  '(UTC+05:30) Colombo': 'Asia/Colombo',
  '(UTC+05:30) India Standard Time': 'Asia/Calcutta',
  '(UTC+05:45) Kathmandu': 'Asia/Kathmandu',
  '(UTC+06:00) Almaty': 'Asia/Almaty',
  '(UTC+06:00) Bishkek': 'Asia/Bishkek',
  '(UTC+06:00) Chagos': 'Indian/Chagos',
  '(UTC+06:00) Dhaka': 'Asia/Dhaka',
  '(UTC+06:00) Moscow+03 - Omsk': 'Asia/Omsk',
  '(UTC+06:00) Thimphu': 'Asia/Thimphu',
  '(UTC+06:00) Vostok': 'Antarctica/Vostok',
  '(UTC+06:30) Cocos': 'Indian/Cocos',
  '(UTC+06:30) Rangoon': 'Asia/Yangon',
  '(UTC+07:00) Bangkok': 'Asia/Bangkok',
  '(UTC+07:00) Christmas': 'Indian/Christmas',
  '(UTC+07:00) Davis': 'Antarctica/Davis',
  '(UTC+07:00) Hanoi': 'Asia/Saigon',
  '(UTC+07:00) Hovd': 'Asia/Hovd',
  '(UTC+07:00) Jakarta': 'Asia/Jakarta',
  '(UTC+07:00) Moscow+04 - Krasnoyarsk': 'Asia/Krasnoyarsk',
  '(UTC+08:00) Brunei': 'Asia/Brunei',
  '(UTC+08:00) China Time - Beijing': 'Asia/Shanghai',
  '(UTC+08:00) Choibalsan': 'Asia/Choibalsan',
  '(UTC+08:00) Hong Kong': 'Asia/Hong_Kong',
  '(UTC+08:00) Kuala Lumpur': 'Asia/Kuala_Lumpur',
  '(UTC+08:00) Macau': 'Asia/Macau',
  '(UTC+08:00) Makassar': 'Asia/Makassar',
  '(UTC+08:00) Manila': 'Asia/Manila',
  '(UTC+08:00) Moscow+05 - Irkutsk': 'Asia/Irkutsk',
  '(UTC+08:00) Singapore': 'Asia/Singapore',
  '(UTC+08:00) Taipei': 'Asia/Taipei',
  '(UTC+08:00) Ulaanbaatar': 'Asia/Ulaanbaatar',
  '(UTC+08:00) Western Time - Perth': 'Australia/Perth',
  '(UTC+08:30) Pyongyang': 'Asia/Pyongyang',
  '(UTC+09:00) Dili': 'Asia/Dili',
  '(UTC+09:00) Jayapura': 'Asia/Jayapura',
  '(UTC+09:00) Moscow+06 - Yakutsk': 'Asia/Yakutsk',
  '(UTC+09:00) Palau': 'Pacific/Palau',
  '(UTC+09:00) Seoul': 'Asia/Seoul',
  '(UTC+09:00) Tokyo': 'Asia/Tokyo',
  '(UTC+09:30) Central Time - Darwin': 'Australia/Darwin',
  "(UTC+10:00) Dumont D'Urville": 'Antarctica/DumontDUrville',
  '(UTC+10:00) Eastern Time - Brisbane': 'Australia/Brisbane',
  '(UTC+10:00) Guam': 'Pacific/Guam',
  '(UTC+10:00) Moscow+07 - Vladivostok': 'Asia/Vladivostok',
  '(UTC+10:00) Port Moresby': 'Pacific/Port_Moresby',
  '(UTC+10:00) Truk': 'Pacific/Chuuk',
  '(UTC+10:30) Central Time - Adelaide': 'Australia/Adelaide',
  '(UTC+11:00) Casey': 'Antarctica/Casey',
  '(UTC+11:00) Eastern Time - Hobart': 'Australia/Hobart',
  '(UTC+11:00) Eastern Time - Melbourne, Sydney': 'Australia/Sydney',
  '(UTC+11:00) Efate': 'Pacific/Efate',
  '(UTC+11:00) Guadalcanal': 'Pacific/Guadalcanal',
  '(UTC+11:00) Kosrae': 'Pacific/Kosrae',
  '(UTC+11:00) Moscow+08 - Magadan': 'Asia/Magadan',
  '(UTC+11:00) Norfolk': 'Pacific/Norfolk',
  '(UTC+11:00) Noumea': 'Pacific/Noumea',
  '(UTC+11:00) Ponape': 'Pacific/Pohnpei',
  '(UTC+12:00) Funafuti': 'Pacific/Funafuti',
  '(UTC+12:00) Kwajalein': 'Pacific/Kwajalein',
  '(UTC+12:00) Majuro': 'Pacific/Majuro',
  '(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy': 'Asia/Kamchatka',
  '(UTC+12:00) Nauru': 'Pacific/Nauru',
  '(UTC+12:00) Tarawa': 'Pacific/Tarawa',
  '(UTC+12:00) Wake': 'Pacific/Wake',
  '(UTC+12:00) Wallis': 'Pacific/Wallis',
  '(UTC+13:00) Auckland': 'Pacific/Auckland',
  '(UTC+13:00) Enderbury': 'Pacific/Enderbury',
  '(UTC+13:00) Fakaofo': 'Pacific/Fakaofo',
  '(UTC+13:00) Fiji': 'Pacific/Fiji',
  '(UTC+13:00) Tongatapu': 'Pacific/Tongatapu',
  '(UTC+14:00) Apia': 'Pacific/Apia',
  '(UTC+14:00) Kiritimati': 'Pacific/Kiritimati',
}

export const TIMEZONE_LABELS = Object.keys(TIMEZONES)

export const TIMEZONE_OPTIONS = TIMEZONE_LABELS.map((label) => ({
  label,
  value: TIMEZONES[label],
}))
