import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { noop } from 'constants/general.constants'

import ContentBlockSelectField from '../select-field/ContentBlockSelectField'

import './content-block-country-selector.scss'

const ContentBlockCountrySelectorField = (props) => {
  const { countriesList, fetchList, handleInput, className } = props
  const [selectedCountry, setSelectedCountry] = useState({})
  const [countries, setCountries] = useState([{}])

  const fetchCountries = async () => {
    if (countriesList && countriesList.length) {
      setCountriesList(countriesList)
    } else {
      const resp = await fetchList()
      const { list = [] } = resp.data || {}
      setCountriesList(list)
    }
  }
  useEffect(() => {
    fetchCountries()
  }, [])
  useEffect(() => {
    forcePreselect()
  }, [countries])

  const setCountriesList = (list) => {
    const countries = list.map(({ alpha2, euMember, name }) => ({
      code: alpha2,
      label: name,
      eu: !!euMember,
      value: alpha2,
    }))

    setCountries(countries)
  }

  const onChange = (data) => {
    handleInput(data)
    setSelectedCountry(data)
  }

  const forcePreselect = () => {
    const { value: propsValue } = props
    const data =
      countries.filter(({ value }) => value === propsValue)[0] || countries.filter(({ value }) => value === 'DE')[0]
    if (data) {
      onChange(data)
    }
  }

  const selectFieldClasses = classNames('content-block__country-selector-field', className)

  return (
    <div className={selectFieldClasses}>
      <ContentBlockSelectField
        components={{ IndicatorSeparator: () => null }}
        onChange={onChange}
        name='menuFontStyle'
        placeholder={I18n.t('shared.common.country')}
        value={selectedCountry}
        options={countries}
        valid={true}
        defaultValue={selectedCountry}
      />
    </div>
  )
}

ContentBlockCountrySelectorField.propTypes = {
  countriesList: PropTypes.array,
  value: PropTypes.string,
  handleInput: PropTypes.func,
  handleValid: PropTypes.func,
  fetchList: PropTypes.func,
  className: PropTypes.string,
}
ContentBlockCountrySelectorField.defaultProps = {
  countriesList: [],
  validations: [],
  fetchList: noop,
  handleInput: noop,
  handleValid: noop,
  className: '',
}

export default ContentBlockCountrySelectorField
