import React from 'react'

import './story-grid.scss'

interface Props {
  columns: number | number[]
  children: React.ReactNode
}

export const StoryGrid: React.FC<Props> = ({ columns, children }) => {
  const templateColumns = Array.isArray(columns) ? columns.map((cl) => cl + 'fr').join(' ') : `repeat(${columns}, 1fr)`

  return (
    <div className='story-grid' style={{ '--columns': templateColumns } as React.CSSProperties}>
      {children}
    </div>
  )
}
