import React from 'react'
import CustomButton from 'shared/screens/course-preview/widgets/custom-button/CustomButton'

import { THEME_SHARING_PARAMS, THEME_SLUGS } from 'constants/themes.constants'
import { SHARABLE_TYPE } from 'constants/sharing.constants'
import { POSITIONS } from '@elo-kit/constants/general.constants'

import { getParamsFromUrl } from 'utils/helpers.utils'
import { getCookies } from 'libs/common/cookies'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'

import affiliateLandingsStore from '../containers/cabinet/stores/affiliateLandings.store'
import productsStore from '../containers/cabinet/stores/products.store'
import paymentPageTemplatesStore from '../containers/cabinet/stores/paymentPageTemplates.store'
import funnelsStore from '../containers/cabinet/stores/funnels.store'
import addonThemesStore from '../containers/cabinet/stores/orderBumpsThemes.store'
import shopThemesStore from '../containers/cabinet/stores/shopThemes.store'
import membershipThemesStore from '../containers/cabinet/stores/membershipThemes.store'

export const isThemePreviewRoute = () => {
  const { pathname } = window.location
  return (
    !!pathname.includes('shop_theme_preview') ||
    !!pathname.includes('membership_theme_preview') ||
    !!pathname.includes('course_lesson_preview') ||
    !!pathname.includes('funnel_page_preview')
  )
}

export const getThemeStoreAndSlug = () => {
  const urlParams = getParamsFromUrl(THEME_SHARING_PARAMS)
  const sharingToken = urlParams.shared_theme_token || getCookies('shared_theme_token')
  const sharableType = urlParams.shared_theme_type || getCookies('shared_theme_type')

  if (sharingToken && sharableType) {
    const themeSlugBySharable = {
      [SHARABLE_TYPE.product]: THEME_SLUGS.products,
      [SHARABLE_TYPE.affiliateLanding]: THEME_SLUGS.affiliateLandings,
      [SHARABLE_TYPE.paymentPage]: THEME_SLUGS.paymentPage,
      [SHARABLE_TYPE.funnel]: THEME_SLUGS.funnels,
      [SHARABLE_TYPE.addon]: THEME_SLUGS.orderBumpsThemes,
      [SHARABLE_TYPE.shop]: THEME_SLUGS.shopThemes,
      [SHARABLE_TYPE.membership]: THEME_SLUGS.membershipThemes,
      [SHARABLE_TYPE.multiItemsSharing]: THEME_SLUGS.sharings,
    }

    const storesBySharable = {
      [SHARABLE_TYPE.product]: productsStore,
      [SHARABLE_TYPE.affiliateLanding]: affiliateLandingsStore,
      [SHARABLE_TYPE.paymentPage]: paymentPageTemplatesStore,
      [SHARABLE_TYPE.funnel]: funnelsStore,
      [SHARABLE_TYPE.addon]: addonThemesStore,
      [SHARABLE_TYPE.shop]: shopThemesStore,
      [SHARABLE_TYPE.membership]: membershipThemesStore,
    }

    return {
      themeStore: storesBySharable[sharableType],
      themeSlug: themeSlugBySharable[sharableType],
    }
  }
  return null
}

export const getAlignment = (value) => {
  switch (value) {
    case POSITIONS.right:
    case POSITIONS.bottom:
      return 'flex-end'
    case POSITIONS.top:
    case POSITIONS.left:
      return 'flex-start'
    case POSITIONS.center:
    default:
      return 'center'
  }
}

export const hex2rgb = (hex, opacity) => {
  let h = hex.replace('#', '')
  h = h.match(new RegExp('(.{' + h.length / 3 + '})', 'g'))

  for (let i = 0; i < h.length; i++) {
    h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16)
  }

  if (typeof opacity !== 'undefined') h.push(opacity)

  return `rgba(${h.join(',')})`
}

export const EloOrCustomButton = ({ defaultTheme, ...restProps }) =>
  defaultTheme ? <EloButton {...restProps} /> : <CustomButton {...restProps} />
