import React from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import { POST } from 'utils/requests.utils'
import { VIMEO_VIDEOS_OPTION_KEY } from 'constants/options.constants'

import Video from '@elo-kit/components/page-builder/blocks/video/video'

import { Content } from 'shared/types/content-page'

interface Props {
  previewMode: boolean
  block: Content
  pathname: string
  contentPageStore
  userStore
}

export const VideoPreview: React.FC<Props> = observer(function VideoPreview(props) {
  const {
    contentPageStore: {
      isAppActive,
      fetchVideoCodes,
      setVideoCode,
      getVideoCodeData,
      // @ts-ignore
      createVideoCode,
      // @ts-ignore
      activeLessonStatusId,
      deleteBlockVideoCodes,
      hideVideoCodes,
      setVideoCodeValue,
      view,
      themeType,
      videoCodes,
    },
    userStore: {
      item: { email },
    },
  } = props

  const { block = {}, previewMode, pathname } = props

  const videoCodesJs = toJS(videoCodes)

  return (
    <Video
      {...props}
      previewMode={previewMode}
      view={view}
      POST={POST}
      block={toJS(block)}
      deleteBlockVideoCodes={deleteBlockVideoCodes}
      fetchVideoCodes={fetchVideoCodes}
      hideVideoCodes={hideVideoCodes}
      activeLessonStatusId={activeLessonStatusId}
      getVideoCodeData={getVideoCodeData}
      setVideoCode={setVideoCode}
      createVideoCode={createVideoCode}
      setVideoCodeValue={setVideoCodeValue}
      userEmail={email}
      videoCodes={videoCodesJs}
      toJS={toJS}
      isAppActive={isAppActive}
      pathname={pathname}
      themeType={themeType}
      vimeoOptionKey={VIMEO_VIDEOS_OPTION_KEY}
    />
  )
})
