import isURL from 'is-url'

import { SLASH_SEPARATOR } from '@elo-kit/constants/general.constants'

import { LINK_TYPE } from '../containers/shared/components/page-builder/constants/block.constants'
import { getItemById } from './helpers.utils'

export const internalURL = (baseURL, menuItem, themePages, username = '') => {
  const {
    location: { pathname },
  } = window
  const formattedMenuItemUrl =
    menuItem.url[0] === SLASH_SEPARATOR ? menuItem.url.replace(SLASH_SEPARATOR, '') : menuItem.url
  const mainPath = baseURL || pathname
  const formattedUrl = mainPath[mainPath.length - 1] === SLASH_SEPARATOR ? mainPath.slice(0, -1) : mainPath

  if (menuItem.default) {
    return `/s/${username}${menuItem.url}`
  }

  if (menuItem.linkType === LINK_TYPE.themePage) {
    return `${formattedUrl}/${getItemById(themePages, formattedMenuItemUrl).slug || ''}`
  }

  return isURL(menuItem.url) ? menuItem.url : `${formattedUrl}/${formattedMenuItemUrl}`
}

export const findBlockById = (blocks, blockId) => {
  let foundBlock = {}
  blocks.forEach((block) =>
    block.children.forEach((rowBlock) => {
      if (rowBlock.id === blockId) foundBlock = rowBlock
    })
  )

  return foundBlock
}

export const isStickyHeader = (row) => {
  const { content: { stickyHeader } = {}, form } = row || {}

  return stickyHeader && form === 'header'
}
