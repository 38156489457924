import React from 'react'
import { observer } from 'mobx-react'
import Datetime from 'react-datetime'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FieldError } from '@elo-kit/components/elo-ui/field-error/FieldError'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import './content-block-date-time-field.scss'

const propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  formatToGet: PropTypes.string,
  autoComplete: PropTypes.string,
  errors: PropTypes.shape({}),
  name: PropTypes.string,
}

const defaultProps = {
  dateFormat: DATE_FORMATS.DDMMYYYY,
  disabled: false,
  erros: {},
  name: '',
}

const ContentBlockDateTimeField = (props) => {
  const {
    autoComplete,
    className,
    dateFormat,
    disabled,
    errors,
    isInvalid,
    label,
    name,
    value,
    placeholder,
    isTransparent,
    extendClass,
    errorId,
    displayTimeZone,
    formatToGet,
    onChange,
  } = props

  const onDateChange = (pickerData) => {
    const fieldValue = displayTimeZone ? pickerData.toISOString() : pickerData.format(formatToGet)
    return onChange(fieldValue)
  }

  const fieldClassNames = classNames(
    'content-block-field content-block__date-time-field',
    {
      'content-block-field--disabled': disabled,
    },
    className
  )

  const fieldControlClassNames = classNames('content-block-field__control content-block__date-time-field__control', {
    'content-block__date-time-field__control--error': isInvalid,
    'content-block__date-time-field__control-transparent': isTransparent,
  })

  return (
    <div className={fieldClassNames}>
      {label && (
        <label className='content-block-field__label' htmlFor={name}>
          {label}
        </label>
      )}

      {isInvalid && <FieldError id={errorId} errorText={errors[name]} />}

      <div className='content-block__date-time-field__control-wrapper'>
        <Datetime
          closeOnSelect
          defaultValue={value}
          value={value}
          inputProps={{
            disabled,
            className: fieldControlClassNames,
            name,
            placeholder,
            autoComplete,
          }}
          locale={I18n.locale}
          onChange={onDateChange}
          type='date'
          timeFormat={false}
          dateFormat={dateFormat}
          name={name}
          className={extendClass}
        />
        <i className='far fa-calendar-alt' />
      </div>
    </div>
  )
}

ContentBlockDateTimeField.propTypes = propTypes
ContentBlockDateTimeField.defaultProps = defaultProps

export default observer(ContentBlockDateTimeField)
