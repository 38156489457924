import React, { Fragment, useEffect } from 'react'
import classNames from 'classnames'

import { BLOCK_VIEW } from 'constants/contentPageShared.constants'
import { AFFILIATE_PROGRAM_FORMS } from 'constants/affiliatePrograms.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { withStores, pipe } from 'utils/helpers.utils'
import { getCommissionRange, getStagesType, getCommissionRangeWithTiers } from 'utils/affiliatePrograms.utils'
import { stringReplaceAll } from 'utils/string.utils'

import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'
import { EDIT_TEXT_FIELD_TYPES } from 'shared/components/page-builder/constants/pageBuilder.constants'

import { AFFILIATE_CLASS_NAMES, AFFILIATE_TEXT_PARAMS } from '../../../../../page-builder/constants/block.constants'

export const getTextWithAffiliateButtons = (text, affiliateProgram = {}, props) => {
  const {
    name = '',
    cookiesExpire = '',
    commission = [],
    affiliateProgramCommissions = [],
    form,
  } = affiliateProgram || {}
  const { classes, content: { buttonAnimation, buttonText } = {}, previewMode } = props

  const buttonClasses = classNames('elo-affiliate-button', classes.button, {
    [classes.shadow]: !buttonAnimation,
    [classes[buttonAnimation]]: !!buttonAnimation,
  })
  const stagesType = getStagesType(affiliateProgram)
  const allProductForm = form === AFFILIATE_PROGRAM_FORMS.all
  const commissions = allProductForm ? commission : affiliateProgramCommissions
  const isMultiStages = allProductForm
    ? commissions.length > 1
    : commissions.some(({ commission }) => commission.length > 1)
  const commissionRange = isMultiStages
    ? getCommissionRangeWithTiers(commissions)
    : getCommissionRange(allProductForm, commissions, stagesType)
  const highlighterAttributes = previewMode
    ? `data-highlighter-item="${BLOCK_MENU_HIGHLIGHT_IDS.affiliate.button}" data-highlighter-selector=""`
    : ''
  stringReplaceAll

  return pipe(
    (str) => stringReplaceAll(str, '%{program}', `${name}`),
    (str) => stringReplaceAll(str, '%{commission}', `${commissionRange}`),
    (str) => stringReplaceAll(str, '%{cookies}', `${cookiesExpire}`),
    (str) =>
      stringReplaceAll(
        str,
        '%{buttons}',
        `
      <div class="${classNames(classes.container, classes.noBackgroundImage)}">
        <button
          class="${buttonClasses}"
          ${highlighterAttributes}
        >
          ${buttonText || I18n.t('react.shared.button.accept')}
        </button>
      </div>
    `
      )
  )(text)
}

const TextAffiliate = (props) => {
  const {
    classes,
    content: { text, view, affiliateProgramToken },
    previewMode,
    affiliateProgram,
    accept,
  } = props

  const handleAffiliateClick = () => {
    if (!previewMode) {
      accept(affiliateProgramToken)
    }
  }

  const affiliateText =
    view === BLOCK_VIEW.text &&
    text &&
    affiliateProgramToken &&
    getTextWithAffiliateButtons(text, affiliateProgram, props)

  useEffect(() => {
    const affiliateButtons = document.getElementsByClassName('elo-affiliate-button')

    for (const button of affiliateButtons) {
      button.addEventListener('click', handleAffiliateClick)
    }
  }, [affiliateText])

  return (
    <Fragment>
      {affiliateText ? (
        <div
          className={classNames(classes.textContainer, AFFILIATE_CLASS_NAMES.textContainerClassName)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.affiliate.text,
            'data-highlighter-selector': '',
          })}
        >
          <EditTextArea
            value={text}
            previewMode={previewMode}
            textFieldType={EDIT_TEXT_FIELD_TYPES.froala}
            message={I18n.t('react.cabinet.affiliate_landings.use_custom_buttons_desc', AFFILIATE_TEXT_PARAMS)}
          >
            <div dangerouslySetInnerHTML={{ __html: affiliateText }} />
          </EditTextArea>
        </div>
      ) : (
        previewMode && (
          <div className={classes.noTextContainer}>
            <i className='fas fa-font' />
          </div>
        )
      )}
    </Fragment>
  )
}

export default withStores(['contentPageStore'], TextAffiliate)
